import { PROMOTED_PUSH_CHARGE_URL } from './url';

const AB_TEST_EXPERIMENT_BASE_URL = '/_exclusive/experiments';
const STORE_AD_BASE_URL = '/paid-promotion/store-ads';
const TOGGLE_FEATURES_URL = '_exclusive/info/toggle-features';

export default {
  getProduct(id) {
    return this.get(`/products/${id}`);
  },

  getProductSkus(id) {
    return this.get(`/products/${id}/skus`);
  },

  getProductVariantInfo(id) {
    return this.get(`/products/${id}/variant-info`);
  },

  getProducts({ params, headers } = { params: {}, headers: {} }) {
    return this.get('/multistrategy-products', 'public', {
      params,
      headers,
    });
  },

  getSeoProducts({ params, headers } = { params: {}, headers: {} }) {
    // force use simplified=true
    return this.get('/seo-multistrategy-products', 'public', {
      params: { ...params, simplified: true },
      headers,
    });
  },

  getFacets(params = {}) {
    return this.get('/products', 'public', { params });
  },

  getAddresses() {
    return this.get('/info/addresses');
  },

  getCouriers() {
    return this.get('/info/carriers');
  },

  getCourierByCategories() {
    return this.get('/couriers/categories');
  },

  getInternationalCountryDeliveries() {
    return this.get('/international-deliveries/countries');
  },

  getCatalogs(term, offset, limit, categoryId) {
    const encodedTerm = encodeURIComponent(term);
    return this.get(
      `/_exclusive/product-catalogs?term=${encodedTerm}&offset=${offset}&limit=${limit}&category_id=${categoryId}`,
    );
  },

  getRelatedProducts(keyword) {
    const encodedKeyword = encodeURIComponent(keyword);
    return this.get('/products/correlate', 'public', { params: { keywords: encodedKeyword } });
  },

  getCategorySuggestions(params) {
    return this.get('/category-suggestions/categories', 'public', {
      params,
    });
  },

  getAttributeOptions(categoryId) {
    return this.get(`/categories/${categoryId}/attributes`);
  },

  getVariantOptions(categoryId) {
    return this.get(`/categories/${categoryId}/variants`);
  },

  getCategories(permalink) {
    return this.get(`/categories/permalink/${permalink}`);
  },
  // A/B test experiments
  chooseAlternative({ experimentName, params: data } = {}) {
    return this.post(`${AB_TEST_EXPERIMENT_BASE_URL}/${experimentName}/participants`, 'user', { data });
  },
  scoreMetric({ experimentName, participantId, params: data } = {}) {
    const pathUrl = `${AB_TEST_EXPERIMENT_BASE_URL}/${experimentName}/participants/${participantId}/metrics`;

    return this.post(pathUrl, 'user', {
      data,
    });
  },
  chargePromotedProduct({ productId, params: data } = {}) {
    const userIdentity =
      (window.USER && window.USER.identity) || document.cookie.match(/browser_id=(.*)/)[1].split(/;/)[0];

    return this.post(PROMOTED_PUSH_CHARGE_URL(productId), 'user', {
      data,
      headers: { 'X-Identity': userIdentity },
    });
  },
  getTrends() {
    return this.get('/trends/keywords');
  },

  getTags({ limit = 24, offset = 0, keywords = '' } = {}) {
    return this.get('/tags', 'public', {
      params: {
        has_landing_page: true,
        limit,
        offset,
        keywords,
      },
    });
  },

  getContents({ content_id }) {
    return this.get(`/contents/${content_id}`);
  },

  getToggleStatus(feature) {
    return this.get(TOGGLE_FEATURES_URL, 'store', { params: { feature } });
  },

  getStoreAdByKeyword({ keyword }) {
    const encodedKeyword = encodeURIComponent(keyword);
    return this.get(`${STORE_AD_BASE_URL}/campaigns?keyword=${encodedKeyword}`, 'public');
  },

  chargeStoreAd(id) {
    const userIdentity =
      (window.USER && window.USER.identity) || document.cookie.match(/browser_id=(.*)/)[1].split(/;/)[0];

    return this.post(`${STORE_AD_BASE_URL}/${id}/charge`, 'public', {
      headers: { 'Bukalapak-Identity': userIdentity },
    });
  },

  getRecommendationProducts() {
    return this.get('/recommendations/products', 'user', {
      params: {
        limit: 15,
        offset: 0,
        referrer_page: 'search_restricted',
      },
    });
  },

  getNeoConfigs(data) {
    return this.post('/_exclusive/neo/configs', 'user', { data });
  },
  getNeoToggles(data) {
    return this.post('/_exclusive/neo/toggles', 'user', { data });
  },

  getSpecialCampaigns() {
    return this.get('/_exclusive/special-campaigns');
  },

  getUserFavoritedProducts(data) {
    const params = { product_ids: data };
    return this.get('/favorites/bulk-checks', 'user', { params });
  },
  addProductToFavorite(productId) {
    const data = { product_ids: [productId] };
    return this.post('/favorites', 'user', { data });
  },
  removeProductToFavorite(productId) {
    const data = { product_ids: [productId] };
    return this.delete('/favorites', 'user', { data });
  },
  sendEventToSplitterGus(event, data) {
    return this.post(`/_exclusive/splitter-gus/events/${event}`, 'user', { data });
  },
  getTopKeywordsRelatedPages({ keyword, limit = 5, has_category_permalink = true } = {}) {
    return this.get('/_exclusive/top-keywords/related-pages', 'public', {
      params: {
        keywords: keyword,
        limit,
        has_category_permalink,
      },
    });
  },
};
