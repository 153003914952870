const replaceDash = text => (text ? text.replace(/-/g, ' ') : '');

const sanitizeText = text =>
  text
    ? text
        .replace(/<[^>]+>/g, '')
        .replace(/{{+|}}+/g, '')
        .replace(/"/g, '')
        .replace(/\\/g, '\\\\')
    : '';

module.exports = { replaceDash, sanitizeText };
