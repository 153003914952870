<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv
        text="Kondisi Barang"
        class="d-filter-item-active te-condition-menu"
        style="padding-left: 0;"
        @click="isShow = true"
      >
        <bl-badge-av count="1" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv
      v-else
      class="te-condition-menu"
      text="Kondisi Barang"
      style="padding-left: 0;"
      @click="isShow = true"
    >
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv
      :active.sync="isShow"
      title="Kondisi"
      text-action="Reset"
      @clickAction="reset"
      @close="close"
    >
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding-left:16px;">
          <bl-cell-mv
            v-for="(option, idx) in options"
            :key="idx"
            :hasBorder="idx > 0 ? 'top' : undefined"
            :text="option.label"
            style="padding-left: 0;"
            @click="itemClicked(option.value)"
          >
            <div slot="iconRight">
              <bl-radio-av
                v-model="value"
                :class="{ 'te-filter-baru': option.value === 'new', 'te-filter-bekas': option.value === 'used' }"
                :native-value="option.value"
              />
            </div>
          </bl-cell-mv>
        </div>
      </div>
      <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
        <bl-button-av
          class="te-condition-save"
          variation="primary"
          fullwidth
          style="border-radius: 4px;"
          @click="save"
        >
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import FilterChosen from './FilterChosen.vue';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(m => m.default || m);

export default {
  name: 'FilterCondition',
  components: {
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  props: {
    currentParamValue: {
      type: Object,
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      value: null,
      savedValue: null,
      options: [
        {
          label: 'Semua Kondisi',
          value: 'all',
        },
        {
          label: 'Baru',
          value: 'new',
        },
        {
          label: 'Bekas',
          value: 'used',
        },
      ],
      chosenList: [],
    }
  },
  computed: {
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.value = null;
        this.updateConditionFilter();
        this.updateChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.value = this.getValue();
      this.updateChosen();
    },
    getValue() {
      let val = '';
      switch (true) {
        case this.currentParamValue.new === this.$PARAM_TRUE_VALUE && this.currentParamValue.used === this.$PARAM_TRUE_VALUE:
          val = this.options[0].value;
          break;
        case this.currentParamValue.new === this.$PARAM_TRUE_VALUE && this.currentParamValue.used === this.$PARAM_FALSE_VALUE:
          val = this.options[1].value;
          break;
        case this.currentParamValue.new === this.$PARAM_FALSE_VALUE && this.currentParamValue.used === this.$PARAM_TRUE_VALUE:
          val = this.options[2].value;
          break;
      }
      return val;
    },
    updateConditionFilter() {
      switch (true) {
        case this.value === this.options[0].value:
          this.$emit('updated', [
            { label: this.options[1].value, value: this.$PARAM_TRUE_VALUE },
            { label: this.options[2].value, value: this.$PARAM_TRUE_VALUE },
          ]);
          break;
        case this.value === this.options[1].value:
          this.$emit('updated', [
            { label: this.options[1].value, value: this.$PARAM_TRUE_VALUE },
            { label: this.options[2].value, value: this.$PARAM_FALSE_VALUE },
          ]);
          break;
        case this.value === this.options[2].value:
          this.$emit('updated', [
            { label: this.options[1].value, value: this.$PARAM_FALSE_VALUE },
            { label: this.options[2].value, value: this.$PARAM_TRUE_VALUE },
          ]);
          break;
        default:
          this.$emit('updated', [
            { label: this.options[1].value, value: this.$PARAM_FALSE_VALUE },
            { label: this.options[2].value, value: this.$PARAM_FALSE_VALUE },
          ]);
      }
    },
    updateChosen() {
      if (this.value === this.options[1].value) {
        this.chosenList = [this.options[1].label];
      } else if (this.value === this.options[2].value) {
        this.chosenList = [this.options[2].label];
      } else {
        this.chosenList = [];
      }
    },
    reset() {
      this.value = null;
    },
    save() {
      this.updateConditionFilter();
      this.updateChosen();
      this.savedValue = this.value;
      this.isShow = false;
    },
    removeChosen(idx) {
      this.value = null;
      this.chosenList.splice(idx, 1);
      this.updateConditionFilter();
    },
    close() {
      if (this.savedValue) {
        this.value = this.savedValue;
      } else {
        if (this.value !== this.getValue()) {
          this.initializeValue();
        }
      }
    },
    itemClicked(val) {
      this.value = val;
    },
  },
};
</script>
