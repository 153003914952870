import base from '@/shared/store/base';
import {
  sortModule,
  relatedModule,
  attributeModule,
  variantModule,
  categoryModule,
  neoModule,
  searchContextModule,
  trackerModule,
} from '@/shared/store/modules';

import mobileActions from './actions';

const mobileProductStore = (url, { additionalModules = {}, toggles = [] }) => {
  return {
    state: base.state(url, 'mobile', { toggles }),
    actions: {
      ...base.actions,
      ...mobileActions,
    },
    mutations: {
      ...base.mutations,
    },
    getters: {
      ...base.getters,
    },
    modules: {
      sort: sortModule,
      related: relatedModule,
      attribute: attributeModule,
      variant: variantModule,
      category: categoryModule,
      neo: neoModule,
      searchContext: searchContextModule,
      tracker: trackerModule,
      ...additionalModules,
    },
  };
};

export default mobileProductStore;
