<template>
  <div class="dp-fixed-header">
    <div class="branch-journeys-top"></div>
    <bl-nav-bar-search-mv
      :value="keyword"
      placeholder="Cari barang..."
      has-back-button
      :has-back-button-link="backButtonLink"
      has-back-button-color="ui-white"
      has-right-button
      has-right-button-link="/cart/carts"
      :has-right-button-notification="totalCartItems"
      ref="searchNavBar"
      @handleClickInput="openOmnisearch"
    >
      <ico-back-android color="#fff" slot="hasBackButtonIcon" />
      <ico-cart color="#fff" slot="hasRightButtonIcon" />
    </bl-nav-bar-search-mv>
  </div>
</template>

<script>
import IcoCart from '@bukalapak/bazaar-visual-next/dist/components/IcoCart';
import IcoBackAndroid from '@bukalapak/bazaar-visual-next/dist/components/IcoBackAndroid';
import BlNavBarSearchMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlNavBarSearchMv';

export default {
  components: {
    IcoBackAndroid,
    BlNavBarSearchMv,
    IcoCart,
  },

  props: {
    keyword: {
      type: String,
      required: true,
    },
  },


  data() {
    return {
      isPWABukaGames: false,
    };
  },

  computed: {
    totalCartItems() {
      return global.STORE ? global.STORE.state.cart.items.length: 0;
    },
    backButtonLink() {
      return `https://m.bukalapak.com/${this.isPWABukaGames ? 'bukagames' : ''}`;
    },
  },

  mounted() {
    global.STORE.dispatch('cart/getCarts').catch(console.error); // eslint-disable-line
    this.getPWAGamesState();
  },

  methods: {
    getPWAGamesState() {
      this.isPWABukaGames =
        typeof window !== 'undefined' && window.sessionStorage?.getItem('isPWABukaGames') === 'true';
    },
    openOmnisearch() {
      global.STORE.commit('omnisearch/toggleShowOverlay');

      this.$nextTick(() => {
        this.$refs.searchNavBar.handleClickCancel();
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .dp-fixed-header {
    position: fixed;
    z-index: 997;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: #d71149;
  }
</style>
