<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv
        hasBorder="top"
        class="d-filter-item-active"
        style="padding-left: 0;"
        @click="isShow = true"
        :text="title"
      >
        <bl-badge-av :count="chosenList.length" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv
      v-else
      hasBorder="top"
      :text="title"
      style="padding-left: 0;"
      @click="isShow = true"
    >
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv
      :active.sync="isShow"
      :title="title"
      text-action="Reset"
      @clickAction="clear"
      @close="close"
    >
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding-left:16px;">
          <div
            v-for="(option, idx) in listOfOptions"
            :key="option.identifier"
          >
            <bl-cell-mv
              :hasBorder="idx > 0 ? 'top' : undefined"
              style="padding-left: 0;"
              @click="option.isShow = true"
            >
              <bl-text-av :ellipsis="1" slot="text">
                <span v-if="option.value && String(option.value) !== 'null'">
                  {{ option.display_name }}:
                  <span style="color:#ea5164;">{{ option.value }}</span>
                </span>
                <span v-else>
                  {{ option.display_name }}
                </span>
              </bl-text-av>
              <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
            </bl-cell-mv>
            <bl-sheet-mv
              :active.sync="option.isShow"
              :title="option.display_name"
              text-action="Reset"
              @clickAction="reset(idx)"
              @close="cancel(idx)"
            >
              <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
              <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
                <div class="d-filter-card" style="padding-left:16px;">
                  <bl-cell-mv
                    text="Semua"
                    style="padding-left: 0;"
                    @click="itemClicked($event, idx, 'null')"
                  >
                    <div slot="iconRight">
                      <bl-radio-av
                        v-model="option.value"
                        native-value="null"
                      />
                    </div>
                  </bl-cell-mv>
                  <bl-cell-mv
                    v-for="(opt, idy) in option.options"
                    :key="idy"
                    hasBorder="top"
                    :text="opt"
                    style="padding-left: 0;"
                    @click="itemClicked($event, idx, opt)"
                  >
                    <div slot="iconRight">
                      <bl-radio-av
                        v-model="option.value"
                        :native-value="opt"
                      />
                    </div>
                  </bl-cell-mv>
                </div>
              </div>
              <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
                <bl-button-av
                  variation="primary"
                  fullwidth
                  style="border-radius: 4px;"
                  @click="select(idx)"
                >
                  Simpan
                </bl-button-av>
              </div>
            </bl-sheet-mv>
          </div>
        </div>
      </div>
      <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
        <bl-button-av
          variation="primary"
          fullwidth
          style="border-radius: 4px;"
          @click="save"
        >
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import FilterChosen from './FilterChosen.vue';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(m => m.default || m);

export default {
  name: 'FilterAttributes',
  props: {
    title: {
      type: String,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  data() {
    return {
      isShow: false,
      values: [],
      tempValues: [],
      listOfOptions: [],
      tempOptions: [],
      chosenList: [],
      type: 'attributes',
    }
  },
  computed: {
    currentValues() {
      return this.selected;
    },
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.clear();
        this.update();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.attributes.forEach(variant => {
        const variantVal = this.getCurrentValue(variant.name);

        const exist = this.listOfOptions.find(item => item.identifier === variant.identifier);
        if (exist) {
          // merge options if identifier exists
          exist.options = [...exist.options, ...variant.options];
        } else {
          this.listOfOptions.push(Object.assign({
            value: variantVal,
            isShow: false,
          }, variant));
        }

        if (variantVal) this.setValues(variantVal, variant);
      });
      this.tempValues = JSON.parse(JSON.stringify(this.values));
      this.tempOptions = JSON.parse(JSON.stringify(this.listOfOptions));
    },
    setValues(val, variant) {
      const exist = this.values.find(item => item.label === variant.identifier);
      if (!exist) {
        this.values.push({
          name: variant.display_name,
          label: variant.identifier,
          value: val,
        });
        this.chosenList.push(this.chosenStr(variant.display_name, val));
      }
    },
    getCurrentValue(name) {
      const item = this.currentValues[`${name}`];
      return item && String(item) && String(item) !== 'null' ? item : null;
    },
    chosenStr(name, val) {
      return `${name}: <span style="color:#ea5164">${val}</span>`;
    },
    reset(id) {
      const item = this.values.find(v => v.label === this.listOfOptions[id].identifier);
      const indeks = this.values.indexOf(item);
      if (indeks !== -1) this.values.splice(indeks, 1);
      this.listOfOptions[id].value = null;
    },
    clear() {
      this.values.forEach(item => {
        item.value = [];
      });
      this.listOfOptions.forEach(option => {
        option.value = null;
      });
    },
    removeChosen(idx) {
      const option = this.listOfOptions.find(o => o.identifier === this.values[idx].label);
      option.value = null;

      const newValArr = JSON.parse(JSON.stringify(this.values));
      newValArr[idx].value = [];
      this.update(newValArr);
    },
    cancel(id) {
      this.values = JSON.parse(JSON.stringify(this.tempValues));
      this.listOfOptions = JSON.parse(JSON.stringify(this.tempOptions));
      this.listOfOptions[id].isShow = false;
      this.$emit('close');
    },
    close() {
      this.values = JSON.parse(JSON.stringify(this.tempValues));
      this.listOfOptions = JSON.parse(JSON.stringify(this.tempOptions));
    },
    update(valuesArr = this.values) {
      this.chosenList = [];
      this.values = [];
      valuesArr.forEach(val => {
        this.$emit('updated', { label: val.label, value: val.value, type: this.type });
        if (val.value && String(val.value) && String(val.value) !== 'null') {
          this.chosenList.push(this.chosenStr(val.name, val.value));
          this.values.push(val);
        }
      });
      this.tempValues = JSON.parse(JSON.stringify(this.values));
      this.tempOptions = JSON.parse(JSON.stringify(this.listOfOptions));
    },
    select(id) {
      const option = this.listOfOptions[id];
      const elem = this.values.find(v => v.label === option.identifier);
      if (elem) {
        elem.value = option.value;
      } else {
        this.values.push({
          name: option.display_name,
          label: option.identifier,
          value: String(option.value !== 'null') ? option.value : '',
        });
      }
      this.listOfOptions[id].isShow = false;
      this.$emit('close');
    },
    save() {
      this.update();
      this.isShow = false;
    },
    itemClicked(event, id, val) {
      if (event.target && event.target.tagName.toLowerCase() === 'div') {
        this.listOfOptions[id].value = val;
      }
    },
  },
};
</script>
