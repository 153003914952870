<template>
  <div class="d-filter-chosen">
    <div class="d-filter-chosen-inner">
      <bl-button-av
        v-for="(item, idx) in items"
        :key="`chosen-${idx}`"
        size="small"
        variation="outline"
        @click="$emit('remove', idx)"
      >
        <bl-flex-container-mv align-items="center">
          <template v-if="isRating">
            <bl-flex-item-av style="margin-right:4px;">
              <bl-rating-mv :value="item" size="small" />
            </bl-flex-item-av>
            <bl-flex-item-av>
              <bl-text-av variation="body-14">dan lebih</bl-text-av>
            </bl-flex-item-av>
          </template>
          <template v-else>
            <bl-flex-item-av>
              <bl-text-av variation="body-14" v-html="item" />
            </bl-flex-item-av>
          </template>
          <bl-flex-item-av>
            <ico-cross-minor :color="$UI_DARKER_COLOR" style="width:14px;margin-left:8px;" />
          </bl-flex-item-av>
        </bl-flex-container-mv>
      </bl-button-av>
    </div>
  </div>
</template>

<script>
import IcoCrossMinor from "@bukalapak/bazaar-visual-next/src/components/IcoCrossMinor";

export default {
  name: 'FilterChosen',
  components: {IcoCrossMinor},
  props: {
    items: {
      type: Array,
      required: true,
    },
    isRating: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
