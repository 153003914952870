import ProductSearch from '@/shared/models/search';
import BaseUrlExtractor from '@/shared/models/extractors/base';

// eslint-disable-next-line no-unused-vars
const state = (url, platform = 'desktop', { trendBrand = {}, bhlm = {}, tag = {}, promo = {}, toggles = [] } = {}) => ({
  platform,
  productSearch: new ProductSearch(new BaseUrlExtractor(url)),
  isError: false,
  isProductSearchPath: false,
  addresses: {},
  couriers: [],
  collections: {
    products: [],
    facets: [],
    categorySuggestions: [],
    productVariants: [],
  },
  layoutType: 'grid',
  filterOptions: {
    courierByCategories: [],
    priceRange: {
      min: '',
      max: '',
    },
    internationalCouriers: [],
    countryDeliveries: [],
    cities: [],
    provinces: [],
  },
  bhlm: global._INITIAL_BHLM_DATA_ || {},
  trendBrand: global._INITIAL_TREND_AND_BRAND_DATA_ || {},
  tagPage: {},
  productCampaignPageData: global._INITIAL_PRODUCT_CAMPAIGN_DATA_,
  promo: promo || global._INITIAL_PROMO_DATA_ || {},
  errorMessage: {
    show: false,
    text: 'Terjadi kesalahan.',
    defaultText: 'Terjadi kesalahan.',
  },
  tag: tag || {},
  backHistory: false,
  isForbiddenKeyword: false,
  blockedKeyword: {
    blocked: false,
    keyword: '',
    message: '',
    type: '',
  },
  specialCampaigns: [],
  isSuperSellerNewBadgeActive: false,
  isLazyloadProducts: false, // TODO: temporary, after lean vevrsion deployed we can remove this,
  cache: {},
  toggles,
  favoritedProducts: [],
});

export default state;
