import urlUtility from '@/shared/utilities/urlUtility';

class BaseUrlExtractor {
  constructor(url = document.location.href) {
    this.urlObj = urlUtility.createUrlObj(url);
    this.searchParamRegex = /^search\[/;
    this.attributeParamRegex = /filter_attr\]\[(.*)\]/;
    this.variantParamRegex = /filter_var\]\[(.*)\]/;
  }

  extractSearchParams() {
    const params = Object.assign({}, this.urlObj.params);
    const result = {};

    delete params.page;

    Object.keys(params).forEach(paramKey => {
      const isSearchParam = this.searchParamRegex.test(paramKey);
      const isAttributeParam = this.attributeParamRegex.test(paramKey);
      const isVariantParam = this.variantParamRegex.test(paramKey);

      if (paramKey && isSearchParam && !isAttributeParam && !isVariantParam) {
        result[paramKey] = params[paramKey];
      }
    });

    // reset sort_by if search keyword exist
    if (params['search[keywords]'] && !params['search[sort_by]']) {
      result['search[sort_by]'] = '';
    }

    return result;
  }

  extractSearchByParamType(paramType) {
    const params = Object.assign({}, this.urlObj.params);
    const result = {};

    const regexFormula = this[`${paramType}ParamRegex`];

    Object.keys(params).forEach(paramKey => {
      if (regexFormula.test(paramKey)) {
        const extractedKey = regexFormula.exec(paramKey)[1];

        result[extractedKey] = params[paramKey];
      }
    });

    return result;
  }

  extractPageNumber() {
    if (this.urlObj.params) {
      return this.urlObj.params.page || '1';
    }

    return '1';
  }
}

export default BaseUrlExtractor;
