import trackerUtility from '@/shared/utilities/trackerUtility';

const initialState = {
  pageView: {
    evn: '',
    td_host: '',
    td_path: '',
    td_referrer: '',
    td_title: '',
    td_url: '',
    td_viewport: '',
    ui: '',
  },
};

const mutations = {
  SET_CONTEXT_INTO_URL(state, searchContext) {
    const url = new URL(window.location.href);

    if (searchContext) {
      for (const key in searchContext) {
        if (searchContext[key] !== undefined) url.searchParams.append(key, String(searchContext[key]));
      }
    }

    state.pageView.td_url = url.href;
  },
  INITIATE_PAGE_VIEW_TRACKER(state) {
    state.pageView.evn = 'pageviews';
    state.pageView.td_host = window.location.hostname;
    state.pageView.td_path = window.location.pathname;
    state.pageView.td_referrer = window.location.href;
    state.pageView.td_title = document.title;
    state.pageView.td_url = '';
    state.pageView.td_viewport = [document.documentElement.clientWidth, document.documentElement.clientHeight].join(
      'x',
    );
    state.pageView.ui = window.USER.id36;
  },
};

const actions = {
  sendTracker({ commit, state, rootState }) {
    if (typeof window == 'undefined' || typeof window?.USER == 'undefined') return;
    commit('INITIATE_PAGE_VIEW_TRACKER');
    commit('SET_CONTEXT_INTO_URL', rootState.searchContext.content);

    trackerUtility.send(state.pageView);
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
