import api from '@/shared/api';

const initialState = {
  attributeOptions: [],
};

const attributeGetters = {
  attributeOptions: state => state.attributeOptions,
  isAttributeOptionExist: state => state.attributeOptions.length > 0,
  getAttributeDisplayName: state => attributeName =>
    state.attributeOptions.find(a => a.name === attributeName).display_name,
};

const mutations = {
  UPDATE_ATTRIBUTE_OPTIONS(state, attributeOptions) {
    state.attributeOptions = attributeOptions.map(a => ({
      ...a,
      identifier: a.name,
    }));
  },
};

const actions = {
  async retrieveProductAttributes({ commit }, { categoryId }) {
    try {
      const { data = [] } = await api.getAttributeOptions(categoryId);

      commit('UPDATE_ATTRIBUTE_OPTIONS', data);
    } catch (error) {
      // @TODO send to Sentry
    }
  },
  resetAttributeOptions({ commit }) {
    commit('UPDATE_ATTRIBUTE_OPTIONS', []);
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: attributeGetters,
  mutations,
  actions,
};
