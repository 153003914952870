<template>
  <div>
    <bl-button-av variation="naked" style="padding: 0 8px;" @click="showFilters = true">
      <div slot="icon">
        <ico-filter-minor />
        <ico-check-circle
          v-if="Object.keys(searchFilterApplied).length"
          color="rgb(70, 196, 144)"
          class="d-tick-badge"
        />
      </div>
      <bl-text-av>Filter</bl-text-av>
    </bl-button-av>
    <bl-sheet-mv
      v-if="isMounted"
      :active.sync="showFilters"
      title="Filter"
      class="d-filter-content"
      text-action="Reset"
      @clickAction="resetFilter"
      @close="cancelFilter"
    >
      <template v-if="isRendered">
        <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
          <filter-switch
            param-name="brand"
            :current-param-value="paramValue('brand')"
            :initial-param-value="initialValue('brand')"
            :is-reset="isReset"
            @updated="updateSearchParam"
          >
            <bl-flex-item-av grow="3">
              <bl-emblem-av variation="bukamall" style="margin-bottom: 10px;" />
              <bl-text-av variation="caption-12">Tampilkan Barang dari Lapak Resmi</bl-text-av>
            </bl-flex-item-av>
          </filter-switch>
          <filter-price-range
            v-if="!isBHLMKeyExist('h')"
            :price-range="priceRange"
            :option="priceRangeOption"
            :is-reset="isReset"
            @updated="updateSearchParam"
          >
            <filter-checkbox
              param-name="todays_deal"
              :current-param-value="paramValue('todays_deal')"
              :initial-param-value="initialValue('todays_deal')"
              :is-reset="isReset"
              style="margin:10px 0;"
              @updated="updateSearchParam"
            >
              Diskon
            </filter-checkbox>
            <filter-checkbox
              param-name="wholesale"
              :current-param-value="paramValue('wholesale')"
              :initial-param-value="initialValue('wholesale')"
              :is-reset="isReset"
              style="margin:10px 0;"
              @updated="updateSearchParam"
            >
              Grosir
            </filter-checkbox>
            <filter-checkbox
              param-name="installment"
              :current-param-value="paramValue('installment')"
              :initial-param-value="initialValue('installment')"
              :is-reset="isReset"
              style="margin:10px 0;"
              @updated="updateSearchParam"
            >
              Cicilan
            </filter-checkbox>
          </filter-price-range>
          <filter-switch
            param-name="assurance"
            :current-param-value="paramValue('assurance')"
            :initial-param-value="initialValue('assurance')"
            :is-reset="isReset"
            @updated="updateSearchParam"
          >
            <bl-flex-item-av width="75%" grow="1">
              <bl-flex-container-mv>
                <ico-guarantee-minor color="#ea5164" style="width:32px;" />
                <div style="margin-left:6px;">
                  <bl-text-av variation="caption-12" style="margin:2px 0;">Product Guarantee</bl-text-av>
                  <bl-text-av tag="span" variation="caption-12" color="subdued">
                    Tampilkan barang pilihan bergaransi uang kembali 100% dari Bukalapak
                  </bl-text-av>
                </div>
              </bl-flex-container-mv>
            </bl-flex-item-av>
          </filter-switch>
          <div class="d-filter-card" style="padding-left:16px;">
            <filter-condition
              v-if="!isBHLMKeyExist('b')"
              :current-param-value="{ new: paramValue('new'), used: paramValue('used') }"
              :is-reset="isReset"
              @updated="formatSearchParam"
              @close="handleCellShow"
            />
            <filter-rating
              param-name="rating_gte"
              :current-param-value="String(paramValue('rating_gte'))"
              :has-value="hasRatingValue"
              :is-reset="isReset"
              @updated="updateSearchParam"
              @close="handleCellShow"
            />
            <filter-top-seller
              param-name="top_seller"
              :current-param-value="paramValue('top_seller')"
              :is-reset="isReset"
              @updated="updateSearchParam"
              @close="handleCellShow"
            />
            <filter-seller-location
              v-if="!isBHLMKeyExist('l')"
              :param-name="['province', 'cities']"
              :current-param-province-value="paramValue('province')"
              :current-param-city-value="paramValue('cities')"
              :is-reset="isReset"
              @updated="formatSearchParam"
              @close="handleCellShow"
            />
            <filter-courier
              param-name="courier"
              :current-param-value="paramValue('courier')"
              :is-reset="isReset"
              @updated="updateSearchParam"
              @close="handleCellShow"
            />
          </div>
          <div v-if="variantOptions.length > 0" class="d-filter-card" style="padding-left:16px;">
            <filter-variants
              v-for="variant in variantOptions"
              :key="variant.identifier"
              :title="variant.name"
              :variant="variant"
              :selected="productSearchVariant[variant.identifier] || []"
              :is-reset="isReset"
              @updated="handleSpecificationChange"
              @close="handleCellShow"
            />
          </div>
          <div v-if="attributeOptions.length > 0" class="d-filter-card" style="padding-left:16px;">
            <filter-attributes
              title="Spesifikasi"
              :attributes="attributeOptions"
              :selected="productSearchAttribute"
              :is-reset="isReset"
              @updated="handleSpecificationChange"
              @close="handleCellShow"
            />
          </div>
        </div>
        <div slot="footer" style="padding: 16px 24px; border-top: 1px solid #eee;">
          <bl-button-av
            class="te-filter-save"
            variation="primary"
            fullwidth
            style="border-radius: 4px;"
            @click="applyFilter"
          >
            Terapkan Filter
          </bl-button-av>
        </div>
      </template>
    </bl-sheet-mv>
  </div>
</template>

<script>
import { mapGetters } from 'external/Vuex';

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(
    m => m.default || m,
  );

import {
  FilterAttributes,
  FilterCheckbox,
  FilterCondition,
  FilterCourier,
  FilterPriceRange,
  FilterRating,
  FilterSellerLocation,
  FilterSwitch,
  FilterTopSeller,
  FilterVariants,
} from './filters';
import IcoFilterMinor from '@bukalapak/bazaar-visual-next/src/components/IcoFilterMinor';
import IcoCheckCircle from '@bukalapak/bazaar-visual-next/src/components/IcoCheckCircle';
import IcoGuaranteeMinor from '@bukalapak/bazaar-visual-next/src/components/IcoGuaranteeMinor';

export default {
  name: 'ProductFilters',

  components: {
    IcoGuaranteeMinor,
    IcoCheckCircle,
    IcoFilterMinor,
    BlSheetMv,
    FilterAttributes,
    FilterCheckbox,
    FilterCondition,
    FilterCourier,
    FilterPriceRange,
    FilterRating,
    FilterSellerLocation,
    FilterSwitch,
    FilterTopSeller,
    FilterVariants,
  },

  props: {
    productSearch: {
      type: Object,
      required: true,
    },
    appliedFilters: {
      type: Object,
      required: true,
    },
    isOverlayActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: false,
      isRendered: false,
      showFilters: false,
      filterArr: [],
      tempParamsFiltered: {},
      tempVariantsFiltered: {},
      tempAttributesFiltered: {},
      searchExcludes: [
        'search[category_id]',
        'search[category_slug]',
        'search[category_name]',
        'search[keywords]',
        'search[sort_by]',
        'search[super_seller_tier]',
      ],
      isReset: false,
      hasReset: false,
    };
  },

  computed: {
    ...mapGetters(['initialProductSearch', 'priceRangeOption', 'bhlm', 'filterOption']),
    ...mapGetters('attribute', {
      attributeOptions: 'attributeOptions',
    }),
    ...mapGetters('variant', {
      variantOptions: 'variantOptions',
    }),
    priceRange() {
      return {
        min: this.productSearch.params['search[price_min]'],
        max: this.productSearch.params['search[price_max]'],
      };
    },
    searchFilterApplied() {
      let newObj = {};
      for (let [key, value] of Object.entries(this.appliedFilters)) {
        if (key === 'search[cities]' || key === 'search[courier]') {
          if (String(value) !== '') newObj[key] = value;
        } else {
          if (value !== null) newObj[key] = value;
        }
      }
      for (let [key, value] of Object.entries(this.productSearchVariant)) {
        if (String(value) !== '') newObj[key] = value;
      }
      for (let [key, value] of Object.entries(this.productSearchAttribute)) {
        if (String(value) !== '') newObj[key] = value;
      }
      return newObj;
    },
    hasRatingValue() {
      return typeof this.appliedFilters['search[rating_gte]'] !== 'undefined';
    },
    productSearchAttribute() {
      return this.productSearch.attributes;
    },
    productSearchVariant() {
      return this.productSearch.variants;
    },
    cities() {
      return this.filterOption('cities');
    },
    provinces() {
      return this.filterOption('provinces');
    },
  },

  watch: {
    showFilters(val) {
      if (val) {
        this.isRendered = true;
      }
      this.$emit('show', this.showFilters);
    },
  },

  mounted() {
    this.isMounted = true;

    if (this.isOverlayActive) {
      this.showFilters = true;
    }
  },

  methods: {
    isBHLMKeyExist(key) {
      return this.bhlm && this.bhlm.params && this.bhlm.params[key];
    },
    paramValue(label) {
      if (label === 'cities' && String(this.productSearch.params['search[cities]']) === '') {
        return [''];
      }
      return this.productSearch.params[`search[${label}]`];
    },
    initialValue(label) {
      return this.initialProductSearch.params[`search[${label}]`];
    },
    updateSearchParam(label, value) {
      setTimeout(() => (this.isReset = false));
      const exist = this.filterArr.find(s => s.label === `search[${label}]`);
      if (exist) {
        exist.value = value;
      } else {
        this.filterArr.push({
          label: `search[${label}]`,
          value: value,
          type: 'params',
        });
      }
    },
    formatSearchParam(params) {
      params.forEach(param => {
        const element = this.filterArr.find(s => s.label === `search[${param.label}]`);
        const indeks = this.filterArr.indexOf(element);
        if (indeks !== -1 && String(param.value) === '0') {
          this.filterArr.splice(indeks, 1);
        } else {
          this.updateSearchParam(param.label, param.value);
        }
      });
    },
    applyFilter() {
      setTimeout(() => (this.hasReset = false));
      this.$emit('updated', this.filterArr, this.hasReset);
      this.clearFilterArr();
      this.tempParamsFiltered = {};
      this.tempVariantsFiltered = {};
      this.tempAttributesFiltered = {};
      this.showFilters = false;
    },
    clearFilterArr() {
      this.filterArr = this.filterArr.filter(v => {
        return v.type === 'variants' || v.type === 'attributes';
      });
    },
    updateSpecsParam(values, type) {
      setTimeout(() => (this.hasReset = false));
      Object.keys(values).forEach(key => {
        const exist = this.filterArr.find(s => s.label === key);
        if (exist) {
          exist.value = values[key];
        } else {
          this.filterArr.push({
            label: key,
            value: values[key],
            type,
          });
        }
      });
    },
    handleSpecificationChange({ label, value, type }) {
      let specification = {};

      const mergedSpecification = Object.assign({}, specification, {
        [label]: value,
      });

      this.updateSpecsParam(mergedSpecification, type);
    },
    resetFilter() {
      const searchParams = this.initialProductSearch.params;
      Object.keys(searchParams).forEach(key => {
        if (!this.searchExcludes.includes(key)) {
          const exist = this.filterArr.find(s => s.label === key);
          if (exist) {
            exist.value = searchParams[key];
          } else {
            this.filterArr.push({
              label: key,
              value: searchParams[key],
              type: 'params',
            });
          }
          this.productSearch.params[key] = searchParams[key];
        }
      });

      if (Object.keys(this.appliedFilters).length) {
        this.tempParamsFiltered = this.appliedFilters;
      }

      if (Object.keys(this.productSearchVariant).length) {
        this.tempVariantsFiltered = this.productSearchVariant;

        Object.keys(this.productSearchVariant).forEach(key => {
          const exist = this.filterArr.find(s => s.label === key);
          if (exist) {
            exist.value = [];
          } else {
            this.filterArr.push({
              label: key,
              value: [],
              type: 'variants',
            });
          }
        });
      }

      if (Object.keys(this.productSearchAttribute).length) {
        this.tempAttributesFiltered = this.productSearchAttribute;

        Object.keys(this.productSearchAttribute).forEach(key => {
          const exist = this.filterArr.find(s => s.label === key);
          if (exist) {
            exist.value = [];
          } else {
            this.filterArr.push({
              label: key,
              value: [],
              type: 'attributes',
            });
          }
        });
      }

      this.isReset = true;
      this.hasReset = true;
    },
    cancelFilter() {
      Object.keys(this.tempParamsFiltered).forEach(key => {
        this.productSearch.params[key] = this.tempParamsFiltered[key];
      });

      Object.keys(this.tempVariantsFiltered).forEach(key => {
        this.productSearch.variants[key] = this.tempVariantsFiltered[key];
      });

      Object.keys(this.tempAttributesFiltered).forEach(key => {
        this.productSearch.attributes[key] = this.tempAttributesFiltered[key];
      });

      this.isReset = false;
      this.hasReset = false;
    },
    handleCellShow() {
      this.$nextTick(() => {
        document.body.style.overflow = 'hidden';
      });
    },
  },
};
</script>

<style lang="scss">
.d-filter-content {
  .bl-sheet__box {
    background-color: #f1f2f5;
  }
}

.d-filter-card {
  .bl-cell__icon-right {
    .bl-radio {
      width: 20px;
    }

    .bl-checkbox__label {
      display: none;
    }
  }

  .d-filter-item-active {
    .bl-text {
      width: 100%;
    }
  }
}

.d-filter-chosen {
  height: 56px;
  margin-left: -16px;
  overflow: hidden;

  &-inner {
    padding-right: 8px;
    padding-bottom: 32px;
    padding-left: 16px;
    overflow-x: scroll;
    white-space: nowrap;
  }

  button {
    display: inline-block;
    padding-right: 12px;
    padding-left: 12px;
    margin: 0 8px 8px 0;
    background-color: #f9fafb;
    border-radius: 18px;
  }

  .bl-button__text {
    font-weight: normal;
  }
}
</style>
