<template>
  <div>
    <template v-if="withNavbar">
      <search-nav-bar ref="searchNavBar" :keyword="currentKeyword | toCapitalize" />
    </template>

    <div :class="wrapperClass">
      <bl-flex-container-mv
        justify-content="space-around"
        class="d-filter-container"
        :class="{
          'd-filter-container--sticky': isSticky,
          'd-filter-container--custom-sticky': isStickyCustom,
          'd-filter-container--show': isStickyShow,
          'd-filter-container--pos': isSSR || isReady,
          'd-filter-container--custom-sticky-with-overlay': isStickyCustom && hasActiveBlSheet,
        }"
      >
        <bl-flex-container-mv justify-content="space-around" class="d-filter-container__inner">
          <product-categories
            :current-category-id="currentCategoryId"
            :current-category-name="currentCategoryName"
            :facets="facets"
            :is-overlay-active="activeMenu === 'category'"
            style="max-width: 33%;"
            @selected="updateFilter"
            @show="
              handleStickyFilter($event);
              isPickCategoryActive = true;
            "
            @close="isPickCategoryActive = false"
          />
          <product-filters
            :product-search="currentProductSearch"
            :applied-filters="appliedFilters"
            :is-overlay-active="activeMenu === 'filter'"
            @updated="updateFilter"
            @show="
              handleStickyFilter($event);
              isFilterActive = true;
            "
            @close="isFilterActive = false"
          />
          <product-sorter
            :current-selected-sort="currentSelectedSort"
            :product-sort-options="productSortOptions"
            :is-overlay-active="activeMenu === 'sort'"
            @sort-change="handleSortChange"
            @show="handleStickyFilter"
          />
        </bl-flex-container-mv>
      </bl-flex-container-mv>
      <div class="d-container">
        <store-ads v-if="isStoreAdsShown" :product-search="currentProductSearch" />
        <div class="dp-heading-container">
          <bl-text-av v-if="isCategoryPage" tag="h1" class="dp-heading">
            <span class="titleLabel">Kategori</span>
            <span class="pageTitle">{{ pageTitle | toCapitalize }}</span>
            <span class="pageInfo">{{ pageNumber }}</span>
          </bl-text-av>
          <bl-text-av v-else tag="h1" class="dp-heading">
            <span v-if="currentKeyword" class="titleLabel">Hasil Pencarian</span>
            <span v-if="pageTitle" class="pageTitle" :class="{ tooltipped: currentKeyword }">{{
              pageTitle | toCapitalize
            }}</span>
            <span v-if="isIncludePageInfo" class="pageInfo">{{ pageNumber }}</span>
          </bl-text-av>
        </div>

        <template v-if="isShowShimmer">
          <product-grid-shimmer />

          <div v-if="pagination.currentPage === 1" class="bl-product-highlighted__wrapper">
            <product-highlight
              v-if="isSeoToggleActive && highlightedProduct"
              :highlighted-product="highlightedProduct"
              :current-keyword="seoKeyword"
              class="m-16"
            />
          </div>
          <div class="bl-product-price-table">
            <price-table
              v-if="isSeoToggleActive && priceTableProducts.length > 0"
              :products="priceTableProducts"
              :current-keyword="seoKeyword"
              class="m-16"
            />
          </div>
        </template>
        <template v-else-if="isProductsExist && !isError">
          <product-quick-filter
            :total-products="pagination.total || 0"
            :product-search="currentProductSearch"
            @selected="updateFilter"
          />
          <buka-games-banner :products="products" />
          <product-grid
            :products="products"
            :params-obj="paramsObj"
            @click="handleProductClick"
            @buy="handleBuyClick"
          />
          <bl-ghostblock-pagination-mv
            v-if="isSeoToggleActive && isGhostblock"
            :pagination="pagination"
            class="mt-16 mb-32 mh-16"
            is-multi-strategy
            :url-params="historySearchParamStr"
            :keyword="seoKeyword"
            aria-next-label="Halaman selanjutnya"
            aria-previous-label="Halaman sebelumnya"
            aria-page-label="Halaman"
            @pageChange="handlePageChange"
          />
          <product-pagination
            v-else
            :pagination="pagination"
            is-multi-strategy
            :url-params="paginateSearchParamsStr"
            aria-next-label="Halaman selanjutnya"
            aria-previous-label="Halaman sebelumnya"
            aria-page-label="Halaman"
            disable-last-page
            @pageChange="handlePageChange"
          />
          <related-keywords
            v-if="relatedKeywords.length > 0"
            :keywords="relatedKeywords"
            :label="`Pencarian Terkait ${currentKeyword}` | toCapitalize"
          />
          <div v-if="pagination.currentPage === 1" class="bl-product-highlighted__wrapper">
            <product-highlight
              v-if="isSeoToggleActive && highlightedProduct"
              :highlighted-product="highlightedProduct"
              :current-keyword="seoKeyword"
              class="m-16"
            />
          </div>
          <div class="bl-product-price-table">
            <price-table
              v-if="isSeoToggleActive && priceTableProducts.length > 0"
              :products="priceTableProducts"
              :current-keyword="seoKeyword"
              class="m-16"
            />
          </div>
        </template>
        <div v-else>
          <product-quick-filter
            v-if="isMounted"
            :total-products="pagination.total || 0"
            :product-search="currentProductSearch"
            @selected="updateFilter"
          />
          <product-unavailable
            v-if="isMounted"
            :is-filter="isAnyFilterApplied"
            style="padding-bottom: 24px;"
            @reset-filter="handleResetFilter"
            @open-omnisearch="openOmnisearch"
          />
          <related-keywords
            v-if="relatedKeywords.length > 0"
            :keywords="relatedKeywords"
            :label="`Pencarian Terkait ${currentKeyword}` | toCapitalize"
          />
          <product-grid v-if="relatedProducts.length > 0" :products="relatedProducts" :params-obj="paramsObj" />
        </div>
      </div>

      <bl-snackbar-mv
        v-if="isMounted"
        :active="errorMessage.show"
        :message="errorMessage.text"
        @close="hideErrorMessage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'external/Vuex';
import { mapWaitingGetters } from 'vue-wait';
import { throttle } from 'throttle-debounce';
import merge from 'lodash/merge';
import trackerUtility from '@/shared/utilities/trackerUtility';

import { PRODUCTS_FETCHING } from '@/shared/types/wait';
import { PAGE_TYPE } from '@/shared/types/productSearch';
import SearchNavBar from './SearchNavBar.vue';
import ProductGrid from './ProductGrid.vue';
import ProductCategories from './ProductCategories.vue';
import ProductFilters from './ProductFilters.vue';
import ProductSorter from './ProductSorter.vue';
import ProductUnavailable from './ProductUnavailable.vue';
import RelatedKeywords from './RelatedKeywords.vue';
import ProductGridShimmer from './ProductGridShimmer.vue';
import ProductPagination from './ProductPagination.vue';
import ProductQuickFilter from './ProductQuickFilter.vue';
import ProductSortTab from './ProductSortTab.vue';
import ProductHighlight from './ProductHighlight';
import PriceTable from './PriceTable.vue';
import BukaGamesBanner from './BukaGamesBanner.vue';

import { replaceDash } from '@/shared/helpers/text-helper';
import { score, chooseAlternative } from '@/shared/experiments';
import { STORE_AD_MWEB_EXPERIMENT_NAME } from '@/shared/types/experimentType';
import StoreAds from './StoreAds.vue';
import { SEO_MOBILE_SEARCH_TOGGLE } from '@/shared/types/toggleKeys';
import { productImpressionEvent, dynRmktParamsReadyEvent } from '@/shared/tracker/gmt';
import { shouldUseSeoMultistrategy } from '@/shared/utilities/bhlmUtility';

const BlSnackbarMv = () =>
  import(/* webpackChunkName: "bl-snackbar-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSnackbarMv').then(
    m => m.default || m,
  );

const ProductError = () =>
  import(/* webpackChunkName: "product-error" */ './ProductError.vue').then(m => m.default || m);

let throttled;

export default {
  name: 'ProductExplorer',

  components: {
    BlSnackbarMv,
    SearchNavBar,
    ProductGrid,
    ProductGridShimmer,
    ProductCategories,
    ProductFilters,
    ProductSorter,
    ProductUnavailable,
    ProductError,
    RelatedKeywords,
    ProductPagination,
    ProductQuickFilter,
    StoreAds,
    ProductSortTab,
    ProductHighlight,
    PriceTable,
    BukaGamesBanner,
  },

  filters: {
    toCapitalize(str) {
      if (typeof str !== 'string') return '';
      return str
        .split('-')
        .join(' ')
        .replace(/\w\S*/g, txt => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },
  },

  props: {
    initialUrl: {
      type: String,
      required: true,
    },
    withNavbar: {
      type: Boolean,
      default: true,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    isSSR: {
      type: Boolean,
      default: !!process.server,
    },
    csrMode: {
      type: Boolean,
      default: false,
    },
    seoPageTitle: {
      type: String,
      default: '',
    },
    isSkeleton: {
      type: Boolean,
      default: false,
    },
    toggles: {
      type: Array,
      default: () => [],
    },
    configs: {
      type: Array,
      default: () => [],
    },
    isSearchListingPage: {
      // yep. state for `isProductSearchPath` still differs on server and client. So our trusted isSearchListingPage returns.
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: false,
      tempScroll: 0,
      isSticky: false,
      isStickyShow: false,
      isReady: false,
      scrollTolerant: 102,
      customStickyHeaderClass: '',
      isStickyCustom: false,
      isBeyondScrollTolerance: false,
      isPickCategoryActive: false,
      isFilterActive: false,
      experimentAlternative: 'normal',
      isCategoryFiltered: false,
      enterStick: true,
      scrollPosition: 0,
      historySearchParamStr: this.paginateSearchParamsStr,
      lastTrackedKeyword: '',
      hasFetchFilterOptions: false,
    };
  },

  computed: {
    isShowShimmer() {
      return (
        (!this.isMounted && this.csrMode) ||
        this.isFetchingProducts ||
        (this.isSSR && (this.isCategoryPage || this.isBhlm || this.isTrendBrandPage))
      );
    },
    pageTitle() {
      if (this.seoPageTitle) return this.seoPageTitle;
      if (this.bhlm && this.bhlm.page) return this.bhlm.dashed.page_title;
      if (this.isCategoryPage) return this.currentCategoryName;
      if (this.isTagPage) return `Jual ${this.tagPage.name}`;
      if (this.isTrendBrandPage) return `Jual ${this.trendBrand.display_name} Murah dan Berkualitas`;
      return this.currentKeyword;
    },
    pageNumber() {
      return this.pagination.currentPage !== 1 ? ` (halaman ${this.pagination.currentPage})` : '';
    },
    isIncludePageInfo() {
      return this.pagination && ((this.bhlm && this.bhlm.page) || this.isProductSearchPath || this.isCategoryPage);
    },
    hasCustomStickyHeader() {
      return this.customStickyHeaderClass !== '';
    },
    wrapperClass() {
      return [
        this.withNavbar ? 'content-wrapper o-layout--fixed-header' : '',
        this.hasCustomStickyHeader ? this.customStickyHeaderClass : '',
      ];
    },
    isProductsExist() {
      return this.products.length > 0;
    },
    paramFrom() {
      if (this.bhlm && this.bhlm.page) return PAGE_TYPE.BHLM;
      if (this.isTagPage) return PAGE_TYPE.TAG;
      return 'list-product';
    },
    paramsObj() {
      return {
        from: this.paramFrom,
        keyword: this.currentKeyword,
        funnel: this.currentProductSearchParams['search[keywords]'] !== '' ? 'omnisearch' : '',
        cf: '1',
        sort_origin: this.currentSelectedSort.value === '' ? 'relevansi' : this.currentSelectedSort.value,
        search_sort_default: this.currentSelectedSort.value === '',
        isAnyFilterApplied: this.isAnyFilterApplied,
        isCategoryPage: this.isCategoryPage,
      };
    },
    facets() {
      return this.getCollection('facets');
    },
    ...mapGetters([
      'isError',
      'products',
      'productSearch',
      'bhlm',
      'trendBrand',
      'isProductSearchPath',
      'isCategoryPage',
      'productSearchParamsStr',
      'paginateSearchParamsStr',
      'currentProductSearchParams',
      'currentKeyword',
      'currentProductSearch',
      'productSortOptions',
      'currentCategoryId',
      'currentCategoryName',
      'isAnyFilterApplied',
      'getCollection',
      'pagination',
      'errorMessage',
      'appliedFilters',
      'initialProductSearch',
      'highlightedProduct',
      'priceTableProducts',
      'isBackHistory',
    ]),
    ...mapGetters('related', {
      relatedKeywords: 'keywords',
      relatedProducts: 'products',
    }),
    ...mapGetters('sort', {
      currentSelectedSort: 'currentSelectedSort',
    }),
    ...mapWaitingGetters({
      isFetchingProducts: [PRODUCTS_FETCHING],
    }),
    hasActiveBlSheet() {
      return this.isPickCategoryActive || this.isFilterActive;
    },
    isStoreAdsShown() {
      return this.experimentAlternative !== 'normal';
    },
    ...mapState(['productCampaignPageData', 'tagPage']),
    promoTitle() {
      return (
        this.productCampaignPageData && this.productCampaignPageData.promo && this.productCampaignPageData.promo.title
      );
    },
    promoCategoryId() {
      const promoCategoryId = this.isCategoryFiltered
        ? this.currentCategoryId
        : this.productCampaignPageData &&
          this.productCampaignPageData.category &&
          this.productCampaignPageData.category.id;
      return promoCategoryId ? `${promoCategoryId}` : '';
    },
    categorySlugPrefix() {
      if (global && global._INITIAL_PRODUCT_CAMPAIGN_DATA_) {
        const prefix = global.location.pathname
          .split('/')
          .splice(0, 3)
          .join('/');
        return `${prefix}/`;
      }
      return '/c/';
    },
    isTagPage() {
      return this.tagPage && this.tagPage.valid;
    },
    isBhlm() {
      return Boolean(this.bhlm && this.bhlm.page);
    },
    isBhlmk() {
      return this.paramFrom === 'bhlm' && Boolean(this.bhlm.params) && Boolean(this.bhlm.params.k);
    },
    // Allowed pages: bhlmk, products/s, tag, and brand
    // Apparently the bug is still here. So isSearchListingPage is still needed.
    isWhitelistedSeoMultistrategyPages() {
      return (
        this.isBhlmk ||
        this.isProductSearchPath ||
        this.isSearchListingPage ||
        this.isTagPage ||
        this.trendBrand.type === 'brand'
      );
    },
    isTrendBrandPage() {
      return Boolean(this.trendBrand && this.trendBrand.page);
    },
    isGhostblock() {
      return this.isProductSearchPath || this.isCategoryPage || this.isBhlm || this.isTagPage || this.isTrendBrandPage;
    },
    seoKeyword() {
      if (this.isCategoryPage) return this.currentCategoryName;
      if (this.isBhlm) return replaceDash(this.bhlm.keyword);
      if (this.isTrendBrandPage) return replaceDash(this.trendBrand.display_name);
      return this.currentKeyword;
    },
    isSeoToggleActive() {
      const toggle = this.toggles.find(t => t.id === SEO_MOBILE_SEARCH_TOGGLE);
      return (toggle && toggle.active) || false;
    },
    activeMenu() {
      return new URL(this.initialUrl).searchParams.get('open');
    },
    currentQueryParams() {
      return this.productSearch.urlObj.query || this.productSearchParamsStr || '';
    },
    shouldUseSeoMultistrategy() {
      return shouldUseSeoMultistrategy(this.isWhitelistedSeoMultistrategyPages, this.currentQueryParams);
    },
  },
  watch: {
    isFilterActive() {
      if (!this.hasFetchFilterOptions) this.checkFilterOptions();
    },
    isProductsExist(newVal, oldVal) {
      const productNotFound = newVal === false && oldVal === true;
      if (productNotFound) {
        this.retrieveRelatedProducts();
      } else {
        this.trackSearch();
      }
    },
    paginateSearchParamsStr() {
      this.historySearchParamStr = this.paginateSearchParamsStr;
      this.$nextTick(() => {
        this.updateHistorySearchParams();
      });
    },
  },

  async serverPrefetch() {
    await this.initializeMobileSearch({ url: this.initialUrl, SSR: true });
    return await this.updateMobilePage({
      initializePage: true,
      SSR: true,
      isSkeleton: this.isSkeleton,
      isLoggedIn: this.isLoggedIn,
      shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
    });
  },

  async mounted() {
    this.isMounted = true;

    const customStickyHeaderClassDataElement = document.querySelector('[data-sticky-header-class]');
    const customStickyHeaderScrollTolerant = document.querySelector('[data-sticky-header-scroll-tolerant]');

    if (customStickyHeaderClassDataElement) {
      this.customStickyHeaderClass = customStickyHeaderClassDataElement.getAttribute('data-sticky-header-class');
    }

    if (customStickyHeaderScrollTolerant) {
      this.scrollTolerant = parseInt(
        customStickyHeaderScrollTolerant.getAttribute('data-sticky-header-scroll-tolerant'),
      );
    }

    this.isReady = true;

    // if ssr store doesn't provide products because of error
    if ((!this.isSSR && !this.isProductSearchPath) || (!this.isProductsExist && this.isError)) {
      await this.updateMobilePage({
        initializePage: true,
        SSR: false,
        isLoggedIn: this.isLoggedIn,
        shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
      });
    }

    this.$nextTick(() => {
      if (!this.csrMode) {
        // call miscs not called in ssr
        this.retrieveMobileMiscCollections();
      }

      if (!this.isProductsExist) {
        this.retrieveRelatedProducts();
      } else {
        this.trackSearch();
      }

      this.retrieveCouriers();

      throttled = throttle(300, () => {
        this.handleScroll();
      });

      window.addEventListener('scroll', throttled);
    });
    this.setStoreAdsToggleBySplitter();
  },

  beforeDestroy() {
    window.removeEventListener('scroll', throttled);
  },

  methods: {
    ...mapActions([
      'initializeMobileSearch',
      'updateProductSearchParams',
      'setProductCartQuantity',
      'updateMobilePage',
      'updatePagination',
      'updateHistory',
      'showErrorMessage',
      'hideErrorMessage',
      'retrieveAddresses',
      'retrieveCouriers',
      'updateProductSearchAttributes',
      'updateProductSearchVariants',
      'chargePromotedProduct',
      'retrieveMobileMiscCollections',
    ]),

    ...mapActions('sort', {
      updateCurrentActiveSort: 'updateCurrentActiveSort',
    }),

    ...mapActions('related', {
      retrieveRelatedProducts: 'retrieveProducts',
    }),

    async handlePageChange(newPage) {
      const { limit } = this.pagination;
      const offset = (newPage - 1) * limit;

      this.updatePagination({ label: 'currentPage', value: newPage });
      this.updatePagination({ label: 'offset', value: offset });

      let forceUrl = '';
      if (this.isProductSearchPath) {
        const params = this.paginateSearchParamsStr ? `&${this.paginateSearchParamsStr}` : '';
        forceUrl = `${this.productSearch.urlObj.pathname}?page=${newPage}${params}`;
      }

      await this.updateMobilePage({
        scrollToTop: true,
        forceUrl,
        shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
        isLoggedIn: this.isLoggedIn,
      });
    },

    async handleSortChange(sortOption) {
      this.updateCurrentActiveSort(sortOption);
      this.updateProductSearchParams([
        {
          label: 'search[sort_by]',
          value: sortOption.value,
        },
      ]);

      await this.updateMobilePage({
        shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
        isLoggedIn: this.isLoggedIn,
      });
    },

    handleFilterUpdate(values, { updatePage = true, fromPopularFilter = false } = {}) {
      this.handleSearchUpdates(values);

      if (updatePage)
        this.updateMobilePage({
          fromPopularFilter,
          shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
          isLoggedIn: this.isLoggedIn,
        });
    },

    handleSearchUpdates(values) {
      const params = values.filter(v => {
        return v.type === 'params';
      });
      const attributes = values.filter(v => {
        return v.type === 'attributes';
      });
      const variants = values.filter(v => {
        return v.type === 'variants';
      });

      if (Object.keys(params).length) this.updateProductSearchParams(params);
      if (Object.keys(attributes).length) {
        let attrObj = {};
        attributes.forEach(item => {
          attrObj[item.label] = item.value;
        });
        this.updateProductSearchAttributes(attrObj);
      }
      if (Object.keys(variants).length) {
        let varObj = {};
        variants.forEach(item => {
          varObj[item.label] = item.value;
        });
        this.updateProductSearchVariants(varObj);
      }
    },

    handleProductClick(product) {
      if (product.position_type === 'promoted') {
        this.chargePromotedProduct(product);
        score({
          experimentName: STORE_AD_MWEB_EXPERIMENT_NAME,
          metricName: 'visit_promoted_product_from_search',
          value: 1,
        });
      }
      // visit metric
      score({ experimentName: STORE_AD_MWEB_EXPERIMENT_NAME, metricName: 'visit_product_from_search', value: 1 });

      if(this.$store.state.searchContext?.content?.search_query_id) {
        this.$store.dispatch( 'searchContext/sendExperimentContext', {
            event: 'visit-product-event',
            platform: 'mweb',
            pos: product.position_order,
            productType: product.position_type
        });
      }
    },

    async handleBuyClick(product) {
      this.$set(product, 'isLoading', true);
      const isLoggedIn = window.USER && window.USER.id36;

      let cartPayload = {
        quantity: product.min_quantity,
        product,
        query: {
          listing_revamp: true,
        },
        headers: {}
      }

      if(this.$store.state.searchContext?.content?.search_query_id && typeof window?.USER !== 'undefined') {
        const experimentContext = {
          user_id: window.USER.id,
          platform: 'mweb',
          referrer: 'product_listing_page',
          product_slot_type: product.position_type,
          pos: product.position_order,
          ...this.$store.state.searchContext.content
        };

        cartPayload = merge({}, cartPayload, { headers: { 'X-Search-Sort-Default': true }, experiment_context: experimentContext });
      }

      try {
        const { data } = await window.STORE.dispatch('cart/addOrUpdateProductToCart', {
          ...cartPayload
        });

        if (window.externalTracker) {
          window.externalTracker.track.addToCart({
            product,
            quantity: product.min_quantity,
          });
        }

        // inconsistency on addOrUpdateProductToCart returned data
        const items = data.items || [];
        const item = items.find(o => o.stuff && o.stuff.product_id && o.stuff.id === product.sku_id);

        const payload = {
          cartId: data.id || '',
          cartItemId: item && item.id,
          platform: 'mobile_web',
          quantity: product.min_quantity,
          referrerEvent: 'product_explorer/beli',
        };

        await trackerUtility.trackAddToCart(payload, product);

        let url = `/payment/purchases/new?referrer_event=product_explorer/beli&product_sku_id=${product.sku_id}&product_id=${product.id}&seller_ids=[${product.store.id}]`;

        if (item && item.id) {
          url = `${url}&item_ids=[${item.id}]`;
        }

        if (!isLoggedIn) {
          window.location = `/login?comeback=${encodeURIComponent(url)}`;
        } else {
          window.location = encodeURI(url);
        }
      } catch (e) {
        let message = false;

        if (e.response && e.response.data && e.response.data.errors) message = e.response.data.errors[0].message;

        this.showErrorMessage(message);
      }
    },

    updateFilter(values, hasReset = false) {
      const filterCategoryId = values.find(value => value.label === 'search[category_id]');
      if (filterCategoryId) {
        this.isCategoryFiltered = true;
      }
      if (hasReset) {
        this.updateMobilePage({
          initializePage: false,
          shouldUseSeoMultistrategy: this.shouldUseSeoMultistrategy,
          isLoggedIn: this.isLoggedIn,
        });
      } else {
        this.handleFilterUpdate(values, { updatePage: true });
      }
    },
    handleScroll() {
      const currentPos = global.window.scrollY;
      this.isBeyondScrollTolerance = currentPos > this.scrollTolerant;
      this.isStickyCustom = this.hasCustomStickyHeader && this.isBeyondScrollTolerance;
      this.isSticky = currentPos <= this.tempScroll ? false : this.isBeyondScrollTolerance;
      this.tempScroll = currentPos;
      if (this.isStickyCustom) {
        setTimeout(() => {
          this.enterStick = false;
        }, 0);
      } else {
        this.enterStick = true;
      }
    },
    handleStickyFilter(show) {
      this.isStickyShow = show;
    },
    enableStickyFilterClass() {
      this.handleStickyFilter(true);
      this.isFilterActive = true;
      const $body = document.querySelector('body');
      this.scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${this.scrollPosition}px`;
      $body.style.width = '100%';
    },
    disableStickyFilterClass() {
      this.isFilterActive = false;
      const $body = document.querySelector('body');
      $body.style.removeProperty('overflow');
      $body.style.removeProperty('position');
      $body.style.removeProperty('top');
      $body.style.removeProperty('width');
      window.scrollTo(0, this.scrollPosition);
    },
    async setStoreAdsToggleBySplitter() {
      try {
        const alternative = await chooseAlternative({ experimentName: STORE_AD_MWEB_EXPERIMENT_NAME });
        this.experimentAlternative = alternative;
      } catch (error) {
        this.experimentAlternative = 'normal';
      }
    },

    openOmnisearch() {
      this.$refs.searchNavBar.openOmnisearch();
    },

    async handleResetFilter() {
      this.$refs.productFilters.resetFilter();
      await this.$nextTick();
      this.$refs.productFilters.applyFilter();
    },

    handleChangeTab(sortOption) {
      this.handleSortChange(sortOption);
      if (this.scrollTolerant && this.isBeyondScrollTolerance) {
        document.documentElement.scrollTop = this.scrollTolerant || 0;
      }
    },

    updateHistorySearchParams() {
      const back = 'back_browser=1';
      this.historySearchParamStr = this.paginateSearchParamsStr.replace(`&${back}`, '').replace(back, '');
    },

    trackSearch() {
      if (this.currentKeyword !== this.lastTrackedKeyword) {
        this.lastTrackedKeyword = this.currentKeyword; // prevent multiple call
        dynRmktParamsReadyEvent(this.products);
        productImpressionEvent(this.currentKeyword, this.products);
      }
    },
    checkFilterOptions() {
      this.retrieveAddresses();
      this.hasFetchFilterOptions = true;
    },
  },
};
</script>

<style scoped lang="scss">
.d-filter-container-wrapper {
  background-color: #fff;
  border-bottom: 2px solid #e8eaef;
}

.d-filter-container {
  width: 100%;
  transition: transform 0.3s ease-in-out;

  &__inner {
    height: 46px;
    padding: 0 8px;
    background-color: #fff;
    border-bottom: 2px solid #e8eaef;
  }

  &--pos {
    position: fixed;
    z-index: 996;
    transition: transform 0.3s ease-in-out;
  }

  &--sticky {
    transform: translateY(-46px) translateZ(0);
  }

  &--show {
    z-index: 997;
  }

  &--custom-sticky-with-overlay {
    z-index: 9999 !important;
  }
}

.content-wrapper--promo {
  padding-top: 111px !important;
}

.has-page-header {
  &.o-layout--fixed-header {
    padding-top: 0;
  }

  .d-filter-container {
    &--pos {
      position: initial;
      z-index: 99;
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    &--show {
      height: 100%;
      z-index: 101; // set layer above header
    }

    &--custom-sticky {
      position: fixed;
      top: 0;
      padding-top: 56px;

      &.d-filter-container--sticky {
        transform: translateY(-111px) translateZ(0);
        &-promo {
          transition: none;
        }
      }
    }
  }

  .d-filter-space {
    transition: height 0.3s ease-in-out;

    &.active {
      height: 46px;
    }
  }

  .d-container {
    padding-top: 1px;
  }
}

.d-container {
  padding-top: 46px;
  padding-bottom: 2px;
  background-color: #f1f2f5;
}

.dp-heading {
  margin: 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;

  .tooltipped {
    &::before {
      content: '\201C';
    }

    &::after {
      content: '\201D';
    }
  }
}
</style>
