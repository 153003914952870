<template>
  <bl-flex-container-mv class="dp-grid">
    <bl-flex-container-mv direction="column" v-for="(chunk, chunkId) in productChunks" :key="chunkId" width="50%">
      <ProductCard
        v-for="(product, idx) in chunk"
        :key="`product-${product.id}-${product.position_type}-${idx}`"
        :use-webp="supportWebp"
        :product="product"
        :params-obj="paramsObj"
        :lazy-image="idx > 1"
        :is-p-w-a-buka-games="isPWABukaGames"
        v-on="$listeners"
      />
    </bl-flex-container-mv>
  </bl-flex-container-mv>
</template>

<script>
import ProductCard from './ProductCard.vue';

export default {
  name: 'ProductGrid',
  components: {
    ProductCard,
  },
  props: {
    products: {
      type: Array,
      default() {
        return [];
      },
    },
    paramsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPWABukaGames: false,
    };
  },
  computed: {
    productChunks() {
      const flexArray = [[], []];

      this.products.forEach((v, i) => {
        flexArray[i % 2].push(v);
      });

      return flexArray;
    },

    supportWebp() {
      if (process.server) {
        return true;
      }
      return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    },
  },
  mounted() {
    this.getPWAGamesState();
  },
  methods: {
    getPWAGamesState() {
      this.isPWABukaGames = typeof window !== 'undefined' && sessionStorage.getItem('isPWABukaGames') === 'true';
    },
  },
};
</script>

<style scoped lang="scss">
.dp-grid {
  padding: 4px 0;
}
</style>
