const upperFirst = require('lodash/upperFirst');
const _flattenDeep = require('lodash/flattenDeep');
const { upstreamErrorLogger } = require('shared/utilities/monitoring');
const { SEO_PROPERTIES_LOGGER_RULE } = require('shared/constants/loggerRule');
const { toTitleCase } = require('@bukalapak/toolbox-helper/string-helper');
const TRENDQWORD_HOST = process.env.TRENDQWORD_HOST;

function cleanupMerk(params = {}) {
  let newParams = Object.assign({}, params);
  if (newParams.m) delete newParams.m;
  return newParams;
}

function reformatPriceText(params = {}) {
  const newParams = { ...params };
  const priceStr = newParams.h || '';

  if (priceStr) newParams.h = priceStr.replace(/\s/, '').toLowerCase();

  return newParams;
}

const SEARCH_PARAM_PAGE = 'page';
const SEARCH_PARAM_SORT_BY = 'search[sort_by]';
const SEARCH_PARAM_KEYWORDS = 'search[keywords]';
const SEARCH_PARAM_FORCE_LEAN = 'isLeanVersion';
const SEARCH_PARAM_SOURCE = 'search_source';

const fetchBhlm = {
  toBhlm(url) {
    const uri = new URL(url);
    if (uri.pathname.includes('/merk/')) {
      return uri.href.replace('erk/', '-');
    }

    if (uri.pathname.includes('/lokasi/')) {
      return uri.href.replace('okasi/', '-');
    }

    return false;
  },
  isBhlm(url) {
    // https://regex101.com/r/jsZgcH/2
    const bhlmPage = /^\/c(\/[\w-]+){1,3}\/([bhlmk]-.+|\blokasi|\bmerk)/;
    const uri = new URL(url);
    const validRegex = bhlmPage.test(uri.pathname);

    if (validRegex === false) {
      return false;
    }

    const toBhlm = fetchBhlm.toBhlm(url);
    const updateUrl = toBhlm ? toBhlm : url;
    const { bhlm } = fetchBhlm.generateUrl(updateUrl);
    const split = bhlm.split('-');

    if (bhlm.slice(-1) === '-') {
      return false;
    }

    // check empty value e.g: m-l-jakarta-selatan
    for (let index = 0; index <= split.length; index++) {
      const param = split[index];
      const currentLength = param.length;
      const nextLength = split[index + 1] && split[index + 1].length;

      if (currentLength === 1) {
        return nextLength > 1;
      }
    }

    return validRegex;
  },
  upperCase(pathname) {
    return (
      (pathname &&
        pathname
          .split('-')
          .map(word => {
            return word ? upperFirst(word) + ' ' : '';
          })
          .join('')) ||
      ''
    );
  },
  relatedCategories(categories, params) {
    if (!categories?.length) return [];
    const formattedParams = reformatPriceText(cleanupMerk(params));

    return categories.map(category => {
      const urlName = category.name;
      const paramEntries = Object.entries(formattedParams).sort();
      const flattenParams = _flattenDeep(paramEntries);
      const urlLink = `/c/${category.permalink}/${flattenParams.join('-')}`;

      return {
        ...category,
        urlName,
        urlLink,
      };
    });
  },
  relatedCategoriesTitle(params, prevCat) {
    let cat = '';
    let b = '';
    let h = '';
    let l = '';

    if (prevCat) {
      cat = `${upperFirst(prevCat.split('/').slice(-1)[0])} `;
    }

    if (params.b) {
      b = `${upperFirst(params.b)} `;
    }

    if (params.h) {
      h = `${upperFirst(params.h)} `;
    }

    if (params.l) {
      l = `di ${toTitleCase(params.l.replace('-', ' '))}`;
    }

    return `${cat}${b}${h}${l}`.trim();
  },
  getKeywordFromBhlmUrl(params, initialCategory) {
    let category = '';
    let m = '';
    let b = '';
    let l = '';
    let h = '';
    let k = '';

    if (initialCategory) {
      category = `${initialCategory} `;
    }

    if (params.k) {
      k = `${params.k} `;
    }

    if (params.m) {
      m = `${params.m} `;
    }

    if (params.b) {
      b = `${params.b} `;
    }

    if (params.l) {
      l = `${toTitleCase(params.l.split('-').join(' '))} `;
    }

    if (params.h) {
      h = `${params.h}`;
    }

    return `${category}${k}${m}${b}${l}${h}`.toLowerCase().trim();
  },
  generateUrl(url) {
    let uri = new URL(url);
    // remove trailing '/' at the beginning and the end
    // change '/c/handphone/b-baru/' to 'c/handphone/b-baru'
    const pathname = uri.pathname.replace(/^\/|\/$/g, '');
    uri = uri.pathname.split('/');

    const type = uri[1];
    const bhlm = uri.slice(-1)[0];

    let permalink = uri;
    permalink.splice(0, 2); // remove /c/ from array
    permalink.splice(-1); // remove /b-h-l-m-k from array
    permalink = permalink.join('/');

    let prevCat = uri;
    if (prevCat.length > 1) {
      prevCat.splice(-1);
    }
    prevCat = prevCat.join('/');

    return {
      type,
      prevCat,
      bhlm,
      permalink,
      pathname,
    };
  },
  generateCrumbs({ type, breadcrumbs }) {
    const crumbs = [{ name: 'Home', url: '/' }];

    for (const cat of breadcrumbs) {
      crumbs.push({
        name: cat.text,
        url: `/${type}/${cat.permalink}`,
        type: 'category',
      });
    }

    return crumbs;
  },
  generateParams(dashed, bhlm) {
    let params = {};

    if (dashed && dashed.search_params) {
      if (dashed.search_params.new || dashed.search_params.used) {
        params['b'] = dashed.search_params.new ? 'baru' : 'bekas';
      }

      if (!dashed.search_params.keyword && dashed.search_params.price_min) {
        let result = '';
        // https://regex101.com/r/S7yQTM/1
        let matchString = bhlm.match(/-?h-(.*?)((-[bhlmk]-)|$)/);
        const word = matchString[1];

        const formatWord = word => {
          return word
            .match(/[^\d]+|\d+/g) // seperate number and strings
            .map(upperFirst)
            .join(' ')
            .trim();
        };

        // check if it contains multiple words
        // example: 'dibawah-2jt' -> ['dibawah', '2jt']
        const hasMultipleWords = word.split('-').length > 1;

        if (hasMultipleWords) {
          const [firstWord, secondWord] = word.split('-');
          result = `${firstWord} ${formatWord(secondWord)}`;
        } else {
          result = formatWord(word);
        }

        params['h'] = result;
      }

      if (dashed.search_params.province) {
        params['l'] = dashed.search_params.province
          .toLowerCase()
          .split(' ')
          .join('-');
      }

      if (dashed.search_params.city) {
        params['l'] = dashed.search_params.city
          .toLowerCase()
          .split(' ')
          .join('-');
      }

      if (dashed.search_params.filter_attr && dashed.search_params.filter_attr.brand) {
        params['m'] = dashed.search_params.filter_attr.brand[0].replace(' ', '-').toLowerCase();
      }

      if (dashed.search_params.keyword) {
        params['k'] = dashed.search_params.keyword
          .toLowerCase()
          .split(' ')
          .join('-');
      }
    }
    params = Object.assign({}, params);

    return params;
  },
  async fetchBhlm({ secureClient, hookStorage, url, logger }) {
    let { type, prevCat, bhlm, permalink, pathname } = fetchBhlm.generateUrl(url);

    const requestApi = [
      secureClient
        .get(`/categories/permalink/${prevCat}`)
        .then(res => res.data)
        .catch(err => {
          const status = err.response && err.response.status;
          logger.error(err.message, {
            stack: err.stack,
            tags: 'dora_categories_permalink',
            api: `/categories/permalink/${prevCat}`,
            url,
            status,
          });
          hookStorage.status = status;
          hookStorage.errorMessage = err.message;
        }),
      secureClient
        .get(`${TRENDQWORD_HOST}/_internal/dashed-filter-page`, 'public', {
          auth: {
            username: process.env.TRENDQWORD_BASIC_USER,
            password: process.env.TRENDQWORD_BASIC_PASSWORD,
          },
          params: {
            permalink,
            dashed_filter_slug: bhlm,
          },
        })
        .then(res => res.data)
        .catch(err => {
          const status = err.response && err.response.status;
          upstreamErrorLogger({
            logger,
            err,
            tags: 'dora_internal_dashed_filter_page',
            api: `_internal/dashed-filter-page?permalink=${permalink}&dashed_filter_slug=${bhlm}`,
            url,
            status,
            loggerStatusRule: SEO_PROPERTIES_LOGGER_RULE,
          });
          hookStorage.status = status;
          hookStorage.errorMessage = err.message;
        }),
      secureClient
        .get(`${TRENDQWORD_HOST}/_internal/seo-properties/${pathname}`, 'public', {
          auth: {
            username: process.env.TRENDQWORD_BASIC_USER,
            password: process.env.TRENDQWORD_BASIC_PASSWORD,
          },
        })
        .then(res => res.data)
        .catch(err => {
          const status = err.response && err.response.status;
          upstreamErrorLogger({
            logger,
            err,
            tags: 'dora_bhlm_seo_properties',
            api: '/_internal/seo-properties/:permalink',
            loggerStatusRule: SEO_PROPERTIES_LOGGER_RULE,
          });
          hookStorage.status = status;
          hookStorage.errorMessage = err.message;
        }),
    ];
    const [categories, dashed = null, seoProperties = null] = await Promise.all(requestApi);
    const params = dashed ? fetchBhlm.generateParams(dashed, bhlm) : {};
    const crumbs = dashed ? fetchBhlm.generateCrumbs({ type, breadcrumbs: dashed.breadcrumbs }) : [];
    const page = await fetchBhlm.isBhlm(url);
    const relatedCategories = fetchBhlm.relatedCategories(categories?.children, params);
    const relatedCategoriesTitle = fetchBhlm.relatedCategoriesTitle(params, prevCat);

    const totalParams = Object.keys(params).length;
    const prevCategoryParentCrumb = dashed && dashed.breadcrumbs[dashed.breadcrumbs.length - (totalParams + 1)];
    const prevCategoryParentCrumbText = prevCategoryParentCrumb ? prevCategoryParentCrumb.text : '';
    const keyword = fetchBhlm.getKeywordFromBhlmUrl(params, prevCategoryParentCrumbText);

    return {
      bhlm: {
        page,
        crumbs,
        dashed,
        params,
        relatedCategories,
        relatedCategoriesTitle,
        seoProperties,
        keyword,
      },
    };
  },
  getKeywordFromCategoryUrl(bhlmUrl) {
    const bhlm = `${bhlmUrl}-x-`;
    if (bhlm.includes('k-')) {
      /*
      match word-string between k- and -{b|h|l|m|x}-
      eg:
      `/k-bagus-dan-murah-h-1jutaan-x-` --> matched: `bagus-dan-murah``
      `/k-garansi-x-` --> matched: `garansi`
      `/b-bekas-l-bandung-x- will` --> not match
      */
      const matches = bhlm.match(/[-/]k-(.*?-[bhlmx]|.*?)-[bhlmx]-/);
      return matches && matches[1] ? matches[1].replace(/-/g, ' ') : '';
    }
    return '';
  },
  shouldUseSeoMultistrategy(isWhitelistedSeoMultistrategyPages = false, queryParamsStr = '') {
    // This function will delete all allowed query param from queryParamStr
    // If there is nothing left, then it means all query params is allowed so this func will return true
    // If there is any query param is left behind, then it means there is unallowed query param so this will return false
    const searchParams = new URLSearchParams(queryParamsStr);
    searchParams.delete(SEARCH_PARAM_PAGE);
    searchParams.delete(SEARCH_PARAM_SORT_BY);
    searchParams.delete(SEARCH_PARAM_KEYWORDS);
    const isLeanVersion = searchParams.get(SEARCH_PARAM_FORCE_LEAN) === 'true';
    if (isLeanVersion) {
      searchParams.delete(SEARCH_PARAM_FORCE_LEAN);
    }
    const isComingFromRelated = searchParams.get(SEARCH_PARAM_SOURCE) === 'related_keywords';
    if (isComingFromRelated) {
      searchParams.delete(SEARCH_PARAM_SOURCE);
    }

    return isWhitelistedSeoMultistrategyPages && searchParams.toString().length === 0;
  },
};

module.exports = fetchBhlm;
