const qs = require('query-string');
const _get = require('lodash/get');
const _isString = require('lodash/isString');

function isCategoryPath(path) {
  const categoryPathRegexPattern = /^\/c\/\w+/;
  return categoryPathRegexPattern.test(path);
}

function getPermalink(path) {
  return path.replace(/^\/c\//, '');
}

function formatKeyword(keyword) {
  return keyword ? keyword.replace(/%20/g, ' ') : '';
}

function removeQuestionMark(search) {
  return search ? search.replace(/^\?/, '') : '';
}

function getKeyword({ query, path }, queryStringed = false) {
  const params = queryStringed ? query : qs.parse(removeQuestionMark(query));

  // could posiblly return string or array of string
  const keyword = _get(params, 'search[keywords]', '');

  if (!params.search && path.includes('/s/')) {
    const lastIndex = path.split('/').length - 1;
    return formatKeyword(
      path
        .split('/')
        [lastIndex].split('-')
        .join(' ') || '',
    );
  }

  return formatKeyword(_isString(keyword) ? keyword : keyword[keyword.length - 1]);
}

function removeDash(str) {
  return str.replace(/-/g, ' ');
}

function removeNumber(str) {
  return str.replace(/\d/g, '');
}

const formatCategoryDisplayName = name => formatKeyword(removeDash(removeNumber(name))); // because lodash pipe is too big

module.exports = {
  isCategoryPath,
  getPermalink,
  formatKeyword,
  getKeyword,
  formatCategoryDisplayName,
};
