<template>
  <div>
    <bl-button-av variation="naked" style="padding: 0 8px;" @click="showCategories = true">
      <div slot="icon" class="btn-category-icon">
        <ico-category-minor />
        <ico-check-circle v-if="currentCategoryId" color="rgb(70, 196, 144)" class="d-tick-badge" />
      </div>
      <bl-text-av class="btn-category-text" :ellipsis="1" style="text-align: left;">
        {{ currentCategoryName ? currentCategoryName : 'Kategori' }}
      </bl-text-av>
    </bl-button-av>
    <bl-sheet-mv v-if="isMounted" :active.sync="showCategories" title="Pilih Kategori">
      <div v-if="isRendered" slot="content">
        <filter-category
          v-if="facets.length > 0"
          :categories="facets"
          :current-category-id="currentCategoryId"
          :prefix="categorySlugPrefix"
          @select="selectCategory"
        />
        <div v-else style="padding: 16px;">
          <bl-callout-mv variation="high">
            <bl-text-av variation="caption-12" style="color: #cc9b54;">
              Maaf, barang yang kamu cari tidak ada di dalam semua kategori.
            </bl-text-av>
          </bl-callout-mv>
        </div>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import { FilterCategory } from './filters';
import IcoCategoryMinor from '@bukalapak/bazaar-visual-next/src/components/IcoCategoryMinor';
import IcoCheckCircle from '@bukalapak/bazaar-visual-next/src/components/IcoCheckCircle';

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(
    m => m.default || m,
  );

export default {
  name: 'ProductCategories',

  components: {
    IcoCheckCircle,
    IcoCategoryMinor,
    FilterCategory,
    BlSheetMv,
  },

  props: {
    currentCategoryId: {
      type: String,
      required: true,
    },
    currentCategoryName: {
      type: String,
      required: true,
    },
    facets: {
      type: Array,
      required: true,
    },
    isOverlayActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: false,
      isRendered: false,
      showCategories: false,
    };
  },

  computed: {
    categorySlugPrefix() {
      if (global._INITIAL_PRODUCT_CAMPAIGN_DATA_) {
        const prefix = global.location.pathname
          .split('/')
          .splice(0, 3)
          .join('/');
        return `${prefix}/`;
      }
      return '/c/';
    },
  },

  watch: {
    showCategories(val) {
      if (val) {
        this.isRendered = true;
      }
      this.$emit('show', this.showCategories);
    },
  },

  mounted() {
    this.isMounted = true;

    if (this.isOverlayActive) {
      this.showCategories = true;
    }
  },

  methods: {
    selectCategory(values) {
      this.showCategories = false;
      this.$emit('selected', values);
    },
  },
};
</script>
