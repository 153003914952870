import api from '@/shared/api';

const state = {
  categories: [],
};

const getters = {
  categories: state => state.categories,
};

const mutations = {
  ADD_CATEGORIES: (state, value) => {
    state.categories = value;
  },
};

const actions = {
  async retrieveCategories({ commit }, { permalink }) {
    try {
      const { data = [] } = await api.getCategories(permalink);
      commit('ADD_CATEGORIES', data);
    } catch (error) {
      // @TODO send to Sentry
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
