import api from '@/shared/api';

const validateMandatoryParams = ({ experimentName, userId }) => {
  if (!experimentName) throw new Error('Experiment name is required');
  if (!userId) throw new Error('Experiment require userId');

  return true;
};

export async function chooseAlternative({ experimentName, override, userId = window.USER.id } = {}) {
  try {
    validateMandatoryParams({ experimentName, userId });

    const params = {
      participant: userId.toString(),
    };

    if (override) {
      params.override = override;
    }

    const { data } = await api.chooseAlternative({ experimentName, params });
    return data.alternative;
  } catch (e) {
    throw new Error('There is an error occurred on fetching experiment alternative');
  }
}

export const score = ({ experimentName, metricName, value, userId = window.USER.id } = {}) => {
  validateMandatoryParams({ experimentName, userId });
  if (!metricName) throw new Error('Metric name is required');
  if (!value) throw new Error('Metric value is required');

  const params = {
    metric: metricName,
    value,
  };

  return api.scoreMetric({ experimentName, params, participantId: userId });
};
