<template>
  <bl-card-mv class="bl-product-highlight" :border-radius="4" is-outlined backgroundColor="transparent">
    <div class="bl-product-highlight__info_container p-12" style="border-bottom: 1px solid #ced2d9;">
      <bl-text-av variation="caption-10" weight="bold" class="bl-product-highlight__title mb-4">
        {{ keyword }} terbanyak dilihat
      </bl-text-av>
      <bl-text-av
        data-testid="product-highlighted"
        tag="span"
        variation="caption-12"
        color="subdued"
        class="bl-product-highlight__product_name"
      >
        {{ highlightedProduct.name }}
      </bl-text-av>
      <bl-flex-container-mv align-items="center" class="mt-8">
        <bl-flex-item-av class="mr-8">
          <bl-progress-circular-av :value="percentageRate" stroke-color="#ffc53e" :size="32" :width="2">
            <bl-text-av
              tag="span"
              variation="body-14"
              color="subdued"
              weight="bold"
              class="bl-product-highlight__product_rate"
            >
              {{ highlightedProduct.rating.average_rate }}
            </bl-text-av>
          </bl-progress-circular-av>
        </bl-flex-item-av>
        <bl-flex-item-av>
          <bl-rating-mv
            :value="highlightedProduct.rating.average_rate"
            :user-count="`${highlightedProduct.rating.user_count} Ulasan`"
            size="medium"
            data-testid="rating"
          />
        </bl-flex-item-av>
      </bl-flex-container-mv>
    </div>
    <bl-link-av
      class="bl-product-highlight__product_link pv-4"
      :href="highlightedProduct.url"
      rel="nofollow"
      style="display: block; text-align: center;"
    >
      <bl-text-av tag="span" variation="caption-12" style="color: #2b4ac7;">Lihat Barang</bl-text-av>
    </bl-link-av>
  </bl-card-mv>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import BlProgressCircularAv from '../../../product-explorer-desktop/components/BlProgressCircularAv';
import { percentageRate } from '@/shared/helpers/number-helper';

export default {
  name: 'ProductHighlight',
  components: {
    BlProgressCircularAv,
  },
  props: {
    highlightedProduct: {
      type: Object,
      required: true,
    },
    currentKeyword: {
      type: String,
      required: true,
    },
  },
  computed: {
    percentageRate() {
      return percentageRate(this.highlightedProduct.rating.average_rate);
    },
    keyword() {
      return _capitalize(this.currentKeyword);
    },
  },
};
</script>
