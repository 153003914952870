import api from '@/shared/api';

const initialState = {
  collections: {
    catalogs: [],
  },
  pagination: {
    offset: 0,
    limit: 10,
  },
  total: 0,
  query: '',
  categoryId: '',
  // abTest: 'control',
};

const getters = {
  catalogs(state) {
    return state.collections.catalogs;
  },
  hasCatalogs(state) {
    return state.total > 0;
  },
};

const mutations = {
  UPDATE_VARIABLE(state, { label, value }) {
    state[label] = value;
  },
  UPDATE_COLLECTION(state, { collectionName, newCollection }) {
    state.collections[collectionName] = newCollection || [];
  },
};

const actions = {
  setQuery({ commit }, { query }) {
    commit('UPDATE_VARIABLE', { label: 'query', value: query });
  },
  setCategory({ commit }, { categoryId }) {
    commit('UPDATE_VARIABLE', { label: 'categoryId', value: categoryId || '' });
  },
  setPagination({ commit }, { offset = 0, limit = 10 }) {
    commit('UPDATE_VARIABLE', { label: 'pagination', value: { offset, limit } });
  },
  async retrieve({ commit, dispatch, rootState }, { query, offset, limit, categoryId } = {}) {
    if (query) {
      dispatch('setQuery', { query });
    }
    if (categoryId) {
      dispatch('setCategory', { categoryId });
    }
    if (offset || limit) {
      dispatch('setPagination', { offset, limit });
    }

    try {
      const getCatalogResponse =
        rootState.mockData && rootState.mockData.allowMocking
          ? await dispatch('mockData/mockProductCatalog', null, { root: true })
          : null;
      const { data, meta } = getCatalogResponse
        ? getCatalogResponse
        : await api.getCatalogs(query, offset, limit, categoryId);
      commit('UPDATE_VARIABLE', { label: 'total', value: meta.total });
      commit('UPDATE_COLLECTION', {
        collectionName: 'catalogs',
        newCollection: data,
      });
    } catch (error) {
      commit('UPDATE_COLLECTION', {
        collectionName: 'catalogs',
        newCollection: [],
      });
    }
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters,
  mutations,
  actions,
};
