const { PRODUCT_TYPES } = require('shared/types/productSearch');

function promotedSection(products, pagination, id) {
  const page = pagination.currentPage || 1;
  const productIndex = products.findIndex(product => product.id === id);
  const position = products.length / 2 > productIndex ? 'top' : 'bottom';

  return `desktop page search ${page} ${position}`;
}

function isOnDiscount(start, end, now = new Date()) {
  if (!start || !end) {
    return false;
  }

  const today = new Date(now).getTime();
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();

  return today >= startDate && today <= endDate;
}

function numberToFeedbackFormat(number) {
  return number >= 1000000
    ? `${Math.floor(number / 1000000)}jt`
    : number >= 1000
    ? `${Math.floor(number / 1000)}rb`
    : `${number}`;
}

function extractImages(product, useWebp = true, imageSize = 330) {
  if (!product.images) return [];

  return (
    product.images &&
    product.images.large_urls.map(image => {
      const rgx = /\/(large)\//;
      const matches = rgx.exec(image);
      let result = image;

      if (matches) {
        result = image.replace(matches[1], `s-${imageSize}-${imageSize}`);

        if (useWebp) {
          result = `${result}.webp`;
        }
      }

      return {
        id: product.id,
        alt: product.name,
        url: result,
      };
    })
  );
}

/**
 * Highlighted product is first organic product that has rating more than 4
 * @param {Array} products
 * @returns {Object} product
 */
function getHighlightedProduct(products) {
  const isOrganicBusiness = product =>
    product.position_type === PRODUCT_TYPES.ORGANIC || product.position_type === PRODUCT_TYPES.BUSINESS;
  const hasRatingMoreThan4 = product => product.rating && product.rating.average_rate >= 4;

  return products.find(product => isOrganicBusiness(product) && hasRatingMoreThan4(product));
}

module.exports = {
  promotedSection,
  isOnDiscount,
  numberToFeedbackFormat,
  extractImages,
  getHighlightedProduct,
};
