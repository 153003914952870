<template>
  <div
    v-if="isShowStoreAds && isNotEmpty"
    class="sa-wrapper"
  >
    <div
      class="sa-slider"
      @scroll="updateScroll"
    >
      <div class="mv-12 mh-16 sa-inline-block sa-wrapper-store">
        <a
          :href="storeUrl"
          @click="trackVisitStoreAds(firstProduct.store.id)"
        >
          <div class="mb-4">
            <bl-text-av
              variation="caption-12"
              tag="span"
              class="is-ellipsis"
            >
              {{ storeName }}
            </bl-text-av>
            <bl-emblem-av
              class="badge"
              v-if="isSuperSeller"
              variation="superseller_icon"
            />
          </div>
          <bl-text-av
            variation="caption-10"
            color="subdued"
            class="mb-4"
            v-if="storeLocation"
          >
            {{ storeLocation }}
          </bl-text-av>
          <bl-text-av
            variation="caption-10"
            color="subdued"
            class="mb-8"
          >
          {{ `${feedback.total} feedback (${feedback.percentage}%)` }}
          </bl-text-av>
          <bl-flex-container-mv class="mb-24">
            <bl-flex-item-av>
              <ico-voucher
                color="#46C490"
                class="mr-4 sa-icon va-middle" />
            </bl-flex-item-av>
            <bl-flex-item-av>
              <bl-text-av
                variation="caption-12"
                style="color: #389C73;"
                weight="bold"
                tag="span"
              >
                {{ voucherCode }}
              </bl-text-av>
            </bl-flex-item-av>
          </bl-flex-container-mv>
          <bl-flex-container-mv>
            <bl-flex-item-av>
              <bl-text-av
                variation="body-14"
                style="color: #4589DC;"
                weight="bold"
                class="mr-4 va-middle"
                tag="span"
              >
                Kunjungi Lapak
              </bl-text-av>
            </bl-flex-item-av>
            <bl-flex-item-av>
              <ico-chevron-right-minor
                color="#4589DC"
                class="va-middle"
              />
            </bl-flex-item-av>
          </bl-flex-container-mv>
        </a>
      </div>
      <div
        v-for="(product, index) in products"
        :key="`${product.store.id}-${index}`"
        class="pv-12 ph-4 sa-inline-block"
        :class="{ 'ml-50': firstProduct.id == product.id }"
      >
        <a
          @click="trackClickProductStoreAds(product.store.id, product.id, index)"
          :href="product.url"
        >
          <bl-card-mv class="sa-card">
            <div class="p-12 sa-flex">
              <div class="pr-12 sa-float-left">
                <img
                  v-if="!!product.images && product.images.small_urls.length"
                  :src="product.images.small_urls[0]"
                  :alt="product.name"
                  class="sa-thumb bl-card">
              </div>
              <div class="sa-float-left">
                <bl-text-av
                  class="mb-8"
                  variation="caption-12"
                  :ellipsis="2"
                  style="white-space: normal;"
                >
                  {{ product.name }}
                </bl-text-av>
                <div class="mb-8">
                  <bl-text-av
                    variation="body-16"
                    weight="bold"
                    tag="span"
                    class="mr-4"
                  >
                    {{ hasDiscount(product.deal)
                      ? discountPrice(product.deal.discount_price)
                      : productPrice(product.price) }}
                  </bl-text-av>
                  <bl-text-av
                    variation="caption-12"
                    color="subdued"
                    tag="span"
                    class="mr-4"
                    v-if="hasDiscount(product.deal)"
                    style="text-decoration: line-through;"
                  >
                    {{ discount(product.deal).price }}
                  </bl-text-av>
                  <bl-text-av
                    variation="caption-12"
                    color="error"
                    tag="span"
                    v-if="hasDiscount(product.deal)"
                  >
                    {{ `-${discount(product.deal).percent}%` }}
                  </bl-text-av>
                </div>
                <div>
                  <BlRatingMv
                    :value="product.rating.average_rate"
                    class="sa-float-left"
                  />
                  <bl-text-av
                    variation="caption-10"
                    color="subdued"
                    v-if="product.stats.sold_count !== '0'"
                    tag="span"
                    class="ml-4 sa-float-left"
                  >
                    {{ `${soldCount(product.stats.sold_count)} terjual` }}
                  </bl-text-av>
                </div>
              </div>
            </div>
          </bl-card-mv>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/shared/api';
import numberHelper from '@bukalapak/toolbox-helper/number-helper';
import { isOnDiscount } from '@/shared/utilities/productsUtility';
import IcoVoucher from "@bukalapak/bazaar-visual-next/src/components/IcoVoucher";
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";

const { simpleNumber, simpleMoney } = numberHelper;

export default {
  name: 'StoreAds',
  components: {IcoChevronRightMinor, IcoVoucher},
  filters: {
    simpleMoney(amount) {
      return simpleMoney(amount);
    },
    simpleNumber(amount) {
      return simpleNumber(amount);
    },
  },
  props: {
    productSearch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowStoreAds: false,
      isScrollLeftExist: false,
      isSuperSellerToggleEnabled: false,
      storeAd: [],
      products: [],
    }
  },
  computed: {
    isNotEmpty() {
      return this.storeAd.length > 0;
    },
    storeAdId() {
      return this.storeAd[0].id;
    },
    firstProduct() {
      return this.storeAd[0].products[0];
    },
    isSuperSeller() {
      const premiumLevel = this.products.map(e => e.store).map(e => e.premium_level);
      return !premiumLevel.includes('none');
    },
    voucherCode() {
      return this.storeAd[0].voucher.code;
    },
    storeLocation() {
      return this.firstProduct.store && this.firstProduct.store.address && this.firstProduct.store.address.city
    },
    storeName() {
      return this.firstProduct.store.name;
    },
    storeUrl() {
      return this.firstProduct.store.url;
    },
    feedback() {
      const reviews = this.firstProduct.store.reviews;
      const totalFeedback = !!reviews.positive ? reviews.positive + reviews.negative : 0;
      return {
        total: simpleNumber(totalFeedback),
        percentage: !!reviews.positive ? ((reviews.positive / totalFeedback) * 100).toFixed() : 0,
      }
    },

  },
  created() {
    this.fetchStoreAdsToggle();
  },
  methods: {
    async fetchStoreAdsToggle() {
      try {
        const [isFeatureActive, isSuperSellerValidationActive] = await Promise.all([
          this.getToggleStatus('product_explorer/iklan_lapak/buyer_side'),
          this.getToggleStatus('product_explorer/iklan_lapak/super_seller'),
        ]);

        this.isSuperSellerToggleEnabled = isSuperSellerValidationActive;

        if (isFeatureActive) {
          this.isShowStoreAds = true;
          this.retrieveStoreAds();
        }
      } catch (e) {
        this.isShowStoreAds = false;
      }
    },
    async getToggleStatus(featureName) {
      try {
        const { data } = await api.getToggleStatus(featureName);
        return !!data.status;
      } catch (e) {
        return false;
      }
    },
    async retrieveStoreAds() {
      const currentKeyword = this.productSearch.params['search[keywords]'];
      if (currentKeyword) {
        try {
          const { data } = await api.getStoreAdByKeyword({ keyword: currentKeyword });
          this.storeAd = data;
          this.products = this.storeAd.map(e => e.products)[0]

          if (this.isSuperSellerToggleEnabled) {
            if (!this.isSuperSeller) {
              this.isShowStoreAds = false;
            }
          }

          if (!!this.storeAdId) {
            api.chargeStoreAd(this.storeAdId);
          }
        } catch (error) {
          // do nothing
        }
      }
    },
    productPrice(price) {
      return simpleMoney(price);
    },
    discountPrice(price) {
      return simpleMoney(price);
    },
    discount(deal) {
      if (this.hasDiscount(deal)) {
        return {
          percent: simpleMoney(deal.percentage),
          price: simpleMoney(deal.original_price),
        };
      }
      return {};
    },
    soldCount(sold) {
      return simpleNumber(sold);
    },

    hasDiscount(deal) {
      const hasDeal = !!deal;
      if (hasDeal) {
        if (Object.keys(deal).length > 0) {
          return deal.percentage && isOnDiscount(deal.applied_date, deal.expired_date);
        }
      }
      return false;
    },
    updateScroll() {
      const slide = document.querySelector('.sa-slider');
      const storeInfo = document.querySelector('.sa-wrapper-store');

      if (slide.scrollLeft > 0) {
        storeInfo.style.opacity = 1 - slide.scrollLeft / 250;
      } else {
        storeInfo.style.opacity = 1;
      }
    },
    trackVisitStoreAds(sellerId) {
      const trackerData = {
        evn: 'visit_store_ads',
        section: 'iklan lapak visit store on mweb page search',
        seller_id: sellerId.toString(36),
        campaign_id: this.storeAdId.toString(36),
        ui: (global.USER && global.USER.id36) || '',
      };

      return window.Tracker.event(trackerData);
    },
    trackClickProductStoreAds(sellerId, productId, index) {
      const trackerData = {
        evn: 'click_product_store_ads',
        section: `iklan lapak click index product ${index+1} on mweb page search`,
        seller_id: sellerId.toString(36),
        campaign_id: this.storeAdId.toString(36),
        product_id: productId.toString(36),
        ui: (global.USER && global.USER.id36) || '',
      };

      return window.Tracker.event(trackerData);
    },
  }
};
</script>

<style type="text/scss" scoped>
.sa-wrapper {
  display: flex;
  background-color: #ffffff;
  min-height: 144px;
  .sa-slider {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .sa-wrapper-store {
    position: absolute;
    opacity: 1;
    width: 50%;
    left: 0;
  }
  .badge {
    width: 20px !important;
    height: 15px !important;
    background-size: cover;
    vertical-align: middle;
  }
}
.sa-flex {
  display: flex;
}
.sa-float-left {
  float: left;
}
.sa-inline-block {
  display: inline-block;
}
.sa-card {
  width: 318px;
  height: 120px;
}
.sa-thumb {
  width: 96px;
  height: 96px;
  max-width: none;
}
.sa-icon {
  width: 16px !important;
  height: 14px !important;
}
.va-middle {
  vertical-align: middle;
}
.ml-50 {
  margin-left: 50%;
}
.is-ellipsis {
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
}
</style>
