import api from '@/shared/api';
import { colorVariantMapping } from '@/shared/types/colorVariantMapping';

const isActive = option => option.state && option.state === 'active';
const pickValue = option => option.name;

const initialState = {
  variantOptions: [],
};

const variantGetters = {
  variantOptions: state => state.variantOptions,
  isVariantOptionExist: state => state.variantOptions.length > 0,
};

const mutations = {
  UPDATE_VARIANT_OPTIONS(state, variantOptions) {
    // turn the values from object into plain array (as attribute filter does)
    const uniformedVariantOptions = variantOptions;
    uniformedVariantOptions.forEach((option, index) => {
      const flattenOptions = option.values.filter(isActive).map(pickValue);
      const isColorVariant = flattenOptions.some(o =>
        colorVariantMapping.some(c => c.label.toLocaleLowerCase() === o.toLocaleLowerCase()),
      );
      let options = flattenOptions;

      if (isColorVariant) {
        options = flattenOptions.filter(o =>
          colorVariantMapping.some(c => c.label.toLocaleLowerCase() === o.toLocaleLowerCase()),
        );
      }
      uniformedVariantOptions[index].isColorVariant = isColorVariant;
      uniformedVariantOptions[index].options = options;
      uniformedVariantOptions[index].identifier = option.display_name;
    });

    state.variantOptions = uniformedVariantOptions.sort(a => {
      return a.isColorVariant ? 1 : -1;
    });
  },
};

const actions = {
  async retrieveProductVariants({ commit }, { categoryId }) {
    try {
      const { data = [] } = await api.getVariantOptions(categoryId);

      commit('UPDATE_VARIANT_OPTIONS', data);
    } catch (error) {
      // @TODO send to Sentry
    }
  },
  resetVariantOptions({ commit }) {
    commit('UPDATE_VARIANT_OPTIONS', []);
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: variantGetters,
  mutations,
  actions,
};
