import { isOnDiscount } from './productsUtility';
import isFinite from 'lodash/isFinite';

const getUnixTime = () => Math.round(new Date().getTime() / 1000);

const convertToString = (val, base = 10) => {
  if (isFinite(val)) {
    return val.toString(base);
  }
  return '';
};

const send = event => {
  const Tracker = window.Tracker;
  if (Tracker) {
    Tracker.event(event);
  }
};

function hasDiscount(product) {
  if (product.deal && Object.keys(product.deal).length > 0) {
    const { deal } = product;

    return deal.percentage && isOnDiscount(deal.applied_date, deal.expired_date);
  }

  return false;
}

const trackAddToCart = async (payload, product) => {
  const categoryId = product.category.id;
  const sellerId = product.store.id;
  const reviewPositive = product.store.reviews.positive;
  const reviewNegative = product.store.reviews.negative || 0;
  const sellerFeedbacks = reviewPositive ? reviewPositive + reviewNegative : 0;

  await send({
    evn: 'add_to_cart',
    ui: (global.USER && global.USER.id36) || '',
    t: getUnixTime(),
    referrer_url: payload.referrerUrl || (document && document.referrer),
    url: payload.url || location.href,

    // integer
    cart_id: convertToString(payload.cartId),
    // integer
    cart_item_id: convertToString(payload.cartItemId),
    platform: payload.platform || 'desktop_web',
    referrer_event: payload.referrerEvent || '',
    quantity: payload.quantity,

    category_id: convertToString(categoryId, 36),
    is_wholesales: product.wholesales.length > 0,
    is_discount: hasDiscount(product),
    price: convertToString(product.price),
    product_id: product.id,
    product_sku_id: convertToString(product.sku_id, 36),
    product_rating: (product.rating && parseFloat(product.rating.average_rate)) || 0,
    reviews: (product.rating && product.rating.user_count) || 0,
    // integer
    seller_id: sellerId ? convertToString(sellerId) : '',
    seller_feedbacks: sellerFeedbacks,
  });
};

const trackProductsImpression = async payload => {
  await send({
    page: payload.pagination.currentPage,
    items: payload.product.id,
    position_group: 'main_search',
    platform: 'desktop',
    pos: `${payload.product.position_type}/${payload.product.position_type_order}`,
    global_pos: payload.product.position_order.toString(),
    keyword: payload.params_obj.keyword,
    search_event_id: payload.product.search_query_id,
    evn: 'search_impression',
    ui: typeof window?.USER !== 'undefined' ? window.USER.id36 : '',
  });
};

const trackProductCardAction = async payload => {
  let context = {};
  if (typeof window !== 'undefined') {
    context = {
      referrer_url: window.location.href,
      url: window.location.href,
      ui: window.USER.id36,
      bl_username: window.USER.username,
    };
  }

  await send({
    evn: 'product_card_action',
    t: getUnixTime(),
    action: payload.action,
    ...context,
  });
};

export default { send, trackAddToCart, trackProductsImpression, trackProductCardAction };
