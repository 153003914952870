import take from 'lodash/take';

import api from '@/shared/api';
import { RECOMMENDATION_PRODUCTS_FETCHING } from '@/shared/types/wait';

const initialState = {
  products: [],
};

const getters = {
  products(state) {
    return state.products;
  },
};

const mutations = {
  UPDATE_PRODUCTS(state, { label, value }) {
    state[label] = value;
  },
};

const actions = {
  async retrieveProducts({ dispatch, commit }) {
    dispatch('wait/start', RECOMMENDATION_PRODUCTS_FETCHING, { root: true });

    const { data } = await api.getRecommendationProducts();

    dispatch('wait/end', RECOMMENDATION_PRODUCTS_FETCHING, { root: true });

    if (data && data.products) {
      const products = data.products.map(p => p.product);

      commit('UPDATE_PRODUCTS', { label: 'products', value: take(products, 15) });
    }
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  getters,
  actions,
};
