<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv
        hasBorder="top"
        class="d-filter-item-active te-rating-menu"
        style="padding-left: 0;"
        @click="isShow = true"
        text="Rating"
      >
        <bl-badge-av count="1" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="isPromoPage ? null : $UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" :isRating="true" @remove="removeChosen" />
    </div>
    <bl-cell-mv
      v-else
      class="te-rating-menu"
      hasBorder="top"
      text="Rating"
      style="padding-left: 0;"
      @click="isShow = true"
    >
      <ico-chevron-right-minor slot="iconRight" :color="isPromoPage ? null : $UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv
      :active.sync="isShow"
      title="Rating"
      text-action="Reset"
      @clickAction="reset"
      @close="close"
    >
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding-left:16px;">
          <bl-cell-mv
            v-for="(option, idx) in options"
            :key="idx"
            :hasBorder="idx > 0 ? 'top' : undefined"
            style="padding-left: 0;"
            @click="itemClicked(option.value)"
          >
            <div slot="text" v-if="option.value > 0">
              <bl-flex-container-mv align-items="center">
                <bl-flex-item-av style="margin-right:4px;">
                  <bl-rating-mv :value="option.value" size="medium" />
                </bl-flex-item-av>
                <bl-flex-item-av>
                  <bl-text-av variation="body-14">dan lebih</bl-text-av>
                </bl-flex-item-av>
              </bl-flex-container-mv>
            </div>
            <div slot="text" v-else>{{ option.label }}</div>
            <div slot="iconRight">
              <bl-radio-av
                v-model="value"
                :class="{ 'te-filter-rating-5': option.value === 5, 'te-filter-rating-4': option.value === 4 }"
                :native-value="option.value"
              />
            </div>
          </bl-cell-mv>
        </div>
      </div>
      <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
        <bl-button-av
          class="te-rating-save"
          variation="primary"
          fullwidth
          style="border-radius: 4px;"
          @click="save"
        >
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import FilterChosen from './FilterChosen.vue';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(m => m.default || m);

export default {
  name: 'FilterRating',
  components: {
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  props: {
    paramName: {
      type: String,
      required: true,
    },
    currentParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    hasValue: {
      type: Boolean,
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
    isPromoPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      value: null,
      savedValue: null,
      options: [
        { label: 'Lihat Semua', value: 0 },
        { label: 'Lima', value: 5 },
        { label: 'Empat', value: 4 },
        { label: 'Tiga', value: 3 },
        { label: 'Dua', value: 2 },
        { label: 'Satu', value: 1 },
      ],
      chosenList: [],
    }
  },
  computed: {
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.value = null;
        // this.updateConditionFilter();
        this.updateChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.value = this.getValue();
      this.savedValue = this.value;
      this.updateChosen();
    },
    getValue() {
      return this.hasValue ? parseInt(this.currentParamValue, 10) : null;
    },
    updateConditionFilter() {
      this.$emit('updated', this.paramName, this.value);
    },
    updateChosen() {
      if (isNaN(parseInt(this.value, 10)) || this.value === 0) {
        this.chosenList = [];
      } else {
        this.chosenList = [this.value];
      }
    },
    reset() {
      this.value = null;
    },
    save() {
      this.updateConditionFilter();
      this.updateChosen();
      this.savedValue = this.value;
      this.isShow = false;
    },
    removeChosen(idx) {
      this.value = null;
      this.chosenList.splice(idx, 1);
      this.updateConditionFilter();
    },
    close() {
      this.value = this.savedValue;
    },
    itemClicked(val) {
      this.value = val;
    },
  },
};
</script>
