const RED = '#ec0c48';
const PINK = '#eb2f8d';
const YELLOW = '#fadb0f';
const GREEN = '#51b85b';
const BLACK = '#000000';
const WHITE = '#f8f9fa';
const BLUE = '#348ad6';

export const colorVariantMapping = [
  { label: 'merah', code: RED },
  { label: 'red', code: RED },
  { label: 'coklat', code: '#4d2309' },
  { label: 'biru', code: BLUE },
  { label: 'blue', code: BLUE },
  { label: 'putih', code: WHITE },
  { label: 'white', code: WHITE },
  { label: 'hitam', code: BLACK },
  { label: 'black', code: BLACK },
  { label: 'hijau', code: GREEN },
  { label: 'green', code: GREEN },
  { label: 'yellow', code: YELLOW },
  { label: 'pink', code: PINK },
  { label: 'merah muda', code: PINK },
];
