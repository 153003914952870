<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv text="Jasa Pengiriman" ref="courier-sheet-trigger" has-border="top" class="d-filter-item-active" style="padding-left: 0;" @click="isShow = true">
        <bl-badge-av :count="chosenList.length" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv v-else ref="courier-sheet-trigger" has-border="top" text="Jasa Pengiriman" style="padding-left: 0;" @click="isShow = true">
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv :active.sync="isShow" title="Jasa Pengiriman" text-action="Reset" @clickAction="reset" @close="close">
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card">
          <div style="margin-bottom:6px; padding:16px;">
            <bl-text-av variation="body-14" style="margin-bottom:6px;">
              Pilih Negara
            </bl-text-av>
            <bl-dropdown-av
              placeholder="Pilih Negara"
              :value="country"
              style="min-width:216px"
              @input="countrySelected"
            >
              <option v-for="(option, idx) in options" :key="idx" :value="option.label">
                {{ option.label }}
              </option>
            </bl-dropdown-av>
          </div>
          <div v-if="couriersArr.length" style="padding-left: 16px; margin: -10px 0 6px;">
            <bl-cell-mv
              v-for="(courier, idx) in couriersArr"
              :key="idx"
              :has-border="idx > 0 ? 'top' : undefined"
              style="padding-left: 0;"
              @click="itemClicked($event, `courier-option-${idx}`)"
            >
              <div slot="text">
                <bl-flex-container-mv align-items="center">
                  <bl-flex-item-av style="margin-right:6px;">
                    {{ courier.label }}
                  </bl-flex-item-av>
                  <bl-flex-item-av v-if="Object.keys(courier.info).length" style="padding-top:2px;">
                    <bl-tooltip-mv placement="bottom-left" :text="courier.info.desc">
                      <ico-help-minor color="#ced2d9" />
                    </bl-tooltip-mv>
                  </bl-flex-item-av>
                </bl-flex-container-mv>
              </div>
              <div slot="iconRight">
                <bl-checkbox-av
                  :ref="`courier-option-${idx}`"
                  v-model="checkboxesArr"
                  :native-value="courier.value"
                  @input="input(courier.value)"
                />
              </div>
            </bl-cell-mv>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding: 16px 24px; border-top: 1px solid #eee;">
        <bl-button-av variation="primary" fullwidth style="border-radius: 4px;" @click="save">
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import { mapGetters } from 'external/Vuex';
import FilterChosen from './FilterChosen.vue';
import { COD_VALUE_NAME } from '../../constants';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";
import IcoHelpMinor from "@bukalapak/bazaar-visual-next/src/components/IcoHelpMinor";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(
    m => m.default || m,
  );

export default {
  name: 'FilterCourier',
  components: {
    IcoHelpMinor,
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  props: {
    paramName: {
      type: [String, Array, Object],
      required: true,
    },
    currentParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
    setCodParams: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      checkboxesArr: [],
      country: null,
      chosenList: [],
      couriersArr: [],
      savedVal: {},
    };
  },
  computed: {
    ...mapGetters(['couriers', 'filterOption']),
    courierList() {
      let arr = [
        {
          label: 'Semua Kurir',
          info: {},
          value: '',
        },
      ];

      this.couriers.forEach(courier => {
        if (courier.includes('Alfatrex Next Day')) {
          if (arr.find(c => c.label === 'Alfatrex Next Day') === undefined) {
            arr.push({
              label: 'Alfatrex Next Day',
              info: {
                title: 'Definisi Alfatrex Next Day',
                desc: 'Kamu bisa ambil langsung pesananmu di Alfamart terdekat atau diantar langsung ke rumahmu',
              },
              value: 'Alfatrex Next Day',
            });
          }
        } else if (courier.includes('Alfatrex REG')) {
          if (arr.find(c => c.label === 'Alfatrex REG') === undefined) {
            arr.push({
              label: 'Alfatrex REG',
              info: {
                title: 'Definisi Alfatrex REG',
                desc: 'Kamu bisa ambil langsung pesananmu di Alfamart terdekat atau diantar langsung ke rumahmu',
              },
              value: 'Alfatrex REG',
            });
          }
        } else if (courier === 'Ambil Sendiri') {
          arr.push({
            label: courier,
            info: {
              title: `Definisi ${courier}`,
              desc:
                'Ambil Sendiri adalah fitur pengiriman untuk pembeli mengambil barang pesanannya langsung ke lokasi pelapak',
            },
            value: courier,
          });
        } else if (courier === 'Rocket Delivery') {
          arr.push({
            label: courier,
            info: {
              title: `Definisi ${courier}`,
              desc:
                'Jasa pengiriman dengan waktu untuk pelapak proses dan kirim barang maksimal 1 jam sejak pesanan baru masuk',
            },
            value: courier,
          });
        } else {
          arr.push({
            label: courier,
            info: {},
            value: courier,
          });
        }
      });
      return arr;
    },
    options() {
      let arr = [
        {
          label: 'Indonesia',
          couriers: this.courierList,
        },
      ];
      this.abroadList.forEach(country => {
        arr.push({
          label: country,
          couriers: [
            {
              label: 'Janio',
              info: {},
              value: `Janio - ${country}`,
            },
          ],
        });
      });
      return arr;
    },
    abroadList() {
      let arr = [];
      this.filterOption('countryDeliveries').forEach(country => {
        arr.push(country.name);
      });
      return arr;
    },
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.reset();
        this.updateChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
    setCodParams(value) {
      this.updateSearchParamCod(value)
    }
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      if (Object.keys(this.savedVal).length) {
        this.countrySelected(this.savedVal.country);
        this.checkboxesArr = this.savedVal.couriers;
      } else {
        const entries = this.getValue();
        let selected = 'Indonesia';
        for (let country of this.abroadList) {
          if (entries.some(value => value.includes(country))) {
            selected = country;
            break;
          }
        }
        this.savedVal.country = selected;
        this.savedVal.couriers = entries;
        this.countrySelected(selected);
      }
      this.updateChosen();
    },
    getValue() {
      return String(this.currentParamValue) !== '' ? this.currentParamValue : [];
    },
    updateChosen() {
      if (String(this.checkboxesArr)) {
        this.chosenList = this.checkboxesArr.filter(item => item);
      } else {
        this.chosenList = [];
      }
    },
    removeChosen(idx) {
      const courierValue = this.chosenList[idx]
      const courierIndex = this.chosenList.findIndex(courier => courier === courierValue)
      if (courierIndex >= 0) {
        this.chosenList.splice(courierIndex, 1);
      }
      if (this.chosenList.length === 0) {
        this.savedVal = {};
        this.country = 'Indonesia';
      } else {
        this.savedVal.couriers = this.chosenList;
      }
      this.countrySelected(this.country);
      this.save();
    },
    reset() {
      this.checkboxesArr = [];
    },
    input(val) {
      if (val === '') {
        this.checkboxesArr = [''];
      } else {
        this.checkboxesArr = this.checkboxesArr.filter(item => item);
      }
    },
    close() {
      this.initializeValue();
    },
    save() {
      this.savedVal.country = this.country;
      this.savedVal.couriers = this.checkboxesArr;
      this.updateChosen();
      this.$emit(
        'updated',
        this.paramName,
        this.checkboxesArr.filter(item => item),
      );
      this.isShow = false;
    },
    countrySelected(label) {
      this.country = label;

      const obj = this.options.find(c => c.label === label);
      this.couriersArr = obj.couriers;

      this.checkboxesArr = this.savedVal.country && this.savedVal.country === label ? this.savedVal.couriers : [];
    },
    itemClicked(event, element) {
      if (event.target && event.target.tagName.toLowerCase() === 'div') {
        this.$refs[element][0].$el.click();
        return false;
      }
    },
    updateSearchParamCod(value) {
      const codIndex = this.couriersArr.findIndex(courier => courier.value === COD_VALUE_NAME)
      if (codIndex >= 0) {
        if (value) {
          this.checkboxesArr.push(COD_VALUE_NAME)
        } else {
          const currentIndex = this.checkboxesArr.findIndex(checkbox => checkbox === COD_VALUE_NAME)
          if (currentIndex >= 0) {
            this.checkboxesArr.splice(currentIndex, 1)
          }
        }
        this.save()
      }
    },
  },
};
</script>
