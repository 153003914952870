import BlBadgeAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlBadgeAv';
import BlButtonAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlButtonAv';
import BlCheckboxAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlCheckboxAv';
import BlDropdownAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlDropdownAv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlFlexItemAv';
import BlRadioAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlRadioAv';
import BlShimmerAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlShimmerAv';
import BlTextAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTextAv';
import BlTextfieldAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTextfieldAv';
import BlLinkAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlLinkAv';

import BlCalloutMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlCalloutMv';
import BlCardMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlCardMv';
import BlCellMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlCellMv';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlFlexContainerMv';
import BlGhostblockPaginationMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlGhostblockPaginationMv';
import BlRatingMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlRatingMv';
import BlSearchSectionMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlSearchSectionMv';
import BlSelectionBlockMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlSelectionBlockMv';
import BlSwitchAv from '@bukalapak/bazaar-mweb-v2/dist/components/BlSwitchAv';
import BlTooltipMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTooltipMv';
import BlTabsMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTabsMv';
import BlTabNavMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTabNavMv';
import BlTabContentMv from '@bukalapak/bazaar-mweb-v2/dist/components/BlTabContentMv';

// desktop
import BlEmblemAv from '@bukalapak/bazaar-dweb/dist/components/BlEmblemAv';

import VueWait from 'vue-wait';

import { enable } from '@/shared/utilities/historyUtility';
import buildStoreObj from '@/shared/store/instances/mobile';
import ProductExplorer from './components/ProductExplorer.vue';

const { UI_DARKER_COLOR } = require('./constants');

import VueObserverTracker from '@bukalapak/tracker-js/src/vue/ObserverTracker';
import { INTERNAL_BL_MERCHANDISE_SELLER_IDS, INTERNAL_BL_ITEMKU_CONFIG } from '@/shared/types/configKeys';

function activateVue(Vue) {
  Vue.use(BlBadgeAv);
  Vue.use(BlButtonAv);
  Vue.use(BlCheckboxAv);
  Vue.use(BlDropdownAv);
  Vue.use(BlFlexItemAv);
  Vue.use(BlRadioAv);
  Vue.use(BlShimmerAv);
  Vue.use(BlTextAv);
  Vue.use(BlTextfieldAv);
  Vue.use(BlLinkAv);

  Vue.use(BlFlexContainerMv);
  Vue.use(BlGhostblockPaginationMv);
  Vue.use(BlRatingMv);
  Vue.use(BlSelectionBlockMv);
  Vue.use(BlCardMv);
  Vue.use(BlCalloutMv);
  Vue.use(BlSearchSectionMv);
  Vue.use(BlTooltipMv);
  Vue.use(BlCellMv);
  Vue.use(BlSwitchAv);
  Vue.use(BlTabsMv);
  Vue.use(BlTabNavMv);
  Vue.use(BlTabContentMv);

  // desktop
  Vue.use(BlEmblemAv);

  Vue.use(VueWait);

  Vue.use(VueObserverTracker);

  // to make it easy and consitent
  Vue.prototype.$PARAM_TRUE_VALUE = '1';
  Vue.prototype.$PARAM_FALSE_VALUE = '0';
  Vue.prototype.$UI_DARKER_COLOR = UI_DARKER_COLOR;
}

export const createApp = ({ Vue, Vuex, props, additionalVuexModules, toggles, configs }, isInServerSide = false) => {
  const { initialUrl } = props;

  activateVue(Vue);

  const store = buildStoreObj(initialUrl, {
    toggles,
    additionalModules: additionalVuexModules,
  });

  Vue.use(Vuex);

  const VuexStore = new Vuex.Store(store);

  if (!isInServerSide) {
    enable(VuexStore, { mobile: true });
  }

  const defaultProps = {
    initialUrl,
    withNavbar: true,
    toggles,
    configs,
  };

  let app = new Vue({
    name: 'ProductExplorer',
    computed: {
      blMerchSellerIds() {
        const config = configs?.find(({ id }) => id === INTERNAL_BL_MERCHANDISE_SELLER_IDS);
        if (config) {
          return config.data['seller-ids']?.map(sellerId => Number(sellerId)) || [];
        }

        return [];
      },
      blItemkuConfig() {
        const config = configs?.find(({ id }) => id === INTERNAL_BL_ITEMKU_CONFIG);
        return config?.data.tags?.find(({ enabled, id }) => enabled && id === 'itemku');
      },
    },
    render: h =>
      h(ProductExplorer, {
        props: {
          ...defaultProps,
          ...props,
        },
      }),
    store: VuexStore,
    wait: new VueWait({
      useVuex: true,
    }),
  });

  return { app, store: VuexStore };
};
