const INTERNAL_LINK_REVAMP_CONFIG = 'webx-category-revamp-enabled';
const INTERNAL_BL_MERCHANDISE_SELLER_IDS = 'merchandise-banner-config';
const INTERNAL_BL_ITEMKU_CONFIG = 'itemku-search-rebranding';
const WHITELISTED_SEO_MULTISTRATEGY_CONFIG = 'DORA_COWEB-6363_SEO_MULTISTRATEGY';
const SEARCH_PRODUCT_TAG_CONFIG = 'search-product-tag-config';
const RELATED_KEYWORDS_ROLLOUT_PREFIX = 'DORA_WEBX-7349_RELATED_KEYWORDS';
const BHLMK_RELATED_KEYWORDS_ROLLOUT_PREFIX = 'DORA_WEBX-7354_BHLMK_RELATED_KEYWORDS';
const PDP_DELIVERY_VOUCHER = 'pdp-delivery-voucher';
const SEARCH_PRODUCT_LABEL_CONFIG = 'search-product-label-config';

module.exports = {
  INTERNAL_LINK_REVAMP_CONFIG,
  INTERNAL_BL_MERCHANDISE_SELLER_IDS,
  INTERNAL_BL_ITEMKU_CONFIG,
  WHITELISTED_SEO_MULTISTRATEGY_CONFIG,
  SEARCH_PRODUCT_TAG_CONFIG,
  RELATED_KEYWORDS_ROLLOUT_PREFIX,
  BHLMK_RELATED_KEYWORDS_ROLLOUT_PREFIX,
  PDP_DELIVERY_VOUCHER,
  SEARCH_PRODUCT_LABEL_CONFIG,
};
