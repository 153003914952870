import qs from 'query-string';

import ProductSort from '@/shared/models/sort';
import ProductSearch from '@/shared/models/search';
import ProductSearchGenerator from '@/shared/models/generators/';
import { removeUnnecessaryParam } from '@/shared/utilities/productSearchUtility';
import { flattenCourierByCategories, flattenDeepArray } from '@/shared/utilities/miscUtility';
import { removeInternationalCouriers } from '@/shared/utilities/courierUtil';

const getters = {
  platform: state => state.platform,
  getSearchParamValue: state => paramLabel => state.productSearch.params[paramLabel],
  products: state => state.collections.products,
  highlightedProduct: (state, getters) => {
    if (state.productSearch.pagination.currentPage !== 1) return null;

    const products = state.collections.products;

    if (products.length > 0 && (getters.isProductSearchPath || getters.bhlm?.page)) {
      const highlightedProduct = products.find(
        product =>
          (product.position_type === 'organic' || product.position_type === 'business') &&
          product.rating &&
          product.rating.average_rate >= 4,
      );

      return highlightedProduct;
    }

    return null;
  },
  priceTableProducts: (state, getters) => {
    const products = state.collections.products;

    if (products.length > 0 && (getters.isProductSearchPath || getters.bhlm?.page)) {
      const priceTableProducts = products
        .filter(product => product.position_type === 'organic' || product.position_type === 'business')
        .map((p, idx) => ({ ...p, position_order: idx }));

      return priceTableProducts.slice(0, 10); // first 10 products
    }

    return [];
  },
  isProductExist: state => !!state.collections.products.length,
  isError: state => state.isError,
  isProductSearchPath: state => state.isProductSearchPath,
  errorMessage: state => state.errorMessage,
  isCategoryPage: state => state.productSearch.params['search[category_id]'] !== '',
  pagination: state => state.productSearch.pagination,
  getCollection: state => collectionLabel => state.collections[collectionLabel],
  productSortOptions: (state, getters) => {
    const keyword = state.productSearch.params['search[keywords]'];
    const deal = state.productSearch.params['search[todays_deal]'];
    return ProductSort.getSortOptions(keyword, deal, getters.isPromoPage, getters.bhlm.page);
  },
  isAnyFilterApplied: state => ProductSearch.isAnyFilterApplied(state.productSearch, ProductSearch.initialParams()),
  productSearchByType: state => type => state.productSearch[type],
  isParamFilled: state => paramLabel =>
    state.productSearch.params[paramLabel] !== ProductSearch.initialParams()[paramLabel],
  productSearch: state => state.productSearch,
  initialProductSearch: () => ({
    params: Object.assign({}, ProductSearch.initialParams()),
    attributes: {},
    variants: {},
  }),
  addresses: state => state.addresses,
  couriers: state => removeInternationalCouriers(flattenCourierByCategories(state.filterOptions.courierByCategories)),
  oneDayCouriers: ({ filterOptions }) =>
    filterOptions.courierByCategories.length > 0
      ? filterOptions.courierByCategories.find(c => c.category === 'oneday').couriers
      : [],
  currentProductSearchParams: state => Object.assign({}, removeUnnecessaryParam(state.productSearch.params)),
  currentProductSearch: state => {
    const result = Object.assign({}, state.productSearch);

    delete result.pagination;
    delete result.urlObj;

    return result;
  },
  currentStoreUsername(state) {
    return state.productSearch.params['search[store_username]'];
  },
  currentKeyword: state => {
    return state.productSearch.params['search[keywords]'];
  },
  currentCategoryId(state) {
    const id = state.productSearch.params['search[category_id]'];
    const slug = state.productSearch.params['search[category_slug]'];

    if (!id && slug) {
      const categories = flattenDeepArray(state.collections['facets']);
      const category = categories.find(cat => slug === `/c/${cat.permalink}`);

      if (category) return String(category.id);
    }

    return id.toString();
  },
  currentCategoryName: (state, getters) => {
    const id = getters.currentCategoryId;
    if (id) {
      const categories = flattenDeepArray(state.collections['facets']);
      const category = categories.find(cat => String(cat.id) === String(id));
      if (category) return category.name;
    }
    return '';
  },
  appliedFilters: state => ProductSearch.selectedFilters(state.productSearch),
  priceRangeOption: state => state.filterOptions.priceRange,
  filterOption: state => option => state.filterOptions[option],
  isBackHistory: state => state.backHistory,

  // params
  productSearchParamsStr: state => ProductSearchGenerator.generateSearchParamsStr(state.productSearch),
  paginateSearchParamsStr: (state, getters) => {
    const params = new URLSearchParams(getters.productSearchParamsStr);
    params.delete('page');

    if (getters.isProductSearchPath) params.delete('search[keywords]');
    let str = '';
    params.forEach((value, key) => (str = `${str}&${key}=${value}`));

    if (getters.isBackHistory) str = `${str}&back_browser=1`;

    return encodeURI(str.substr(1));
  },
  productSearchParamApiObject: (state, getters) =>
    ProductSearchGenerator.generateApiSearchParamsObject(
      state.productSearch,
      false,
      getters.isPromoPage,
      getters.isSortedByBestSelling,
    ),
  productSearchParamApiStr: (_, getters) =>
    qs.stringify(getters.productSearchParamApiObject, { arrayFormat: 'bracket' }),
  productSearchParamsUrlUseCurrentPath: (state, getters) =>
    getters.bhlm.page ||
    state.tagPage.valid ||
    getters.promo.page ||
    getters.trendBrand.page ||
    state.productCampaignPageData,
  productSearchParamsUrl: (state, getters) =>
    ProductSearchGenerator.generateSearchParamsUrl(state.productSearch, getters.productSearchParamsUrlUseCurrentPath),
  productSearchParamsForRetrieveFacets: state => {
    const productSearchCopy = JSON.parse(JSON.stringify(state.productSearch));
    productSearchCopy.params = removeUnnecessaryParam(productSearchCopy.params, ['category_name', 'category_slug']);
    productSearchCopy.pagination.limit = 1;
    return ProductSearchGenerator.generateApiSearchParamsStr(productSearchCopy, true);
  },
  bhlm: state => (global._INITIAL_BHLM_DATA_ ? global._INITIAL_BHLM_DATA_ : state.bhlm),
  promo: state => (global._INITIAL_PROMO_DATA_ ? global._INITIAL_PROMO_DATA_ : state.promo),
  trendBrand: state =>
    global._INITIAL_TREND_AND_BRAND_DATA_ ? global._INITIAL_TREND_AND_BRAND_DATA_ : state.trendBrand,
  tag: state => state.tag,
  isForbiddenKeyword: state => state.isForbiddenKeyword,
  isPromoPage: state => state.productCampaignPageData && !!state.productCampaignPageData.title,
  isSortedByBestSelling: (state, getters) => {
    const hasNoSearchQuery = !state.productSearch.params['search[keywords]'];
    return Boolean(
      (getters.bhlm.page && !getters.bhlm.params?.k) ||
        state.tagPage.valid ||
        getters.trendBrand.page ||
        (getters.isCategoryPage && hasNoSearchQuery),
    );
  },
  blockedKeyword: state => state.blockedKeyword,
  specialCampaigns: state =>
    state.specialCampaigns.reduce((obj, specialCampaign) => {
      return { ...obj, [specialCampaign.id]: specialCampaign };
    }, {}) || {},
  isSuperSellerNewBadgeActive: state => state.isSuperSellerNewBadgeActive,
  cache: (state, getters) => key => (getters.isCacheExpired(key) ? null : state.cache[key].data),
  isCacheExpired: state => key => !state.cache[key] || (state.cache[key] && state.cache[key].expiredAt <= Date.now()),
  isToggleActive: state => key => state.toggles?.find(({ id }) => id === key)?.active ?? false,
};

export default getters;
