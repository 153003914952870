import api from '@/shared/api';

const initialState = {
  neoConfigs: [],
  toggles: [],
};

const neoGetters = {
  searchProductTagsConfig: state =>
    state.neoConfigs ? state.neoConfigs.find(config => config.id === 'search-product-tag-config') : null,
  productMaxTags: (_, getters) =>
    getters.searchProductTagsConfig ? getters.searchProductTagsConfig.data['search-max-tag'] : 0,
  productTagsConfig: (_, getters) =>
    getters.searchProductTagsConfig ? getters.searchProductTagsConfig.data['tags'] : [],
};

const mutations = {
  UPDATE_VARIABLE(state, { label, value }) {
    state[label] = value;
  },
};

const actions = {
  async retrieveNeoConfigs({ commit }, id) {
    try {
      const { data } = await api.getNeoConfigs({ id });

      commit('UPDATE_VARIABLE', { label: 'neoConfigs', value: data });
    } catch (error) {
      console.error(error);
    }
  },
  async retrieveToggles({ commit }, ids = [], { isMobile = false } = {}) {
    const platform = isMobile ? 'bl_mobile_web' : 'bl_web';

    try {
      const { data } = await api.getNeoToggles({ id: ids, platform });

      commit('UPDATE_VARIABLE', { label: 'toggles', value: data });
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: neoGetters,
  mutations,
  actions,
};
