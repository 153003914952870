import BaseProductSearchGenerator from './base';

class IndexProductSearchGenerator extends BaseProductSearchGenerator {
  static generatePathname(productSearch, useCurrentPath) {
    const storeUsername = productSearch.params['search[store_username]'];
    const categorySlug = productSearch.params['search[category_slug]'];
    const keywords = productSearch.params['search[keywords]'];

    if (storeUsername) {
      // if productSearch is store_username
      return `/${storeUsername}/products`;
    }
    if (categorySlug) {
      // if productSearch is category_slug
      return categorySlug;
    }

    if (useCurrentPath) {
      const isPromoPage = useCurrentPath.promo && !!useCurrentPath.promo.title;
      if (keywords && !isPromoPage) {
        return '/products';
      }

      return window ? window.location.pathname : global.initialPathname;
    }

    return '/products';
  }
}

export default IndexProductSearchGenerator;
