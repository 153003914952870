import { pipe, map, flatten } from 'rambda';

const formatResult = (name, type) => ({ name, type });
const formatProvince = provinceName => formatResult(provinceName, 'province');
const formatCity = ({ cityName, provinceName }) =>
  Object.assign({}, formatResult(cityName, 'city'), { province: provinceName });

/**
 * getProvinces data from /info/addresses
 * @param {Object} addresses
 * @returns {Array} provinces
 */
const getProvinces = (addresses = {}) => pipe(map(formatProvince))(Object.keys(addresses));

/**
 * getCitiesByProvince object from each province above
 * @param {Object} provinceObject object
 * @param {String} provinceName string
 * @returns {Array} cities
 */
const getCitiesByProvince = ({ provinceObject = {}, provinceName } = {}) =>
  pipe(map(formatCity))(Object.keys(provinceObject).map(cityName => ({ cityName, provinceName })));

/**
 * get cities from addresses
 * @param {Object} addresses data
 * @returns {Array} cities with province
 */
const getAllCitiesFromAddresses = (addresses = {}) =>
  pipe(
    map(getCitiesByProvince),
    flatten,
  )(Object.keys(addresses).map(provinceName => ({ provinceName, provinceObject: addresses[provinceName] })));

const KAB_STRING = 'Kab. ';
const isDistric = cityName => cityName.includes(KAB_STRING);
const removeDistricCode = cityName => cityName.replace(KAB_STRING, '');
const getInitialCityName = cityName => cityName[0];
const sortByAlphabet = group => Object.values(group).sort((a, b) => a.name.localeCompare(b.name));
const sortCityByAlphabet = cities => cities.sort((a, b) => a.name.localeCompare(b.name));
const generateOption = (option, { $isDisabled = false, customValue } = {}) => {
  return Object.assign(
    {},
    {
      ...option,
      value: customValue ? customValue : option.name,
      $isDisabled,
    },
  );
};

/**
 * group city by alphabet
 * @param {Array} cities objects
 * @return {Array} grouped cities by alphabet
 */
const groupCityByAlphabet = (cities = [], { $isDisabled = false } = {}) => {
  const citiesCopy = cities.slice();

  const groupedCity = citiesCopy.reduce((acc, city) => {
    const { name } = city;
    const groupName = isDistric(name) ? pipe(removeDistricCode, getInitialCityName)(name) : getInitialCityName(name);
    const option = generateOption(city, { $isDisabled });

    // if not exist, attach
    if (!acc[groupName]) acc[groupName] = { name: groupName, options: [option] };
    // if exist, push to options
    else acc[groupName].options.push(option);

    return acc;
  }, {});

  return sortByAlphabet(groupedCity);
};

export {
  getProvinces,
  getCitiesByProvince,
  getAllCitiesFromAddresses,
  groupCityByAlphabet,
  generateOption,
  sortCityByAlphabet,
};
