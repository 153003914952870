export const dynRmktParamsReadyEvent = products => {
  if (!window) return;
  const remarketingProducts = products.slice(0, 3);

  window.dataLayer.push({
    event: 'dynRmktParamsReady',
    google_tag_params: {
      ecomm_prodid: remarketingProducts.map(product => product.id),
      ecomm_pagetype: 'category',
      ecomm_totalvalue: remarketingProducts.reduce((total, product) => total + product.price, 0),
    },
  });
};

export const productImpressionEvent = (keyword, products) => {
  if (!window) return;
  window.dataLayer.push({
    event: 'productImpression',
    keyword,
    ecommerce: {
      currencyCode: 'IDR',
      impressions: products,
    },
  });
};

export default {
  productImpressionEvent,
  dynRmktParamsReadyEvent,
};
