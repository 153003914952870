import _get from 'lodash/get';
import api from '@/shared/api';
import ProductSearch from '@/shared/models/search';
import IndexUrlExtractor from '@/shared/models/extractors';
import urlUtility from '@/shared/utilities/urlUtility';
import { PRODUCTS_FETCHING } from '@/shared/types/wait';
import { isBhlm } from '@/shared/utilities/bhlmUtility';
import { isCategoryPath } from '../../../utilities/breadcrumbUtility';

export default {
  async initializeMobileSearch({ commit }, { url = '', SSR = false }) {
    const productSearch = new ProductSearch(new IndexUrlExtractor(url));
    const { pathname } = productSearch.urlObj;

    if (isCategoryPath(pathname) && !isBhlm(url)) {
      if (SSR) {
        // remove /c/ at the beginning
        const permalink = pathname.replace('/c/', '');
        const { data: category } = await api.getCategories(permalink);
        if (category) {
          productSearch.params['search[category_id]'] = category.id;
        }
      }

      if (!SSR) {
        // get the id from intial data of SSR breadcrumb fragment
        const INITIAL_BREADCRUMB = '__INITIAL_PRODUCT_EXPLORER_BREADCRUMB_MOBILE__';
        if (window[INITIAL_BREADCRUMB]) {
          const breadcrumbInitialDataString = window[INITIAL_BREADCRUMB];
          const breadcrumbIntiaalData = JSON.parse(unescape(breadcrumbInitialDataString));
          const categoryId = _get(breadcrumbIntiaalData, 'categoryTree.id', null);

          if (categoryId) {
            productSearch.params['search[category_id]'] = categoryId;
          }
        }
      }
    }

    commit('UPDATE_VARIABLE', { label: 'productSearch', value: productSearch });
  },

  async updateMobilePage(
    { getters, dispatch, commit },
    {
      initializePage = false,
      scrollToTop = false,
      fromPopularFilter = false,
      forceUpdateHistory = true,
      isLoggedIn = false,
      SSR = false,
      forceUrl = '',
      isSkeleton = false,
      shouldUseSeoMultistrategy = false,
    } = {},
  ) {
    if (!initializePage) {
      dispatch('updateHistory', { forceUpdateHistory, forceUrl });
      global.STORE.commit('productExplorer/updateSearchUrl', getters.productSearchParamsUrl);
    }

    if (initializePage && !isSkeleton && SSR) {
      dispatch('retrieveSpecialCampaigns');
      dispatch('neo/retrieveNeoConfigs', ['search-product-tag-config']);
    }

    if (
      // on server when not logged-in
      (!isLoggedIn && SSR) ||
      // initializePage on logged-in
      (initializePage && isLoggedIn) ||
      // on client if SSR timeout
      (!isLoggedIn && getters.isSSRTimeout) ||
      // anything that not initializePage (next page, such apply new filter, sort or move page)
      !initializePage
    ) {
      dispatch('wait/start', PRODUCTS_FETCHING, { root: true });
      await dispatch('retrieveMobileCollections', {
        fromPopularFilter,
        isIncludeFacet: true,
        isSSR: SSR,
        shouldUseSeoMultistrategy,
        platform: 'mweb',
        isLoggedIn,
      });
      dispatch('wait/end', PRODUCTS_FETCHING, { root: true });
    }

    if (!SSR) {
      // only call all Miscs on client-side
      dispatch('retrieveMobileMiscCollections');
    }

    const pathname = urlUtility.getPathname();
    commit('UPDATE_PRODUCT_SEARCH_PATH', pathname.startsWith('/products/s/'));

    if (scrollToTop) {
      document.documentElement.scrollTop = 0;
    }
  },

  async retrieveMobileCollections({ dispatch }, options = {}) {
    await dispatch('retrieveProducts', options);
    await dispatch('related/retrieveKeywords', options);
  },

  async retrieveMobileMiscCollections({ dispatch }) {
    dispatch('retrieveOtherMobileMiscCollections');
  },

  async retrieveOtherMobileMiscCollections({ dispatch, getters }) {
    if (getters.isCategoryPage) {
      const categoryId = getters.getSearchParamValue('search[category_id]');
      dispatch('attribute/retrieveProductAttributes', { categoryId });
      dispatch('variant/retrieveProductVariants', { categoryId });
    } else {
      const categorySuggestions = await dispatch('retrieveCategorySuggestions');
      if (categorySuggestions && categorySuggestions[0] && categorySuggestions[0].score >= 0.5) {
        const categoryId = categorySuggestions[0].id;
        dispatch('attribute/retrieveProductAttributes', { categoryId });
        dispatch('variant/retrieveProductVariants', { categoryId });
      }
    }

    const isLoggedIn = !!(window.USER && window.USER.id);
    if (isLoggedIn) dispatch('retrieveInternationalCourierCountries');
  },

  toggleHistoryBack({ commit }, toggle) {
    commit('UPDATE_BACK_HISTORY', toggle);
  },
};
