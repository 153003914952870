const _sampleSize = require('lodash/sampleSize');

const { PRODUCT_TYPES } = require('types/productSearch');

const _reArrangePositionOrder = products => products.map((p, idx) => ({ ...p, position_order: idx })); // re-arrange the position
/**
 * Format multistrategy product composition
 * @param {Array} products array of products with type
 */
const formatMultistrategyComposition = (products = []) => {
  // edge cases https://bukalapak.atlassian.net/wiki/spaces/SRCH/pages/1355033742/Dora+Side+Notes#Dynamic-Layout
  const total = products.length;
  const NUMBER_OF_PRODUCT_IN_A_ROW = 5;
  const isLessThanOneRow = total < NUMBER_OF_PRODUCT_IN_A_ROW;
  const isInsufficient = total % NUMBER_OF_PRODUCT_IN_A_ROW;

  if (isLessThanOneRow || !isInsufficient) return _reArrangePositionOrder(products); // no need to fill with anything

  const promotedProducts = products.filter(p => p.position_type === PRODUCT_TYPES.PROMOTED);
  const organicProducts = products.filter(p => p.position_type === PRODUCT_TYPES.ORGANIC);

  const promotedMod = promotedProducts.length % NUMBER_OF_PRODUCT_IN_A_ROW;
  const organicMod = organicProducts.length % NUMBER_OF_PRODUCT_IN_A_ROW;

  const fillWithBusinessProducts = insufficiency => {
    const businessProducts = products.filter(p => p.position_type === PRODUCT_TYPES.BUSINESS);
    return _reArrangePositionOrder([...products, ..._sampleSize(businessProducts, insufficiency)]);
  };

  if (promotedMod && organicMod) {
    const totalInsufficientProducts = NUMBER_OF_PRODUCT_IN_A_ROW - (promotedMod + organicMod);

    return fillWithBusinessProducts(totalInsufficientProducts);
  }
  if (promotedMod) {
    const insufficientPromoted = NUMBER_OF_PRODUCT_IN_A_ROW - promotedMod;

    return fillWithBusinessProducts(insufficientPromoted);
  }
  if (organicMod) {
    const insufficientOrganic = NUMBER_OF_PRODUCT_IN_A_ROW - organicMod;

    return fillWithBusinessProducts(insufficientOrganic);
  }

  return _reArrangePositionOrder(products);
};

/**
 * Merge products with meta type for product type
 * @param {Array} products
 * @param {Object} meta
 * @returns {Array} products
 */
const mergeProductsWithMetaType = (products, meta) => {
  return products.map((product, idx) => ({
    ...product,
    position_type: meta.position[idx].type,
    position_order: idx,
  }));
};

module.exports = {
  formatMultistrategyComposition,
  mergeProductsWithMetaType,
};
