<template>
  <div class="dp-card-container">
    <observer-tracker @visible="onVisible">
      <bl-card-mv class="dp-card" @click.native="handleProductClick">
        <a data-test="url" class="dp-card__head" :href="productUrl" rel="nofollow">
          <div v-if="image && !errorImage" class="dp-card__img-container">
            <v-lazy-image
              v-if="lazyImage"
              :src="image.url"
              :alt="image.alt"
              src-placeholder="https://s0.bukalapak.com/dora/assets/images/placeholder-grey.jpg"
              class="dp-card__img"
              @error.native="errorImage = true"
            />
            <img v-else class="dp-card__img" :src="image.url" :alt="image.alt" @error="errorImage = true" />
          </div>
          <div v-else class="ratio">
            <ico-image />
          </div>
        </a>
        <div class="dp-card__body">
          <div class="dp-card__description">
            <a
              :href="productUrl"
              class="dp-card__description-link"
              rel="nofollow"
              tabindex="-1"
            >
              <bl-text-av class="dp-card__name-tag" :ellipsis="1" variation="caption-12">{{ productName }}</bl-text-av>
            </a>

            <bl-flex-container-mv
              v-if="isOfficialBlMerchandiseStore"
              direction="column"
              wrap="wrap"
              class="bl-product-card__description-benefit-tag"
            >
              <bl-flex-item-av>
                <div class="bl-product-card__tag">
                  <ico-verified-minor color="#ED0547" class="mr-4" />
                  <bl-text-av :ellipsis="1" color="highlight-01" class="bl-text--caption-10"
                    >Official Bukalapak</bl-text-av
                  >
                </div>
              </bl-flex-item-av>
            </bl-flex-container-mv>

            <bl-flex-container-mv
              v-if="isShowDigitalGoodTags"
              direction="column"
              wrap="wrap"
              class="bl-product-card__description-benefit-tag"
            >
              <bl-flex-item-av>
                <div class="bl-product-card__tag">
                  <ico-phone-iphone color="#ED0547" class="mr-4" />
                  <bl-text-av :ellipsis="1" color="highlight-01" class="bl-text--caption-10">Produk Digital</bl-text-av>
                </div>
              </bl-flex-item-av>
              <bl-flex-item-av>
                <div class="bl-product-card__tag">
                  <ico-freeshipping color="#ED0547" class="mr-4" />
                  <bl-text-av :ellipsis="1" color="highlight-01" class="bl-text--caption-10">Tanpa Ongkir</bl-text-av>
                </div>
              </bl-flex-item-av>
            </bl-flex-container-mv>

            <div class="dp-card__price">
              <bl-flex-container-mv justify-content="space-between" align-items="flex-start">
                <bl-text-av variation="body-16" weight="bold" :class="{ 'on-discount': hasDiscount }">
                  {{ productPrice }}
                </bl-text-av>
                <bl-text-av v-if="isWholesale" class="wholesale" variation="caption-10">Grosir</bl-text-av>
              </bl-flex-container-mv>
              <bl-flex-container-mv>
                <bl-text-av v-if="hasDiscount" variation="caption-12" color="subdued" class="discount-price">
                  {{ discount.price }}
                </bl-text-av>
                <bl-text-av v-if="hasDiscount" variation="caption-12" class="on-discount">{{
                  `-${discount.percent}%`
                }}</bl-text-av>
              </bl-flex-container-mv>
            </div>

            <div v-if="rating.userCount && rating.userCount !== '0'" class="dp-card__rating">
              <a :href="rating.link" class="dp-card__rating-star" rel="nofollow">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 12 12">
                  <path fill="#FFC26A" d="M6,9.54l-3.71,2L3,7.34,0,4.39l4.15-.6L6,0,7.85,3.79l4.15.6-3,3,.71,4.16Z" />
                </svg>
                <bl-text-av variation="caption-10" class="text">{{ rating.averageRate }}</bl-text-av>
              </a>
              <div v-if="soldCount !== '0'" class="rating-separator"></div>
              <bl-text-av v-if="soldCount !== '0'" variation="caption-10" class="text">{{
                `Terjual ${soldCount}`
              }}</bl-text-av>
            </div>

            <bl-flex-container-mv
              v-if="hasStoreNameLink || productStore.location"
              justify-content="space-between"
              align-content="flex-end"
              class="dp-card__seller"
            >
              <bl-flex-item-av class="dp-card__seller-info">
                <bl-text-av
                  v-if="hasStoreNameLink"
                  :href="productStore.link"
                  :ellipsis="1"
                  rel="nofollow"
                  variation="caption-10"
                  class="dp-card__seller-link"
                  >{{ productStore.name }}</bl-text-av
                >
                <img
                  v-if="isShowItemkuTag"
                  src="https://s2.bukalapak.com/m-ast/web-drawable/drawable-mdpi/logo_itemku.png"
                  alt="Itemku Logo"
                  width="48px"
                  height="16px"
                  class="mt-8 dp-card__seller-brand-logo"
                />
                <bl-text-av
                  v-else
                  variation="caption-10"
                  color="subdued"
                  :ellipsis="1"
                  class="dp-card__seller-loc mt-8"
                >
                  {{ productStore.location }}
                </bl-text-av>
              </bl-flex-item-av>

              <bl-emblem-av
                v-if="isSuperSeller || isOfficialStore"
                class="badge"
                :variation="isSuperSeller ? `superseller_icon` : `bukamall_icon`"
              />
            </bl-flex-container-mv>
          </div>

          <bl-button-av
            class="dp-card__action"
            :disabled="isProductLoading"
            fullwidth
            variation="secondary"
            size="small"
            @click="handleBuyProduct"
            >Beli</bl-button-av
          >
        </div>

        <!-- floating buttons -->
        <div v-if="isUsedItem || hasBadgeAds" class="dp-card__floating">
          <div v-if="hasBadgeAds" class="dp-card__floating-badge">
            <ico-promotion color="#fff" class="prompt" />
          </div>
          <div v-if="isUsedItem" class="dp-card__floating-badge">
            <bl-text-av color="inverse" variation="caption-10">Bekas</bl-text-av>
          </div>
        </div>
      </bl-card-mv>
    </observer-tracker>
  </div>
</template>

<script>
import ProductCardMixins from '../mixins/ProductCard';
import BlIconAv from '@bukalapak/bazaar-visual-next/dist/components/BlIconAv';
import IcoVerifiedMinor from '@bukalapak/bazaar-visual-next/dist/components/IcoVerifiedMinor';
import IcoImage from '@bukalapak/bazaar-visual-next/dist/components/IcoImage';
import IcoPhoneIphone from '@bukalapak/bazaar-visual-next/src/components/IcoPhoneIphone';
import IcoFreeshipping from '@bukalapak/bazaar-visual-next/src/components/IcoFreeshipping';
import IcoPromotion from '@bukalapak/bazaar-visual-next/src/components/IcoPromotion';

import VLazyImage from 'v-lazy-image';

export default {
  name: 'ProductCard',

  components: {
    IcoVerifiedMinor,
    IcoPromotion,
    IcoFreeshipping,
    IcoPhoneIphone,
    VLazyImage,
    IcoImage,
  },

  mixins: [ProductCardMixins],
};
</script>

<style scoped lang="scss">
@import '~@bukalapak/bazaar-token/dist/tokens.scss';
// need to redefine the color because the bazaar is outdated
.bl-product-card {
  &__description-benefit-tag {
    margin: 0 -2px 2px;
  }
  &__tag {
    max-width: 100%;
    height: 16px;
    display: inline-flex;
    align-items: center;
    padding: 2px 4px;
    margin: 2px;
    border-radius: 4px;
    background: #fce6ed;
    svg {
      flex-shrink: 0;
      width: 10px !important;
      height: 10px !important;
    }
  }
}
.bl-text {
  &--highlight-01 {
    color: #d1083a;
  }
  &--caption-10 {
    font-size: 10px;
    line-height: 12px;
  }
}

.dp-card-container {
  padding: 4px;
}

.dp-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #04f;
  }

  &__head {
    text-align: center;

    .ratio {
      position: relative;
      display: block;
      overflow: hidden;
      background-color: #eee;

      &::before {
        display: block;
        width: 100%;
        padding-bottom: 100%;
        content: '';
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }

  &__body {
    padding: 8px;
  }

  &__description {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    padding-top: 20px;
    text-decoration: none;
    flex-direction: column;
  }

  &__description-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__img-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-shrink: 0;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    transition: border $duration-base ease-out;
  }

  &__name-tag {
    margin-bottom: 4px;
    font-weight: 500;
  }

  &__price {
    margin-bottom: 8px;

    .discount-price {
      margin-right: 4px;
      text-decoration: line-through;
      letter-spacing: 0.15px;
    }

    .on-discount {
      color: #ea5164;
    }

    .wholesale {
      padding: 2px 4px;
      margin-right: 4px;
      color: #cc5e57;
      background: #fff7ec;
      border-radius: 4px;
    }
  }

  &__seller {
    margin-bottom: 4px;
    padding-right: 4px;

    &-info {
      width: calc(100% - 24px);
    }

    &-link {
      position: relative;
    }

    &-brand-logo {
      display: block;
      height: 16px;
      margin-bottom: -4px;
    }

    .badge {
      position: relative;

      svg {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: scale(0.84);
      }
    }
  }

  &__rating {
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    &-star {
      display: flex;
      position: relative;
      align-items: center;
      margin-right: 4px;
    }

    .text {
      margin-left: 4px;
      line-height: 1.2;
    }

    &-wrapper {
      padding-right: 8px;
    }

    .rating-separator {
      width: 1px;
      height: 10px;
      background: $gray-30;
    }
  }

  &__action {
    position: relative;
    font-weight: 500;
    border-radius: 4px;
  }

  &__floating {
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;

    &-badge {
      height: 20px;
      padding: 4px;
      margin-right: 4px;
      background-color: rgba(20, 21, 26, 0.2);
      border-radius: 2px;
    }

    .prompt {
      margin: -6px -6px 0;
      transform: scale(0.6);
    }
  }

  &__loading {
    position: relative;
    height: 100%;
  }
}
</style>
