let firstUrl = '';

function enable(store, { mobile = false } = {}) {
  firstUrl = store.getters.productSearchParamsUrl;

  if (window.ga) {
    // looking around, after popstate is ga 'page' is not reset to document.location
    window.ga('set', 'page', document.location.href);
  }

  if (mobile)
    window.addEventListener('popstate', () => {
      store.dispatch('initializeMobileSearch', { url: document.location.href });
      store.dispatch('updateMobilePage', { forceUpdateHistory: false });
      store.dispatch('toggleHistoryBack', true);
    });
  else
    window.addEventListener('popstate', () => {
      store.dispatch('initializeProductSearch', { initialUrl: document.location.href });
      store.dispatch('updatePage', { forceUpdateHistory: false });
      store.dispatch('toggleHistoryBack', true);
    });
}
function disable() {
  window.removeEventListener('popstate');
}

function pushHistory(urlToVisit, forceUpdateHistory) {
  const currentUrl = document.location.href;
  const { title } = document;

  if (window.ga) {
    // ga set page
    window.ga('set', 'page', urlToVisit);

    // ga send pageview
    window.ga('send', 'pageview', urlToVisit);
  }

  if ((urlToVisit !== currentUrl && urlToVisit !== firstUrl) || forceUpdateHistory) {
    window.history.pushState({ url: urlToVisit }, title, urlToVisit);
  }
}

export { enable, disable, pushHistory };
