import { numberHelper } from '@bukalapak/toolbox-helper';
import { mapGetters } from 'external/Vuex';
import _get from 'lodash/get';
import { AllHtmlEntities } from 'html-entities';

import urlUtility from '@/shared/utilities/urlUtility';
import { promotedSection, isOnDiscount } from '@/shared/utilities/productsUtility';
import { PRODUCT_TAG_ICON } from '@/shared/types/productTagIconMapping';

const { money, simpleNumber, simpleMoney } = numberHelper;

export default {
  filters: {
    money(amount) {
      return money(amount);
    },

    simpleMoney(amount) {
      return simpleMoney(amount);
    },

    simpleNumber(amount) {
      return simpleNumber(amount);
    },
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
    paramsObj: {
      type: Object,
      default: () => ({}),
    },
    imageSize: {
      type: Number,
      default: 463,
    },
    lazyImage: {
      type: Boolean,
      default: false,
    },
    useWebp: {
      type: Boolean,
      default: false,
    },
    isPWABukaGames: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errorImage: false,
    };
  },

  computed: {
    ...mapGetters(['products', 'pagination', 'currentKeyword', 'specialCampaigns', 'isProductSearchPath']),
    isLoggedIn() {
      return global.USER && global.USER.id36;
    },
    isWholesale() {
      return this.product.wholesales.length > 0;
    },
    pixelTrackerData() {
      const section = promotedSection(this.products, this.pagination, this.product.id);
      const keyword = this.currentKeyword || '';

      return {
        evn: 'viewed_promoted_product',
        ui: global.USER && global.USER.id36,
        product_id: this.product.id.toString(36),
        seller_id: this.product.store.id.toString(36),
        section,
        keyword,
      };
    },
    isUsedItem() {
      return this.product.condition === 'Bekas';
    },
    soldCount() {
      return simpleNumber(this.product.stats && this.product.stats.sold_count ? this.product.stats.sold_count : 0);
    },
    productStore() {
      const store = this.product.store;
      if (store) {
        return {
          name: store.name,
          link: store.url.replace('www.', 'm.'),
          location: store.address.city || '',
        };
      }
      return {
        name: '',
        link: '',
        location: '',
      };
    },
    hasStoreNameLink() {
      return this.productStore.name && this.productStore.link;
    },
    hasBadgeAds() {
      return this.product.position_type === 'promoted';
    },
    isProductLoading() {
      return !!this.product.isLoading;
    },
    productPrice() {
      if (this.isSubsidy) return simpleMoney(this.product.price);
      return this.hasDiscount ? simpleMoney(this.product.deal.discount_price) : simpleMoney(this.product.price);
    },
    productName() {
      return AllHtmlEntities.decode(this.product.name);
    },
    productUrl() {
      const productUrlMobile = this.product.url.replace('www.', 'm.');

      if (this.paramsObj.isCategoryPage || this.paramsObj.from === 'bhlm' || this.paramsObj.from === 'tag') {
        return productUrlMobile;
      }

      let params = {
        from: this.paramsObj.from,
        pos: this.product.position_order,
      };

      if (this.isLoggedIn) {
        params = Object.assign(params, {
          keyword: this.paramsObj.keyword,
          funnel: this.paramsObj.funnel,
          product_owner: this.isOfficialStore ? 'seller_brand' : 'normal_seller',
          cf: '1',
          ssa: this.isSuperSeller ? '1' : '0',
          sort_origin: this.paramsObj.sort_origin,
          search_sort_default: this.paramsObj.search_sort_default,
          promoted: this.product.position_type === 'promoted' ? 1 : 0,
        });

        if (this.paramsObj.isAnyFilterApplied) params.acf = '1';
        if (this.paramsObj.isCategoryPage && this.paramsObj.isAnyFilterApplied) params.cp = '1';
        if (this.$store.state.searchContext?.content?.search_query_id) {
          const searchContext = this.$store.state.searchContext?.content;

          params.product_slot_type = this.product.position_type;
          params.search_query_id = searchContext.search_query_id;
          params.is_keyword_typo = searchContext.is_keyword_typo;
          params.keyword_expansion = searchContext.keyword_expansion;
          params.search_result_size = searchContext.search_result_size;
        }
      }

      const productUrlPath = new URL(productUrlMobile).pathname;
      const encodedParams = urlUtility.encodeUrlParams(params);

      return `${productUrlPath}?${encodedParams}`;
    },
    isOfficialStore() {
      return this.product.store && this.product.store.brand_seller;
    },
    isPremiumSeller() {
      return this.product.store && this.product.store.premium_level !== 'none' && !this.isOfficialStore;
    },
    isSuperSeller() {
      return this.isPremiumSeller && this.product.store && this.product.store.premium_top_seller;
    },
    isOfficialBlMerchandiseStore() {
      return this.product && this.$root?.blMerchSellerIds?.includes(this.product.store?.id);
    },
    isDigitalGoodsCategory() {
      return this.product && this.product.digital_product;
    },
    isShowDigitalGoodTags() {
      return !this.isPWABukaGames && this.isDigitalGoodsCategory;
    },
    isItemkuProduct() {
      if (!this.$root.blItemkuConfig) return false;

      const criterias = this.$root.blItemkuConfig.criterias || [];
      return (
        this.product &&
        criterias.some(({ field, values }) => {
          return values.includes(_get(this.product, field));
        })
      );
    },
    isShowItemkuTag() {
      return !this.isPWABukaGames && this.isItemkuProduct;
    },
    hasDiscount() {
      if (this.isSubsidy) return true;
      if (Object.keys(this.product.deal).length > 0) {
        const { deal } = this.product;

        return deal.percentage && isOnDiscount(deal.applied_date, deal.expired_date);
      }

      return false;
    },
    discount() {
      if (this.isSubsidy) {
        return {
          percent: this.product.discount_percentage,
          price: simpleMoney(this.product.original_price),
        };
      }

      if (this.hasDiscount) {
        return {
          percent: this.product.deal.percentage,
          price: simpleMoney(this.product.deal.original_price),
        };
      }
      return {};
    },
    rating() {
      return {
        averageRate: this.product.rating.average_rate,
        userCount: simpleNumber(this.product.rating.user_count),
        link: new URL(this.product.url.replace('/p/', '/reviews/')).pathname,
      };
    },
    image() {
      if (this.product.images && this.product.images.large_urls.length) {
        const image = this.product.images.large_urls[0];

        const rgx = /\/(large)\//;
        const matches = rgx.exec(image);
        let result = image.replace(matches[1], `s-${this.imageSize}-${this.imageSize}`);

        if (this.useWebp) {
          result = `${result}.webp`;
        }

        return {
          id: this.product.id,
          alt: this.productName,
          url: result,
        };
      }
    },
    hasTag() {
      return !!this.productTags.length;
    },
    wholesaleText() {
      if (!this.isWholesale) {
        return '';
      }
      const wholesale = this.product.wholesales[0];
      return `Beli ${wholesale.lower_bound} diskon ${simpleMoney(this.product.price - wholesale.price)}/pcs`;
    },
    productTagsConfig() {
      return this.$store.getters['neo/productTagsConfig'];
    },
    productMaxTags() {
      return this.$store.getters['neo/productMaxTags'] || 3;
    },
    searchListingProductTagsConfig() {
      return this.productTagsConfig
        .filter(
          config =>
            config.enabled &&
            config.criterias.find(criteria => criteria.type === 'screen').values.includes('search_listing'),
        )
        .map(({ id, title, criterias, icon }) => {
          return {
            id,
            title,
            icon,
            ...criterias[0],
          };
        });
    },
    productTags() {
      const tags = this.searchListingProductTagsConfig
        .filter(config => {
          const tagIcon = config.icon || PRODUCT_TAG_ICON[config.id];
          const attributeValue =
            this.product && typeof _get(this.product, config.type) !== 'undefined'
              ? _get(this.product, config.type).toString()
              : null;
          return attributeValue && config.values.includes(attributeValue) && !!tagIcon;
        })
        .map(config => {
          return {
            icon: PRODUCT_TAG_ICON[config.id],
            text: config.id && config.id === 'grosir' ? this.wholesaleText : config.title,
          };
        });
      return tags.slice(0, this.productMaxTags);
    },
    productSpecialCampaign() {
      return this.product.special_campaign_id ? this.specialCampaigns[this.product.special_campaign_id] : null;
    },
    productSpecialCampaignBannerUrl() {
      if (!this.productSpecialCampaign) {
        return '';
      }
      const now = new Date();
      const start = new Date(this.productSpecialCampaign.start_time);
      const end = new Date(this.productSpecialCampaign.end_time);

      if (now >= start && now < end) {
        return this.productSpecialCampaign.banner_list_url;
      }
      return '';
    },
    isSubsidy() {
      return this.product.discount_percentage > 0;
    },
  },

  methods: {
    handleProductClick() {
      this.$emit('click', this.product);
    },
    handleBuyProduct() {
      this.$emit('buy', this.product);
    },
    onVisible() {
      if (this.product.position_type === 'promoted') {
        try {
          const gifUrl = window.Tracker.pixel(this.pixelTrackerData);
          window.axios.get(gifUrl);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
