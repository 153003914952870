import interfaces from './interfaces';
import wrapInterfaceSSR from './wrapInterfaceSSR';

let wrapSecureClientInterface = global.API.wrap;

if (process.server) {
  wrapSecureClientInterface = wrapInterfaceSSR;
}

export default wrapSecureClientInterface(interfaces);
