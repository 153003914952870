const INITIAL_APP_DATA = '__INITIAL_MAIN_MOBILE__';
const INITIAL_SEO_PAGE_TITLE = '__INITIAL_SEO_PAGE_TITLE__';
const MOUNT_POINT_ID = 'product-explorer-mobile';
const PRODUCT_EXPLORER_MODE = '__PRODUCT_EXPLORER_MODE__';
const COD_VALUE_NAME = 'Bayar di Tempat (COD)';
const NEO_TOGGLES = '__NEO_TOGGLES__';
const NEO_CONFIGS = '__NEO_CONFIGS__';

const UI_DARKER_COLOR = '#ced2d9';

module.exports = {
  INITIAL_APP_DATA,
  INITIAL_SEO_PAGE_TITLE,
  MOUNT_POINT_ID,
  PRODUCT_EXPLORER_MODE,
  COD_VALUE_NAME,
  NEO_TOGGLES,
  UI_DARKER_COLOR,
  NEO_CONFIGS,
};
