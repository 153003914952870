import qs from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import escape from 'lodash/escape';
import to from 'await-to-js';
import get from 'lodash/get';
import pick from 'lodash/pick';

import api from '@/shared/api';
import { pushHistory } from '@/shared/utilities/historyUtility';
import cacheUtility from '@/shared/utilities/cacheUtility';
import ProductSearch from '@/shared/models/search';
import { promotedSection } from '@/shared/utilities/productsUtility';
import { getProvinces, getAllCitiesFromAddresses } from '@/shared/utilities/filters/location-utils';
import { PROMOTED_PUSH_CHARGE_URL } from '@/shared/api/url';
import { RESTRICTED_KEYWORD, SUPER_SELLER_BADGE_VERSION_FETCHING } from '@/shared/types/wait';
import { AGE_RESTRICTED_KEYWORD_CACHE_KEY } from '@/shared/types/productSearch';
import { CACHE_PRODUCT_RESULTS_TOGGLE } from '@/shared/types/toggleKeys';
import { formatMultistrategyComposition } from '@/shared/utilities/multistrategyUtility';

const basicProductProperties = [
  'id',
  'name',
  'for_sale',
  'max_quantity',
  'stock',
  'category.id',
  'category.structure',
  'condition',
  'price',
  'url',
  'deal',
  'discount_subsidy',
  'discount_percentage',
  'original_price',
  'specs',
  'images',
  'rating',
  'sku_id',
  'min_quantity',
  'wholesales',
  'store.id',
  'store.name',
  'store.address',
  'store.brand_seller',
  'store.premium_level',
  'store.reviews',
  'store.premium_top_seller',
  'store.url',
  'stats.sold_count',
  'digital_product',
  'created_at',
];
const productTagsProperties = [
  'assurance',
  'digital_product',
  'shipping.free_shipping_coverage',
  'special_campaign_id',
  'store.carriers',
  'wholesales',
  'without_shipping',
];
const allowedAttributes = {
  mobile: [
    ...basicProductProperties,
    'store.carriers',
    'shipping',
    'assurance',
    'without_shipping',
    'free_shipping',
    'special_campaign_id',
  ],
  desktop: [...basicProductProperties, ...productTagsProperties, 'store.reviews'],
};

const actions = {
  updateProductSearchParams({ getters, dispatch, commit }, payload) {
    const currentCategoryId = getters.getSearchParamValue('search[category_id]');
    const currentSort = getters.getSearchParamValue('search[sort_by]');

    payload.forEach(param => {
      // reset attribute and variant when category changed
      if (param.label === 'search[category_id]' && param.label !== currentCategoryId) {
        commit('CLEAR_PRODUCT_SEARCH_ATTRIBUTE');
        commit('CLEAR_PRODUCT_SEARCH_VARIANT');
      }

      if (param.label === 'search[todays_deal]' && currentSort === 'percentage:desc') {
        commit('RESET_PRODUCT_SORT');
      }

      commit('UPDATE_PRODUCT_SEARCH_PARAM', param);
    });

    dispatch('resetPagination');
  },

  updateProductSearchAttributes({ commit, dispatch }, payloads) {
    Object.keys(payloads).forEach(attribute => {
      if (payloads[attribute].length === 0) delete payloads[attribute];
    });
    commit('UPDATE_PRODUCT_SEARCH', { label: 'attributes', value: payloads });
    dispatch('resetPagination');
  },

  updateProductSearchVariants({ commit, dispatch }, payloads) {
    Object.keys(payloads).forEach(variant => {
      if (payloads[variant].length === 0) delete payloads[variant];
    });

    commit('UPDATE_PRODUCT_SEARCH', { label: 'variants', value: payloads });
    dispatch('resetPagination');
  },

  setProductCartQuantity: ({ commit }, { products, cartItems }) => {
    const productsCopy = cloneDeep(products);

    for (const item of cartItems) {
      const cartItem = productsCopy.find(p => p.id === item.stuff.product_id);
      if (cartItem) {
        cartItem.cartQuantity = item.quantity;
      }
    }

    commit('UPDATE_COLLECTION', { collectionName: 'products', newCollection: productsCopy });
  },
  getBhlmParams({ getters }) {
    const dashed = getters.bhlm.dashed;
    const searchParams = dashed.search_params;
    const priceMin = searchParams.price_min || '';
    const priceMax = searchParams.price_max || '';
    const brand = get(searchParams, 'filter_attr.brand[0]', '');
    let params = {
      category_id: dashed.category_id,
    };

    if (searchParams.keyword) {
      params['keywords'] = searchParams.keyword;
    }

    if (searchParams.new) {
      params['condition'] = 'new';
    }

    if (searchParams.used) {
      params['condition'] = 'used';
    }

    if (brand) {
      params['attribute_filter[brand]'] = brand;
    }

    if (priceMin >= 0 && priceMax > 0) {
      params['price_range'] = `${priceMin}:${priceMax}`;
    }

    if (searchParams.province) {
      params['provinces'] = searchParams.province;
    }

    if (searchParams.city) {
      delete params.provinces;
      params['cities[]'] = searchParams.city;
    }

    return params;
  },
  async getTrendBrandParams({ getters }) {
    const filters = getters.trendBrand.product_filters;
    const params = {};

    if (filters.keywords) {
      params['keywords'] = filters.keywords;
    }

    if (filters.brand[0]) {
      params['attribute_filter[brand]'] = filters.brand;
    }

    if (filters.category_id) {
      params['category_id'] = filters.category_id;
    }

    return params;
  },
  getTagParams({ state }, params) {
    const customParams = {
      tag_page_slug: state.tagPage ? state.tagPage.slug : '',
      promoted: 'false',
    };
    if (!state.tagPage.hasSortBy) {
      customParams['sort'] = params['sort'] || 'date';
    }
    return customParams;
  },
  getProductCampaignParams({ state }) {
    const { productCampaignPageData } = state;
    const customParams = {
      campaign_ids: [productCampaignPageData.promo.bukalapak_campaign_id],
    };
    if (productCampaignPageData.category && !productCampaignPageData.isFirstRunComplete) {
      customParams.category_id = productCampaignPageData.category.id;
    }

    productCampaignPageData.isFirstRunComplete = true;

    return customParams;
  },
  getPromoParams({ getters }) {
    const params = {
      campaign_ids: getters.promo.campaign_ids,
    };
    if (getters.promo.category_id) params.category_id = getters.promo.category_id;
    return params;
  },
  async getCustomRetrieveProductsParams({ getters, dispatch, state }, params) {
    let customParams = {};
    if (getters.bhlm.page) {
      customParams = await dispatch('getBhlmParams');
    } else if (state.tagPage && state.tagPage.valid) {
      customParams = await dispatch('getTagParams', params);
    } else if (state.productCampaignPageData) {
      customParams = await dispatch('getProductCampaignParams');
    } else if (getters.promo && getters.promo.page) {
      customParams = await dispatch('getPromoParams');
    } else if (getters.trendBrand && getters.trendBrand.page) {
      customParams = await dispatch('getTrendBrandParams');
    }
    return Object.assign(params, customParams);
  },
  populateProducts({ getters, commit, dispatch, rootState }, { data, meta, platform = null }) {
    const mergeById = (products, meta) => {
      if (!meta.total || !products.length) {
        return [];
      }

      return products.reduce((accumulator, product, idx) => {
        product = pick(product, allowedAttributes[getters.platform]);
        const position_type = meta.position[idx].type;
        return [
          ...accumulator,
          {
            ...product,
            name: escape(product.name),
            position_order: idx,
            position_type: position_type,
            cartQuantity: 0,
            isLoading: false,
            wholesale: product.wholesales && product.wholesales.length > 0,
            free_shipping: get(product, 'shipping.free_shipping_coverage.length', 0) > 0,
            search_query_id: meta.search_context?.search_query_id,
            position_type_order: accumulator.filter(p => position_type === p.position_type).length,
          },
        ];
      }, []);
    };

    if (meta.search_context) {
      commit('searchContext/SET_SEARCH_CONTEXT', meta.search_context, { root: true });

      if (meta.page == 1)
        dispatch('searchContext/sendExperimentContext', { event: 'search-product-event', platform: platform });
    }

    const transformedData = formatMultistrategyComposition(mergeById(data, meta));
    // @TODO check if price range meta's exist
    commit('UPDATE_COLLECTION', { collectionName: 'products', newCollection: transformedData });
    commit('REPLACE_PAGINATION', {
      totalPages: meta.total_pages || Math.ceil(meta.total / 24),
      total: meta.total,
      limit: meta.per_page || 24,
    });

    const facets = get(meta, 'facets', []);
    commit('UPDATE_COLLECTION', {
      collectionName: 'facets',
      newCollection: facets,
    });
    commit('UPDATE_VARIABLE', { label: 'blockedKeyword', value: meta.blocked_keywords });
    dispatch('handleBlockedKeyword', meta.blocked_keywords);

    // send pageviews tracker
    if (rootState?.searchContext?.content?.search_query_id) dispatch('tracker/sendTracker');
  },
  populateSeoProducts({ getters, commit }, { data, meta }) {
    const mergeById = (products, meta) => {
      if (meta.total && products.length) {
        return products.map((product, idx) => {
          product = pick(product, allowedAttributes[getters.platform]);

          return {
            ...product,
            name: escape(product.name),
            position_order: idx,
            position_type: 'organic', // hardcoded since this value is not provided (not neccessary to be provided) from new endpoint while existing implementation needs this to render products. So as to not interrupt current flow, and it is too much of a hassle to put another conditionals in highlightedProduct/priceTableProducts, this value is hardcoded. Either 'organic'|'business' will suffice.
            cartQuantity: 0,
            isLoading: false,
            wholesale: product?.wholesales?.length > 0,
            free_shipping: get(product, 'shipping.free_shipping_coverage.length', 0) > 0,
          };
        });
      }
      return [];
    };

    // empty search context store
    commit('searchContext/SET_EMPTY_SEARCH_CONTEXT', { root: true });

    const transformedData = formatMultistrategyComposition(mergeById(data, meta));
    // @TODO check if price range meta's exist
    commit('UPDATE_COLLECTION', { collectionName: 'products', newCollection: transformedData });
    commit('REPLACE_PAGINATION', {
      totalPages: Math.ceil(meta.total / meta.per_page),
      total: meta.total,
      limit: meta.per_page || 50,
    });

    commit('UPDATE_VARIABLE', { label: 'blockedKeyword', value: meta.blocked_keywords });
  },
  async retrieveProducts(
    { commit, getters, dispatch },
    {
      fromPopularFilter = false,
      isIncludeFacet = false,
      isSSR = false,
      shouldUseSeoMultistrategy = false,
      platform = null,
      isLoggedIn,
    } = {},
  ) {
    commit('UPDATE_ERROR_STATE', false);
    let params = {
      ...getters.productSearchParamApiObject,
      facet: isIncludeFacet,
      shouldUseSeoMultistrategy,
      isLoggedIn,
    };
    let dispatchPopulateTarget = 'populateProducts';
    if (getters.isAnyFilterApplied) {
      params.filter_non_popular_section = !fromPopularFilter;
    }

    try {
      params = await dispatch('getCustomRetrieveProductsParams', params);

      if (shouldUseSeoMultistrategy && !isLoggedIn) {
        dispatchPopulateTarget = 'populateSeoProducts';
        if (params['attribute_filter[brand]'] && params['attribute_filter[brand]'][0]) {
          params['attribute_filter[brand]'] = params['attribute_filter[brand]'][0];
        }
      }

      const cacheKey = JSON.stringify({ retrieveProducts: params });

      if (getters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE) && !getters.isCacheExpired(cacheKey)) {
        return dispatch(dispatchPopulateTarget, getters.cache(cacheKey));
      }

      const cacheValue = await dispatch('retrieveUncachedProducts', params);
      dispatch(dispatchPopulateTarget, { ...cacheValue, platform: platform });

      if (getters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE) && !isSSR) {
        commit('UPDATE_CACHE', { key: cacheKey, value: cacheValue });
      }
    } catch (error) {
      commit('UPDATE_ERROR_STATE', true);
      throw new Error(error);
    }
  },
  async retrieveUncachedProducts({ state, commit, getters, dispatch }, params) {
    try {
      let getProductResponse =
        state.mockData && state.mockData.allowMocking
          ? await dispatch('mockData/mockMultiStrategy', { platform: getters.platform })
          : null;

      let getProductsApi = api.getProducts;
      let headers = {};

      if (params.shouldUseSeoMultistrategy && !params.isLoggedIn) {
        getProductsApi = api.getSeoProducts;
      } else {
        params.show_search_contexts = true;
        headers['X-Device-Ad-ID'] = typeof window !== 'undefined' ? window.Tracker.$userIdentity : '';
      }

      return getProductResponse ? getProductResponse : await getProductsApi({ params, headers });
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // means timeout https://github.com/axios/axios/issues/1543
        commit('UPDATE_SSR_TIMEOUT_STATUS', true);
      }
      commit('UPDATE_ERROR_STATE', true);
      throw new Error(error);
    }
  },

  async retrieveFacets({ commit, getters }) {
    // @TODO : this parse quite expensive process, we can get the value before it stringified
    const params = qs.parse(getters.productSearchParamsForRetrieveFacets, { arrayFormat: 'bracket' });

    try {
      const { meta } = await api.getFacets(params);

      if (meta.facets && meta.facets.length > 0) {
        commit('UPDATE_COLLECTION', {
          collectionName: 'facets',
          newCollection: meta.facets,
        });
      }
    } catch (err) {
      // error handler
    }
  },

  async retrieveCategorySuggestions({ getters, commit, dispatch }) {
    const keyword = getters.getSearchParamValue('search[keywords]').trim();
    const cacheKey = JSON.stringify({ categorySuggestions: keyword });

    if (keyword && getters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE) && !getters.isCacheExpired(cacheKey)) {
      return commit('UPDATE_COLLECTION', getters.cache(cacheKey));
    }

    const categorySuggestions = await dispatch('retrieveUncachedCategorySuggestions', keyword);

    if (!categorySuggestions || (Array.isArray(categorySuggestions) && !categorySuggestions.length)) return;

    commit('UPDATE_COLLECTION', categorySuggestions);

    if (getters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE)) {
      commit('UPDATE_CACHE', { key: cacheKey, value: categorySuggestions });
    }
  },

  async retrieveUncachedCategorySuggestions(_, keyword) {
    if (!keyword.length) return;

    try {
      const { meta, data } = await api.getCategorySuggestions({
        term: keyword,
        limit: 3,
      });

      // @TODO Send to Sentry
      if (meta.errors) return;

      if (data.length > 0) {
        return { collectionName: 'categorySuggestions', newCollection: data };
      }

      return data;
    } catch (error) {
      // error handler
    }
  },

  async retrieveAddresses({ commit }) {
    let addresses = cacheUtility.get('addresses');

    if (addresses) {
      commit('UPDATE_VARIABLE', { label: 'addresses', value: addresses });
    }

    const [error, { data = {} } = {}] = await to(api.getAddresses());
    if (error) console.log('fetch addresses error :', error);

    if (Object.keys(data).length > 0) {
      addresses = data;
      commit('UPDATE_VARIABLE', { label: 'addresses', value: addresses });
    }

    if (addresses && Object.keys(addresses).length > 0) {
      commit('UPDATE_FILTER_OPTIONS', {
        label: 'cities',
        value: getAllCitiesFromAddresses(addresses),
      });
      commit('UPDATE_FILTER_OPTIONS', {
        label: 'provinces',
        value: getProvinces(addresses),
      });

      cacheUtility.set('addresses', addresses);
    }
  },

  async retrieveCouriers({ commit }) {
    const cacheKey = 'courier-by-categories';
    const couriers = cacheUtility.get(cacheKey);

    if (couriers) {
      commit('UPDATE_FILTER_OPTIONS', { label: 'courierByCategories', value: couriers });
    }

    try {
      const { data } = await api.getCourierByCategories();
      // const data = await miscUtility.reformatCouriers(response.data);

      if (data.length > 0) {
        commit('UPDATE_FILTER_OPTIONS', { label: 'courierByCategories', value: data });
        cacheUtility.set(cacheKey, data, 3600000);
      }
    } catch (error) {
      // error
    }
  },

  async retrieveInternationalCourierCountries({ commit }) {
    try {
      const { data = {} } = await api.getInternationalCountryDeliveries();

      if (data.countries.length > 0) {
        const countries = data.countries.filter(item => Object.keys(item).length !== 0);
        commit('UPDATE_INTERNATIONAL_COURIERS', {
          countries: countries,
        });
      }
    } catch (error) {
      // send to Sentry?
    }
  },

  updateHistory({ commit, getters, state }, { forceUpdateHistory, forceUrl } = {}) {
    const urlToVisit = forceUrl || getters.productSearchParamsUrl;

    const toProducts = urlToVisit.includes('/products');
    if (toProducts && (getters.bhlm.page || state.tagPage.valid || getters.trendBrand.page || getters.promo.page)) {
      window.location = urlToVisit;
      return;
    }

    pushHistory(urlToVisit, forceUpdateHistory);
    commit('UPDATE_BACK_HISTORY', false);
  },

  resetPagination({ commit }) {
    commit('UPDATE_PAGINATION', { label: 'currentPage', value: 1 });
    commit('UPDATE_PAGINATION', { label: 'offset', value: 0 });
  },

  updatePagination({ commit }, payload) {
    commit('UPDATE_PAGINATION', payload);
  },

  resetValueByLabel({ commit }, paramLabel) {
    commit('RESET_PARAM_VALUE_BY_LABEL', paramLabel);
  },

  replaceProductSearch({ commit }, newProductSearch) {
    commit('REPLACE_PRODUCT_SEARCH', newProductSearch);
  },

  resetProductSearch({ commit, getters }) {
    const currentProductSearchCopy = Object.assign({}, getters.currentProductSearch);

    const initialParams = ProductSearch.initialParams();
    // do not reset keyword, sort by & category & super seller tier
    delete initialParams['search[keywords]'];
    delete initialParams['search[sort_by]'];
    delete initialParams['search[category_id]'];
    delete initialParams['search[category_slug]'];
    delete initialParams['search[category_name]'];
    delete initialParams['search[super_seller_tier]'];

    currentProductSearchCopy.attributes = {};
    currentProductSearchCopy.variants = {};
    currentProductSearchCopy.params = Object.assign({}, currentProductSearchCopy.params, initialParams);

    commit('REPLACE_PRODUCT_SEARCH', currentProductSearchCopy);
  },

  handleOmni({ dispatch, commit }, payloads) {
    payloads.forEach(({ paramLabel: label, paramValue: value }) => {
      commit('UPDATE_PRODUCT_SEARCH_PARAM', { label, value });
    });
    commit('CLEAR_PRODUCT_SEARCH_ATTRIBUTE');
    commit('CLEAR_PRODUCT_SEARCH_VARIANT');
    dispatch('resetPagination');
    dispatch('updatePage', { scrollToTop: true });
  },
  chargePromotedProduct({ getters }, payload) {
    const { id } = payload;
    const keyword = getters.currentKeyword || '';
    const section = promotedSection(getters.products, getters.pagination, id);

    const identity = (window.USER && window.USER.identity) || document.cookie.match(/browser_id=(.*)/)[1].split(/;/)[0];

    const data = {
      productId: id,
      section: section,
      search_keyword: keyword,
      identity,
    };

    const lsKey = window.APP.env === 'production' ? 'bl_token' : 'bl_token_dev';
    const accessToken = JSON.parse(window.localStorage.getItem(lsKey) || '{}').access_token;

    if (window.navigator && window.navigator.sendBeacon && accessToken) {
      const headers = {
        type: 'application/json',
      };

      let blob = new Blob([JSON.stringify(data)], headers);
      window.navigator.sendBeacon(PROMOTED_PUSH_CHARGE_URL(id, accessToken), blob);
    } else {
      api.chargePromotedProduct(data);
    }
  },
  showErrorMessage({ getters, commit }, message) {
    if (getters.errorMessage.show === true) {
      commit('TOGGLE_ERROR_MESSAGE', false);
    }

    setTimeout(() => {
      commit('UPDATE_ERROR_MESSAGE', message);
      commit('TOGGLE_ERROR_MESSAGE', true);
    }, 50);
  },
  hideErrorMessage({ commit }) {
    commit('UPDATE_ERROR_MESSAGE');
    commit('TOGGLE_ERROR_MESSAGE', false);
  },
  // there are two type of blocked keyword, totally blocked and age restricted
  handleBlockedKeyword({ commit, state, dispatch }, blockedKeywordMeta) {
    if (!blockedKeywordMeta) throw new Error('blocked keyword meta doesnt exist');

    const path = get(state, 'productSearch.urlObj.path', '');
    const isSlashS = path.startsWith('/products/s');
    const isBlocked = get(blockedKeywordMeta, 'blocked', false);
    const type = get(blockedKeywordMeta, 'type', '');
    const isForbiddenKeyword = type === 'block';
    const isAgeRestrictedKeyword = type === 'age_restriction';
    const isBrowser = typeof window !== 'undefined';
    const isNeedToBlock = !isSlashS && isBlocked && isForbiddenKeyword;
    // slash /s is excluded due to SEO concern

    commit('UPDATE_VARIABLE', { label: 'isForbiddenKeyword', value: isNeedToBlock });

    if (isAgeRestrictedKeyword && isBrowser) {
      const isHasBeenAccepted = !!cacheUtility.get(AGE_RESTRICTED_KEYWORD_CACHE_KEY, { useLocalStorage: true });

      if (isHasBeenAccepted) return;
      // there are two posibble condition that triggers the pop-up
      // first, when there's mouse movement
      // second, when it idle for two seconds
      // which one come first
      const mouseListener = () => showPopup();
      const DELAY = 2000; // ms
      const showPopup = () => {
        dispatch('wait/start', RESTRICTED_KEYWORD, { root: true });
        // cleaning up
        document.removeEventListener('mousemove', mouseListener, false);
      };

      document.addEventListener('mousemove', mouseListener, false);

      setTimeout(() => {
        showPopup();
      }, DELAY);
    }
  },
  async retrieveSpecialCampaigns({ commit }) {
    const { data } = await api.getSpecialCampaigns();
    commit('UPDATE_VARIABLE', { label: 'specialCampaigns', value: data });
  },
  retrieveSuperSellerNewBadgeStatus({ dispatch, commit }) {
    dispatch('wait/start', SUPER_SELLER_BADGE_VERSION_FETCHING, { root: true });
    api
      .getToggleStatus('super-seller-rebranding-enabled')
      .then(res => {
        commit('UPDATE_VARIABLE', {
          label: 'isSuperSellerNewBadgeActive',
          value: res.data.status,
        });
      })
      .finally(() => {
        dispatch('wait/end', SUPER_SELLER_BADGE_VERSION_FETCHING, { root: true });
      });
  },
  retrieveUserFavoritedProducts({ getters, commit }) {
    const productIds = getters.products?.map(({ id }) => id) ?? [];
    return api
      .getUserFavoritedProducts(productIds)
      .then(({ data }) => {
        commit('UPDATE_FAVORITED_PRODUCTS', data?.favorited);
      })
      .catch(() => {
        commit('UPDATE_FAVORITED_PRODUCTS', []);
      });
  },
  addFavoritedProduct({ commit, state }, productId) {
    return api
      .addProductToFavorite(productId)
      .then(() => {
        const newFavoritedProducts = [...state.favoritedProducts, productId];
        commit('UPDATE_FAVORITED_PRODUCTS', newFavoritedProducts);
      })
      .catch(() => {
        return Promise.reject('Gagal menambahkan barang ke favorit');
      });
  },
  removeFavoritedProduct({ commit, state }, productId) {
    return api
      .removeProductToFavorite(productId)
      .then(() => {
        const favoritedProducts = state.favoritedProducts.filter(id => id !== productId);
        commit('UPDATE_FAVORITED_PRODUCTS', favoritedProducts);
      })
      .catch(() => {
        return Promise.reject('Gagal menghapus barang dari favorit');
      });
  },
};

export default actions;
