const generatorUtility = {
  getMiscellaneousFilterByType(filterType, filters) {
    const result = {};
    Object.keys(filters).forEach(key => {
      if (filterType && key) {
        result[`${filterType}_filter[${key}]`] = filters[key];
      }
    });

    return result;
  },
  convertBooleanParam(paramName = '', paramValue, booleanParams = []) {
    if (booleanParams.includes(paramName)) {
      return paramValue === '1';
    }

    return paramValue;
  },
  convertApiKeyName(paramName = '', mappingParamNames = {}) {
    return mappingParamNames[paramName] || paramName;
  },
  removeUnnecessaryParam(params = {}, paramsToRemove = []) {
    paramsToRemove.forEach(param => {
      delete params[param];
    });

    delete params.undefined;

    return params;
  },
  decideConditionParamValue(apiParams) {
    const defaultValue = '';
    if (apiParams.new && apiParams.used) {
      return defaultValue;
    }

    if (apiParams.new) {
      return 'new';
    }

    if (apiParams.used) {
      return 'used';
    }

    return defaultValue;
  },
  generatePriceRange({ price_min: priceMin, price_max: priceMax } = {}) {
    if (priceMin && priceMax) {
      return `${priceMin}:${priceMax}`;
    }

    if (priceMin) {
      return `${priceMin}:`;
    }

    if (priceMax) {
      return `:${priceMax}`;
    }

    return ':';
  },
  generateRating({ rating_gte, rating_lte } = {}) {
    const min = 0;
    const max = 5;

    if (rating_gte && rating_lte) {
      return `${rating_gte}:${rating_lte}`;
    }

    if (rating_gte) {
      return `${rating_gte}:${max}`;
    }

    if (rating_lte) {
      return `${min}:${rating_lte}`;
    }

    return `${min}:${max}`;
  },
};

export default generatorUtility;
