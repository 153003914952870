<template>
  <bl-tabs-mv
    style="overflow: hidden;"
    :tabs="tabs"
    :active-tab-name="activeTabName"
    @update:activeTabName="handleChangeTab"
  >
    <template v-for="tab in tabs">
      <bl-tab-nav-mv :key="tab" :slot="`tab-nav-${tab}`" :data-testid="`tab-nav-${tab}`">
        <bl-flex-container-mv justify-content="space-around">
          <div>{{ tabText[tab] }}</div>
          <bl-icon-av
            v-if="tab === 'price'"
            :icon="chevronTabIcon"
            :color="activeTabName === 'price' ? 'rgb(66, 69, 77)' : 'rgb(129, 134, 143)'"
          />
        </bl-flex-container-mv>
      </bl-tab-nav-mv>
    </template>
  </bl-tabs-mv>
</template>

<script>
import BlIconAv from "@bukalapak/bazaar-visual-next/dist/components/BlIconAv";
import IcoChevronDownMinor from "@bukalapak/bazaar-visual-next/dist/components/IcoChevronDownMinor";
import IcoChevronUpMinor from "@bukalapak/bazaar-visual-next/dist/components/IcoChevronUpMinor";

export default {
  name: 'ProductSortTab',
  components: {
    BlIconAv
  },
  props: {
    currentSelectedSort: {
      type: Object,
      required: true,
    },
    productSortOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        'bestselling',
        'relevance',
        'bestdiscount',
        'price'
      ],
      priceTabValue: 'price',
      activeTabName: 'bestselling'
    }
  },
  watch: {
    currentSelectedSort: {
      immediate: true,
      handler(val) {
        if (val) {
          this.syncActiveTabName(val)
        }
      }
    }
  },
  computed: {
    isBestPriceTab() {
      return this.priceTabValue === 'price'
    },
    chevronTabIcon() {
      return this.isBestPriceTab ? IcoChevronUpMinor : IcoChevronDownMinor
    },
    tabText() {
      return this.productSortOptions.reduce((obj, option) => {
        if (option.apiValue === 'price') {
          obj['price'] = this.isBestPriceTab ? 'Termurah' : 'Termahal'
        } else if (option.apiValue === 'bestdiscount') {
          obj['bestdiscount'] = 'Diskon'
        } else {
          obj[option.apiValue] = option.text
        }
        return obj
      }, {})
    },
    sortOption() {
      return this.productSortOptions.reduce((obj, option) => {
        obj[option.apiValue] = option
        return obj
      }, {})
    }
  },
  methods: {
    handleChangeTab(tab) {
      if (tab === 'price' && this.activeTabName === 'price') {
        this.priceTabValue = this.isBestPriceTab ? '-price' : 'price'
        this.$emit('change-tab', this.sortOption[this.priceTabValue])
      } else if (tab !== this.activeTabName) {
        this.$emit('change-tab', this.sortOption[tab])
      }
      this.activeTabName = tab
    },
    syncActiveTabName() {
      this.activeTabName = this.currentSelectedSort.apiValue || 'bestselling'
      if (this.currentSelectedSort.apiValue.includes('price')) {
        this.priceTabValue = this.currentSelectedSort.apiValue
        this.activeTabName = 'price'
      }
    }
  }
}
</script>
<style lang="scss">
.bl-tab__action--border {
  background: #2b4ac7 !important
}
</style>
