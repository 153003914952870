<template>
  <div class="d-filter-category">
    <template v-if="selectedCategory.length">
      <bl-cell-mv
        @click="handlePrevious"
        hasBorder="top"
        :text="labelCategories.parent"
      >
        <ico-chevron-right-minor slot="iconLeft" />
      </bl-cell-mv>
    </template>
    <template v-else="">
      <bl-search-section-mv
        v-model="searchCategoryVal"
        placeholder="Cari kategori..."
        cancelText="Cancel"
        @cancelClick="searchCategoryVal = ''"
      />
    </template>
    <template v-if="searchCategoryVal">
      <div
        v-for="search in searchList"
        :key="search.id"
        class="bl-cell"
        style="padding-bottom: 8px;"
        @click="handleItemClick(search)"
      >
        <div class="bl-cell__title">
          <span
            class="bl-text"
            v-html="categoryLinkText(search)"
          />
        </div>
      </div>
    </template>
    <template v-else="">
      <bl-cell-mv
        @click="handleAllClick"
        hasBorder="top"
        :text="labelCategories.existing"
        :style="selectedCategory.length ? {'font-weight': '600'} : {}"
      />
      <div>
        <div
          v-for="category in listCategories"
          :key="category.id"
          class="d-category-item"
        >
          <bl-cell-mv
            v-if="category.children && category.children.length > 0"
            hasBorder="top"
            :data-test-id="'ut-item-text-' + category.id"
            :text="categoryLinkText(category)"
            :style="category.id === parseInt(currentCategoryId, 10) ? {'font-weight': '600'} : {}"
            @click="handleItemClick(category)"
          >
            <ico-chevron-right-minor slot="iconRight" />
          </bl-cell-mv>
          <bl-cell-mv
            v-else=""
            hasBorder="top"
            :data-test-id="'ut-item-text-' + category.id"
            :text="categoryLinkText(category)"
            :style="category.id === parseInt(currentCategoryId, 10) ? {'font-weight': '600'} : {}"
            @click="handleItemClick(category)"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
export default {
  name: 'FilterCategory',
  components: {IcoChevronRightMinor},
  props: {
    prefix: {
      type: String,
      default: '/c/',
    },
    categories: {
      type: Array,
      required: true,
    },
    currentCategoryId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryAll: {},
      selectedCategory: [],
      searchCategoryVal: '',
      listCategories: this.categories,
      labelCategories: {
        parent: 'Kembali ke Semua Kategori',
        existing: 'Semua Kategori',
      },
      searchList: [],
    }
  },
  watch: {
    selectedCategory() {
      let arr = this.categories;
      let parents = {};
      if (this.selectedCategory.length) {
        for (let i = 0; i <= this.selectedCategory.length - 1; i++) {
          const childs = arr.find(c => c.id === this.selectedCategory[i]);
          if (childs.children && childs.children.length) {
            arr = childs.children;
            this.labelCategories.existing = `Semua ${childs.name} (${childs.count})`;
            this.categoryAll = childs;
          }
          if (i < this.selectedCategory.length - 1) {
            parents = childs;
          }
        }
      } else {
        this.labelCategories.existing = 'Semua Kategori';
      }
      this.listCategories = arr;
      this.labelCategories.parent = parents.name ? `Kembali ke Semua ${parents.name}` : 'Kembali ke Semua Kategori';
    },
    searchCategoryVal() {
      const keyword = this.searchCategoryVal;
      if (keyword && keyword.trim() !== '') {
        this.searchList = this.searchKeywordList(this.categories, this.searchCategoryVal);
      } else {
        this.searchList = [];
      }
    },
  },
  mounted() {
    if (this.currentCategoryId) {
      this.selectedCategory = this.currentSelectedArr(this.categories, this.currentCategoryId);
    }
  },
  methods: {
    currentSelectedArr(arr, id) {
      let results = this.findActiveCategory(arr, parseInt(id, 10));
      const lastId = results[results.length - 1];
      if (!this.categoryHasChildren(this.categories, lastId)) {
        results.pop();
      };
      return results;
    },
    findActiveCategory(arr, id) {
      let temp = [];
      let ids = [];
      for (let cat of arr) {
        temp = [];
        if (cat.id === id) {
          ids.push(cat.id);
          break;
        }
        if (cat.children && cat.children.length > 0) {
          const deep = this.findActiveCategory(cat.children, id);
          if (deep.length) {
            temp.push(cat.id);
            ids = temp.concat(deep);
          }
        }
      }
      return ids;
    },
    categoryHasChildren(arr, id) {
      let childs = false;
      let temp = false;
      for (let cat of arr) {
        temp = false;
        if ((cat.id === id) && (cat.children && cat.children.length > 0)) {
          childs = true;
          break;
        }
        if (cat.children && cat.children.length > 0) {
          temp = this.categoryHasChildren(cat.children, id);
          if (temp) {
            childs = temp;
          }
        }
      }
      return childs;
    },
    searchKeywordList(entries, keyword, parent = '') {
      let results = [];
      const parentStr = parent !== '' ? ` / ${parent}` : '';
      entries.forEach(entry => {
        if (entry.name.toLowerCase().includes(keyword.trim().toLowerCase())) {
          const re = new RegExp(keyword, 'gi');
          const txt = entry.name.replace(re, `<b>${keyword}</b>`);
          results.push({
            id: entry.id,
            name: parentStr !== '' ? `${txt}${parentStr}` : txt,
            count: entry.count,
            permalink: entry.permalink
          });
        }
        results.push(...this.searchKeywordList(entry.children, keyword, `${entry.name}${parentStr}`));
      });
      return results;
    },
    categoryLinkText(obj) {
      return parseInt(obj.count, 10) > 0 ? `${obj.name} (${obj.count})` : obj.name;
    },
    handleAllClick() {
      if (this.categoryAll) {
        this.$emit('select', this.selectedItem(this.categoryAll));
      } else {
        this.$emit('select', []);
      }

    },
    handleItemClick(obj) {
      if (obj.children && obj.children.length > 0) {
        this.selectedCategory.push(obj.id);
      } else {
        this.$emit('select', this.selectedItem(obj));
      }
    },
    selectedItem(obj) {
      let item = [
        {
          label: 'search[category_id]',
          value: obj.id || '',
          type: 'params',
        },
        {
          label: 'search[category_name]',
          value: obj.name || '',
          type: 'params',
        },
      ];

      const isCategoryAll = Object.keys(obj).length === 0;

      if (isCategoryAll && this.prefix.includes('/promo/')) {
        const prefix = this.prefix;
        item = [
          {
            label: 'search[category_slug]',
            value: prefix,
            type: 'params',
          },
          ...item,
        ];
      } else {
        item = [
          {
            label: 'search[category_slug]',
            value: obj.permalink ? `${this.prefix}${obj.permalink}` : '/products/',
            type: 'params',
          },
          ...item,
        ];
      }

      return item;
    },
    handlePrevious() {
      this.selectedCategory.pop();
      if (!this.selectedCategory.length) this.categoryAll = {};
    },
  },
};
</script>

<style scoped lang="scss">
  .d-filter-category {
    background: #ffffff;
    .bl-search-section__item {
      margin-right: 0;
    }

    .bl-button__text {
      padding-left: 16px;
    }
  }

  .d-category-item {
    padding: 0 16px 0 28px;

    .bl-cell {
      padding-right: 0;
      padding-left: 0;
    }

    &:first-child {
      padding-left: 16px;

      .bl-cell {
        padding-left: 12px;
      }
    }
  }
</style>
