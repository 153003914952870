import ProductSort from '@/shared/models/sort';
import ProductSearch from '@/shared/models/search';
import { MAX_CACHED_ITEMS, CACHE_EXPIRY_DURATION } from '../../types/cache';

const mutations = {
  UPDATE_PRODUCT_SEARCH_PARAM: (state, { label, value }) => {
    state.productSearch.params[label] = value;
  },

  UPDATE_PRODUCT_SEARCH: (state, { label, value }) => {
    state.productSearch[label] = value;
  },

  RESET_PARAM_VALUE_BY_LABEL: (state, paramLabel) => {
    const initialParams = Object.assign({}, ProductSearch.initialParams());

    Object.keys(initialParams).forEach(param => {
      if (param === `search[${paramLabel}]`) {
        state.productSearch.params[`search[${paramLabel}]`] = initialParams[`search[${paramLabel}]`];
      }
    });
  },

  UPDATE_VARIABLE(state, { label, value }) {
    state[label] = value;
  },

  UPDATE_FILTER_OPTIONS(state, { label, value }) {
    state.filterOptions[label] = value;
  },

  UPDATE_COLLECTION(state, { collectionName, newCollection }) {
    state.collections[collectionName] = newCollection || [];
  },

  UPDATE_PAGINATION(state, { label, value }) {
    state.productSearch.pagination[label] = value;
  },
  REPLACE_PAGINATION(state, pagination = {}) {
    state.productSearch.pagination = Object.assign({}, state.productSearch.pagination, pagination);
  },

  RESET_PRODUCT_SORT(state) {
    const currentKeyword = state.productSearch.params['search[keywords]'];
    const currentDeal = state.productSearch.params['search[todays_deal]'];
    const sortOptions = ProductSort.getSortOptions(currentKeyword, currentDeal);
    // get first option (dynamic)
    const firstSortOption = sortOptions[0];

    state.productSearch.params['search[sort_by]'] = firstSortOption;
  },

  UPDATE_ERROR_STATE(state, value) {
    state.isError = value;
  },

  UPDATE_PRODUCT_SEARCH_PATH(state, value) {
    state.isProductSearchPath = value;
  },

  REPLACE_PRODUCT_SEARCH(state, newProductSearch) {
    state.productSearch = Object.assign({}, state.productSearch, newProductSearch);
  },

  UPDATE_INTERNATIONAL_COURIERS(state, { countries }) {
    // hardcoded since there's only one right now
    const internationalCouriers = ['Janio'];
    let result = [];
    const rename = (courier, country) => `${courier} - ${country.name}`;

    internationalCouriers.forEach(courier => {
      result = result.concat(countries.map(country => rename(courier, country)));
    });

    state.filterOptions.countryDeliveries = countries;
    state.filterOptions.internationalCouriers = result;
  },

  CLEAR_PRODUCT_SEARCH_ATTRIBUTE(state) {
    state.productSearch.attributes = {};
  },

  CLEAR_PRODUCT_SEARCH_VARIANT(state) {
    state.productSearch.variants = {};
  },
  TOGGLE_ERROR_MESSAGE(state, show) {
    state.errorMessage.show = show;
  },
  UPDATE_ERROR_MESSAGE(state, message = false) {
    state.errorMessage.text = typeof message === 'string' ? message : state.errorMessage.defaultText;
  },
  UPDATE_BACK_HISTORY(state, value) {
    state.backHistory = value;
  },
  UPDATE_CACHE(state, { key, value }) {
    // if the cache object exceeds the max cached limit
    // remove the least-recently used item before adding another
    if (Object.keys(state.cache).length >= MAX_CACHED_ITEMS) {
      const lruKey = Object.keys(state.cache)[0];
      delete state.cache[lruKey];
    }

    state.cache[key] = {
      data: value,
      expiredAt: Date.now() + CACHE_EXPIRY_DURATION,
    };
  },
  UPDATE_FAVORITED_PRODUCTS(state, payload = []) {
    state.favoritedProducts = payload;
  },
};

export default mutations;
