import BaseUrlExtractor from './base';

class IndexUrlExtractor extends BaseUrlExtractor {
  constructor(url = document.location.href) {
    super(url);
    this.categoryPathRegex = /^\/c\//;
    this.searchPathRegex = /\/s\//;
    this.promoPathRegex = /^\/promo\/(\w|-)*\/?/;
  }

  extractSearchParams() {
    const params = Object.assign({}, this.urlObj.params);
    const result = {};

    delete params.page;

    const { pathname } = this.urlObj;

    if (this.searchPathRegex.test(pathname)) {
      const lastIndex = pathname.split('/').length - 1;
      // TODO: move this to utility
      const keywords =
        pathname
          .split('/')
          [lastIndex].split('-')
          .join(' ') || '';
      params['search[keywords]'] = decodeURIComponent(keywords);
    }

    Object.keys(params).forEach(paramKey => {
      // avoid null, attribute and variant params
      const isAttributeParam = this.attributeParamRegex.test(paramKey);
      const isVariantParam = this.variantParamRegex.test(paramKey);
      const isSearchParam = this.searchParamRegex.test(paramKey);

      if (paramKey && isSearchParam && !isAttributeParam && !isVariantParam) {
        result[paramKey] = params[paramKey];
      }
    });

    if (this.categoryPathRegex.test(pathname) || this.promoPathRegex.test(pathname)) {
      result['search[category_slug]'] = `${pathname}`;
    }

    // reset sort_by when keyword exists
    if (params['search[keywords]'] && !params['search[sort_by]']) {
      result['search[sort_by]'] = '';
    }

    return result;
  }
}

export default IndexUrlExtractor;
