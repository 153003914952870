const namespace = 'BLProductExplorer';
const cache = {
  set(key, dataObj, { useLocalStorage = false } = {}) {
    const cacheKey = `${namespace}-${key}`;
    const storage = useLocalStorage ? localStorage : sessionStorage;

    return storage.setItem(cacheKey, JSON.stringify(dataObj));
  },
  get(key, { useLocalStorage = false } = {}) {
    const cacheKey = `${namespace}-${key}`;
    const storage = useLocalStorage ? localStorage : sessionStorage;

    return JSON.parse(storage.getItem(cacheKey));
  },
  namespace,
};

export default cache;
