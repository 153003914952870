import api from '@/shared/api';

const initialState = {
  content: {},
};

const mutations = {
  SET_SEARCH_CONTEXT(state, metaSearchContext) {
    if (metaSearchContext) {
      for (var key in metaSearchContext) {
        state.content[key] = metaSearchContext[key];
      }
    }
  },
  SET_EMPTY_SEARCH_CONTEXT(state) {
    state.content = {};
  },
};

const actions = {
  async sendExperimentContext({ state }, { event, platform, pos = null, productType = null }) {
    if (typeof window == 'undefined' || typeof window?.USER == 'undefined') return;
    try {
      let experimentContext = {
        user_id: window.USER.id,
        platform: platform,
        ...state.content,
      };

      if (event === 'visit-product-event')
        experimentContext = { ...experimentContext, pos: pos, product_slot_type: productType };

      const payload = { data: { ...experimentContext } };

      return await api.sendEventToSplitterGus(event, payload);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
};
