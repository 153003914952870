import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

import { NOT_FILTER_PARAMS } from '@/shared/types/productSearch';

const removeUnnecessaryParam = (params = {}, unnecessaryParams = NOT_FILTER_PARAMS) => {
  const result = Object.assign({}, params);

  unnecessaryParams.forEach(param => delete result[`search[${param}]`]);

  return result;
};

const convertParamLabel = ({ label, value }) => {
  return {
    label: `search[${label}]`,
    value,
  };
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
const difference = (object, base) => {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

/**
 * Conver param object to array of object
 * @param {Object} object Object of param
 * @return {Array} array return an array of param object
 */
const convertParamObjectToArray = (params = {}) => {
  return Object.keys(params).map(paramLabel => ({ label: paramLabel, value: params[paramLabel] }));
};

/**
 * Get facets based on category suggestion
 * @param {Array} facets of facets
 * @param {String} suggestionPermalink permalink
 * @return {Object} of suggestion with facet in it
 */
function getFacetBySuggestion(facets = [], suggestion, breadcrumbs = []) {
  for (let facet of facets) {
    if (facet.id === suggestion.id)
      return Object.assign({}, facet, {
        breadcrumbs,
      });
    const facet_ = getFacetBySuggestion(facet.children, suggestion, [...breadcrumbs, facet.name]);
    if (facet_) return facet_;
  }
}

/**
 * convert city param to cities to be able use multiple cities filter
 * https://bukalapak.atlassian.net/wiki/spaces/SRCH/pages/912066095/Migration+city+param+to+cities+param
 * @param {Object} params of product search
 * @return {Object} params of product search
 */
function convertCityParamToCities(params = {}) {
  const paramsCopy = Object.assign({}, params);
  const { 'search[city]': city = '', 'search[cities]': cities = [] } = paramsCopy;

  let cityResult = [...cities];

  if (typeof city === 'string' && city.length > 0 && cities.length === 0) {
    cityResult = [city];
  }

  paramsCopy['search[cities]'] = cityResult;
  delete paramsCopy['search[city]'];

  return paramsCopy;
}

export {
  removeUnnecessaryParam,
  convertParamLabel,
  difference,
  convertParamObjectToArray,
  getFacetBySuggestion,
  convertCityParamToCities,
};
