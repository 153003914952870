const LIMIT_MAX_PAGE_TOGGLE = 'DORA_SEARCH-7096_LIMIT_MAXIMUM_PAGE';
const SEO_MOBILE_SEARCH_TOGGLE = 'DORA_WEBX-3654_SEO_MOBILE_SEARCH';
const CACHE_PRODUCT_RESULTS_TOGGLE = 'DORA_SRC-3273_CACHE_PRODUCT_RESULTS';
const PRODUCTS_RELATED_KEYWORDS_TOGGLE = 'DORA_COWEB-5770_PRODUCTS_RELATED_KEYWORDS';
const BHLMK_RELATED_KEYWORDS_TOGGLE = 'DORA_COWEB-5770_BHLMK_RELATED_KEYWORDS';
const NEW_WEB_PRODUCT_CARD_TOGGLE = 'DORA_SEARCH-9845_WEB_PRODUCT_CARD_NEW';

module.exports = {
  ATF_PRODUCTS_DWEB: 'DORA_CFE-1070_atf_products', // https://tutuplapak.atlassian.net/browse/CFE-1070
  LIMIT_MAX_PAGE_TOGGLE,
  SEO_MOBILE_SEARCH_TOGGLE,
  CACHE_PRODUCT_RESULTS_TOGGLE,
  PRODUCTS_RELATED_KEYWORDS_TOGGLE,
  BHLMK_RELATED_KEYWORDS_TOGGLE,
  NEW_WEB_PRODUCT_CARD_TOGGLE,
};
