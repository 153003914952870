import api from '@/shared/api';
import { RELATED_PRODUCTS_FETCHING } from '@/shared/types/wait';
import { CACHE_PRODUCT_RESULTS_TOGGLE } from '@/shared/types/toggleKeys';

const initialState = {
  products: [],
  keywords: [],
};

const getters = {
  products(state) {
    return state.products;
  },
  keywords(state) {
    return state.keywords;
  },
};

const mutations = {
  ADD_RELATED_PRODUCT(state, { label, value }) {
    state[label].push(value);
  },
  RESET_RELATED(state, { label }) {
    state[label] = [];
  },
  UPDATE_RELATED_PRODUCTS(state, { label, value }) {
    state[label] = value;
  },
};

const actions = {
  async retrieveKeywords({ commit, dispatch, rootState, rootGetters }, payload) {
    const currentKeyword = rootState.bhlm?.keyword || rootState.productSearch.params['search[keywords]'];

    const cacheKey = JSON.stringify({ relatedKeywords: currentKeyword });
    // if cache is found, restore the value from cache and exit immediately
    if (rootGetters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE) && !rootGetters.isCacheExpired(cacheKey)) {
      return commit('UPDATE_RELATED_PRODUCTS', rootGetters.cache(cacheKey));
    }

    // otherwise get a new value, and update the cache
    const cacheValue = await dispatch('retrieveUncachedKeywords', payload);
    commit('UPDATE_RELATED_PRODUCTS', cacheValue);

    if (rootGetters.isToggleActive(CACHE_PRODUCT_RESULTS_TOGGLE)) {
      commit('UPDATE_CACHE', { key: cacheKey, value: cacheValue }, { root: true });
    }
  },
  async retrieveUncachedKeywords({ state, dispatch, rootState }, { isSSR = false } = {}) {
    const currentKeyword = rootState.bhlm?.keyword || rootState.productSearch.params['search[keywords]'];

    if (currentKeyword) {
      try {
        let getRelatedKeywordResponse =
          rootState.mockData && rootState.mockData.allowMocking
            ? await dispatch('mockData/mockRelatedKeywords', null, { root: true })
            : null;

        let keywords;

        if (getRelatedKeywordResponse) {
          keywords = getRelatedKeywordResponse.data;
        } else if (isSSR && state.keywords.length) {
          return { label: 'keywords', value: state.keywords };
        } else {
          const { data: topKeywordsResponse } = await api.getTopKeywordsRelatedPages({keyword: currentKeyword})

          keywords = topKeywordsResponse.map(topKeyword => ({
            title: topKeyword.keyword,
            url: topKeyword.url.replace(/^.*?\.bukalapak\.com/, ''),
            keyword: topKeyword.keyword,
          }));
        }

        return { label: 'keywords', value: keywords };
      } catch (error) {
        return { label: 'keywords', value: [] };
      }
    } else {
      return { label: 'keywords', value: [] };
    }
  },

  async retrieveProducts({ dispatch, commit, rootState }) {
    const currentKeyword = rootState.productSearch.params['search[keywords]'];

    if (currentKeyword) {
      try {
        commit('RESET_RELATED', { label: 'products' });

        dispatch('wait/start', RELATED_PRODUCTS_FETCHING, { root: true });
        const { data } = await api.getRelatedProducts(currentKeyword);
        dispatch('wait/end', RELATED_PRODUCTS_FETCHING, { root: true });

        if (data.length) {
          data.forEach(item => {
            commit('ADD_RELATED_PRODUCT', { label: 'products', value: item });
          });
        }
      } catch (error) {
        // error
      }
    }
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  getters,
  actions,
};
