<template>
  <nav
    v-if="paginationExists"
    class="dp-pagination"
  >
    <ul class="dp-pagination__list">
      <li v-if="paginationItems.prevPage.enabled">
        <a
          role="button"
          class="dp-pagination__previous"
          rel="nofollow"
          @click.prevent="handlePageItemClick(paginationItems.prevPage.pageNumber)"
          :href="paginationItems.prevPage.pageUrl"
          :aria-label="ariaPreviousLabel"
        >
          <ico-drop-down-minor />
        </a>
      </li>
      <template v-for="page in paginationItems.pages">
        <li
          v-if="page.gap"
          :key="page.id"
        >
          <span class="dp-pagination__ellipsis">&hellip;</span>
        </li>
        <li
          v-else-if="page.current"
          :key="page.id"
          class="has-page-active"
        >
          <span
            class="dp-pagination__link is-current"
            aria-current="true"
            :aria-label="page.ariaLabel"
          >
            {{ page.pageNumber }}
          </span>
        </li>
        <li
          v-else
          :key="page.id"
        >
          <a
            class="dp-pagination__link"
            rel="nofollow"
            :class="{'is-disabled': !page.pageUrl}"
            :href="page.pageUrl"
            :aria-label="page.ariaLabel"
            @click.prevent="page.pageUrl && handlePageItemClick(page.pageNumber)"
          >
            {{ page.pageNumber }}
          </a>
        </li>
      </template>
      <li v-if="paginationItems.nextPage.enabled">
        <a
          role="button"
          class="dp-pagination__next"
          rel="nofollow"
          @click.prevent="handlePageItemClick(paginationItems.nextPage.pageNumber)"
          :href="paginationItems.nextPage.pageUrl"
          :aria-label="ariaNextLabel"
        >
          <ico-drop-down-minor />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import IcoDropDownMinor from "@bukalapak/bazaar-visual-next/src/components/IcoDropDownMinor";
export default {
  name: 'ProductPagination',
  components: {IcoDropDownMinor},
  props: {
    /**
     * Pagination Config
     * `return {
          currentPage: 1,
          limit: 10,
          offset: 0,
          total: 30,
          totalPages: 0,
        }`
     */
    pagination: {
      type: Object,
      default:() => {
        return {
          currentPage: 1,
          limit: 10,
          offset: 0,
          total: 30,
          totalPages: 0,
        }
      },
    },
    /**
     * Url Parameter
     */
    urlParams: {
      type: String,
      default: '',
    },
    /**
     * Pagination items to show after & before current page
     */
    paginationWindow: {
      type: Number,
      default: 1,
    },
    /**
     * Whether the last page is disabled
     * `true , false`
     */
    disableLastPage: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the pagination is multi strategy
     * `true , false`
     */
    isMultiStrategy: {
      type: Boolean,
      default: false,
    },
    /**
     * Accessibility label for the next page link.
     */
    ariaNextLabel: {
      type: String,
      default: ''
    },
    /**
     * Accessibility label for the previous page link.
     */
    ariaPreviousLabel: {
      type: String,
      default: ''
    },
    /**
     * Accessibility label for the page link. If passed, this text will be prepended to the number of the page.
     */
    ariaPageLabel: {
      type: String,
      default: 'page'
    },
  },

  computed: {
    urlParamSerialized() {
      return this.urlParams ? `&${this.urlParams}` : ''
    },
    paginationExists() {
      return Math.ceil(this.pagination.total / this.pagination.limit) >= 2
    },
    paginationItems /* istanbul ignore next */ () {
      const limit = this.pagination.limit
      const currentPage = this.pagination.currentPage
      const totalPages = this.isMultiStrategy
        ? this.pagination.totalPages
        : Math.ceil(this.pagination.total / limit)
      const pages = []
      const prevPage = {}
      const nextPage = {}

      if (totalPages >= 2) {
        const lowerBound = Math.max(1, currentPage - this.paginationWindow)
        const upperBound = Math.min(
          totalPages,
          currentPage + this.paginationWindow,
        )
        let lastPageNumber = 0

        for (
          let count = 0;
          count < this.paginationWindow * 2 + 1;
          count += 1
        ) {
          const pageNumber = lowerBound + count

          if (pageNumber <= totalPages) {
            lastPageNumber = pageNumber
            pages[count] = {
              pageNumber,
              pageUrl: `?page=${pageNumber}${this.urlParamSerialized}`,
              current: currentPage === pageNumber,
              ariaLabel: `${this.ariaPageLabel} ${pageNumber}`
            }
          }
        }

        if (lowerBound >= 2) {
          const firstPage = {
            pageNumber: 1,
            pageUrl: `?page=1${this.urlParamSerialized}`,
            current: false,
          }

          if (lowerBound > 2) {
            const pageGap = {
              gap: true,
            }

            pages.unshift(pageGap)
          }

          pages.unshift(firstPage)
        }

        if (upperBound <= totalPages - 1 && lastPageNumber < totalPages) {
          const lastPage = {
            pageNumber: totalPages,
            pageUrl: this.disableLastPage
              ? ''
              : `?page=${totalPages}${this.urlParamSerialized}`,
            current: false,
          }

          if (upperBound < totalPages - 1 && lastPageNumber < totalPages) {
            const pageGap = {
              gap: true,
            }

            pages.push(pageGap)
          }

          pages.push(lastPage)
        }

        if (currentPage > 1) {
          prevPage.enabled = true
          prevPage.pageUrl = `?page=${currentPage - 1}${this.urlParamSerialized}`
          prevPage.pageNumber = currentPage - 1
        }

        if (currentPage < totalPages) {
          nextPage.enabled = true
          nextPage.pageUrl = `?page=${currentPage + 1}${this.urlParamSerialized}`
          nextPage.pageNumber = currentPage + 1
        }
      }

      return {
        prevPage,
        pages,
        nextPage,
      }
    },
  },

  methods: {
    /**
     * Event click after user clicked.
     *
     * @type function
     * @event pageChange
     */
    handlePageItemClick(newPage) {
      this.$emit('pageChange', newPage)
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@bukalapak/bazaar-token/dist/tokens.scss";

  $pagination-border-color-active: $navy-50;
  $pagination-width-size: 33px;

  .dp-pagination {
    display: flex;
    padding-top: 16px;
    padding-bottom: 32px;
    margin: 0;
    text-align: center;
    align-items: center;
    justify-content: center;

    &__list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      align-items: center;

      li {
        padding: 0;
        margin: 0;

        &.has-page-active {
          z-index: 1;
        }
      }
    }

    &__next,
    &__previous,
    &__link,
    &__ellipsis {
      display: flex;
      min-width: $pagination-width-size + 2;
      padding: 0 0.5em;
      margin: -1px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #42454d;
      transition: color $duration-short ease-in-out;
    }

    &__link {
      &.is-current {
        color: $color-text-interactive;
        cursor: default;
      }

      &:focus {
        color: inherit;
        outline: $pagination-border-color-active solid 2px;
        outline-offset: -2px;
      }

      &.is-disabled {
        pointer-events: none;
        cursor: default;
      }
    }

    &__next,
    &__previous {
      width: $pagination-width-size;
      padding: 0;

      svg {
        margin-top: -1px;
      }

      &[disabled] {
        display: none;
      }
    }

    &__next {
      svg {
        transform: rotate(-90deg);
      }
    }

    &__previous {
      svg {
        transform: rotate(90deg);
      }
    }
  }
</style>
