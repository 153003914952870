const wrapSecureClientInterface = (interfaces = {}) => {
  // get secureClient & metric set in dataFetch fragment-hook
  const requestManager = global.SECURE_CLIENT;
  const metric = global.METRIC;
  const additionalLogInfo = global.additionalLogInfo || {};

  const wrappedInterfaces = {};

  if (requestManager) {
    Object.keys(interfaces).forEach(key => {
      wrappedInterfaces[key] = async (...args) => {
        const startTime = new Date().getTime();
        additionalLogInfo[`${key}-start`] = startTime - global.startTime;

        let status = 'ok';
        let code = '2'; // status code: 2xx

        try {
          return await interfaces[key].bind(requestManager)(...args);
        } catch (e) {
          const statusCode = e.response ? e.response.status : 500;
          status = e.response ? (statusCode === 404 ? 'ok' : 'fail') : 'timeout';
          code = status <= 500 ? '4' : '5';

          console.error(
            JSON.stringify({
              type: 'api',
              duration: (new Date().getTime() - startTime) / 1000,
              level: 'error',
              severity: 'ERROR',
              message: e.message,
              stack: e.stack,
              tags: [key, 'error-api'],
              status: statusCode,
              param: args[0],
            }),
          );

          return Promise.reject(e);
        } finally {
          //sending metric to dataDog
          if (metric) {
            const duration = new Date().getTime() - startTime;
            additionalLogInfo[`${key}-end`] = new Date().getTime() - global.startTime;

            metric.push([
              {
                method: 'histogram',
                name: 'service_latency_seconds_api',
                value: duration / 1000,
                tags: [
                  `action:${key}`,
                  `status:${status}`,
                  `code:${code}xx`,
                  `speed:${duration > 100 ? 'slow' : 'fast'}`,
                ],
              },
            ]);
          }
        }
      };
    });

    wrappedInterfaces.abortRequest = requestManager.abortRequest.bind(requestManager);
    wrappedInterfaces.refreshToken = () => Promise.resolve({});
  }

  return wrappedInterfaces;
};

export default wrapSecureClientInterface;
