<template>
  <div class="d-filter-card" style="padding:10px 16px;">
    <bl-text-av variation="caption-12" weight="medium" style="margin:6px 0 16px;">Rentang Harga</bl-text-av>
    <bl-flex-container-mv margin="0 0 12px 0">
      <div>
        <bl-textfield-av
          v-model="minLocal"
          is-small
          type="number"
          placeholder="Minimal"
          class="te-filter-price-min"
          :class="{ 'is-error': !!inputErrorMin }"
          :message="inputErrorMin || {}"
          @focus="onFocus = $options.PRICE_MIN"
          @blur="onFocus = null"
          @input="value => handleChange(value, $options.PRICE_MIN)"
        />
        <bl-text-av v-if="inputErrorMin" variation="caption-12" style="margin-top:4px; color:#cc5e57">
          {{ inputErrorMin.message }}
        </bl-text-av>
      </div>
      <span style="padding:12px;">-</span>
      <div>
        <bl-textfield-av
          v-model="maxLocal"
          is-small
          type="number"
          placeholder="Maksimal"
          class="te-filter-price-max"
          :class="{ 'is-error': !!inputErrorMax }"
          :message="inputErrorMax || {}"
          @focus="onFocus = $options.PRICE_MAX"
          @blur="onFocus = null"
          @input="value => handleChange(value, $options.PRICE_MAX)"
        />
        <bl-text-av v-if="inputErrorMax" variation="caption-12" style="margin-top:4px; color:#cc5e57">
          {{ inputErrorMax.message }}
        </bl-text-av>
      </div>
    </bl-flex-container-mv>
    <slot />
  </div>
</template>

<script>
import numberHelper from '@bukalapak/toolbox-helper/number-helper';
import debounce from 'lodash/debounce';

const PRICE_MIN = 'price_min';
const PRICE_MAX = 'price_max';
const { delimiter } = numberHelper;

export default {
  name: 'FilterPriceRange',
  PRICE_MIN,
  PRICE_MAX,
  props: {
    priceRange: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  delimiter,
  data() {
    return {
      minLocal: null,
      maxLocal: null,
      onFocus: null,
    };
  },
  computed: {
    inputErrorMin() {
      if (this.onFocus !== PRICE_MAX && Math.sign(this.maxLocal) > 0 && this.maxLocal < this.minLocal) {
        return {
          message: 'Melebihi batas maksimal',
        };
      }
      return null;
    },
    inputErrorMax() {
      if (this.onFocus !== PRICE_MIN && this.maxLocal && this.minLocal > this.maxLocal) {
        return {
          message: 'Kurang dari batas minimal',
        };
      }
      return null;
    },
  },
  watch: {
    priceRange({ min = 0, max = 0 } = {}) {
      // update the local variable if value is different
      if (Number(min) !== Number(this.minLocal)) {
        this.minLocal = min;
      }
      if (Number(max) !== Number(this.maxLocal)) {
        this.maxLocal = max;
      }
    },
    isReset(value) {
      if (value) this.setInitialValues();
    },
  },
  created() {
    this.handleChange = debounce((value, type) => {
      let numStr = String(value);
      numStr = parseInt(numStr.replace(/\D/g, ''));

      if (type === PRICE_MIN) {
        this.minLocal = isNaN(numStr) || numStr === 0 ? '' : numStr;
        if (this.maxLocal && numStr > this.maxLocal) return;
      }

      if (type === PRICE_MAX) {
        this.maxLocal = isNaN(numStr) || numStr === 0 ? '' : numStr;
        if (this.maxLocal && numStr < this.minLocal) return;
      }

      this.updateFilter(PRICE_MIN, this.minLocal);
      this.updateFilter(PRICE_MAX, this.maxLocal);
    }, 1000);

    this.setInitialValues();
  },
  methods: {
    setInitialValues() {
      this.minLocal = this.priceRange.min ? Number(this.priceRange.min) : '';
      this.maxLocal = this.priceRange.max ? Number(this.priceRange.max) : '';
    },
    updateFilter(label, value) {
      this.$emit('updated', label, value);
    },
  },
};
</script>
