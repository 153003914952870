<template>
  <div>
    <bl-button-av ref="toggleBtn" variation="naked" style="padding: 0 8px;" @click="sheetProductSorter = true">
      <div slot="icon" class="btn-sort-icon">
        <ico-sort-minor />
        <ico-check-circle v-if="!isDefault" color="rgb(70, 196, 144)" class="d-tick-badge" />
      </div>
      <bl-text-av class="btn-sort-text" :ellipsis="1">{{ currentSortText }}</bl-text-av>
    </bl-button-av>

    <bl-sheet-mv v-if="isMounted" :active.sync="sheetProductSorter" title="Urutkan" auto-height>
      <bl-flex-container-mv v-if="isRendered" slot="content" direction="column">
        <bl-cell-mv
          v-for="(sort, key) in productSortOptions"
          :key="key"
          :text="sort.text"
          @click="handleSortChange(sort)"
        >
          <ico-ui-tick v-if="currentSelectedSort.text === sort.text" slot="iconRight" />
        </bl-cell-mv>
      </bl-flex-container-mv>
    </bl-sheet-mv>
  </div>
</template>

<script>
import IcoSortMinor from '@bukalapak/bazaar-visual-next/src/components/IcoSortMinor';
import IcoCheckCircle from '@bukalapak/bazaar-visual-next/src/components/IcoCheckCircle';
import IcoUiTick from '@bukalapak/bazaar-visual-next/src/components/IcoUiTick';
const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(
    m => m.default || m,
  );

export default {
  name: 'ProductSorter',

  components: {
    IcoUiTick,
    IcoCheckCircle,
    IcoSortMinor,
    BlSheetMv,
  },

  props: {
    currentSelectedSort: {
      type: Object,
      required: true,
    },
    productSortOptions: {
      type: Array,
      required: true,
    },
    isOverlayActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: false,
      isRendered: false,
      currentSortText: this.currentSelectedSort.value ? this.currentSelectedSort.text : 'Urutkan',
      sheetProductSorter: false,
    };
  },

  computed: {
    isDefault() {
      return !this.currentSelectedSort.value;
    },
  },

  watch: {
    sheetProductSorter(val) {
      if (val) {
        this.isRendered = true;
      }
      this.$emit('show', this.sheetProductSorter);
    },
  },

  mounted() {
    this.isMounted = true;

    if (this.isOverlayActive) {
      this.sheetProductSorter = true;
    }
  },

  methods: {
    handleSortChange(sortOption) {
      this.currentSortText = sortOption.text;
      this.$emit('sort-change', sortOption);

      this.$nextTick(() => {
        this.sheetProductSorter = false;
      });
    },
  },
};
</script>
