<template>
  <div>
    <bl-checkbox-av
      :value="isCheckedVal"
      @input="toggle"
    >
      <bl-text-av tag="span" variation="caption-12">
        <slot />
      </bl-text-av>
    </bl-checkbox-av>
  </div>
</template>

<script>
export default {
  name: 'FilterCheckbox',
  props: {
    paramName: {
      type: String,
      required: true,
    },
    currentParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    initialParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
    trueValue: {
      type: [String, Boolean],
      default() {
        return this.$PARAM_TRUE_VALUE;
      },
    },
    falseValue: {
      type: [String, Boolean],
      default() {
        return this.$PARAM_FALSE_VALUE;
      },
    },
  },
  data() {
    return {
      isChecked: '',
    }
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.isChecked = this.initialParamValue === this.trueValue;
      }
    },
  },
  computed: {
    isCheckedVal() {
      return this.isChecked;
    },
  },
  mounted() {
    this.isChecked = this.currentParamValue === this.trueValue
  },
  methods: {
    toggle(val) {
      if (typeof val === 'boolean') {
        this.isChecked = val;
        this.$emit('updated', this.paramName, this.isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
$component-name: '.bl-checkbox';
#{$component-name} {
  &--filter-promo-page {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    .bl-checkbox__label {
      padding-left: 0;
    }
  }
}
</style>
