<template>
  <bl-flex-container-mv
    v-if="isShow"
    align-items="center"
    justify-content="space-between"
    tag="section"
    class="bukagames-instant-buy m-8 ph-20 pv-16"
  >
    <div class="bukagames-instant-buy__info-wrapper">
      <bl-text-av tag="h2" variation="caption-12" weight="bold" color="inverse" class="bukagames-instant-buy__heading">
        BELI INSTAN
      </bl-text-av>

      <bl-text-av tag="h3" variation="caption-10" color="inverse" class="bukagames-instant-buy__sub-heading">
        Cepat diproses, harga terbaik!
      </bl-text-av>

      <bl-flex-container-mv
        align-items="center"
        justify-content="space-between"
        class="bukagames-instant-buy__games-wrapper"
      >
        <div class="bukagames-instant-buy__game-box ph-6 pv-2">
          <img src="https://s3.bukalapak.com/attachment/873232/higgs-domino-logo.png" alt="instant-buy-higgs-domino" />
        </div>
        <div class="bukagames-instant-buy__game-box ph-6 pv-2">
          <img
            src="https://s0.bukalapak.com/attachment/573232/mobile-legends-logo.png"
            alt="instant-buy-higgs-domino"
          />
        </div>
        <bl-text-av tag="p" variation="caption-08" weight="medium" color="inverse" class="bukagames-instant-buy__text">
          +40 game lainnya
        </bl-text-av>
      </bl-flex-container-mv>
    </div>

    <div class="instant-buy-type__action-wrapper">
      <bl-button-av class="bukagames-instant-buy__buy-button" size="small" href="/bukagames/voucher-game">
        Pilih game
      </bl-button-av>
    </div>
  </bl-flex-container-mv>
</template>

<script>
export default {
  name: 'BukaGamesBanner',
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isPWABukaGames: false,
    };
  },
  computed: {
    isDigitalProductsExist() {
      return this.products.some(product => product.digital_product);
    },
    isShow() {
      return this.isPWABukaGames && this.isDigitalProductsExist;
    },
  },
  mounted() {
    this.getPWAGamesState();
  },
  methods: {
    getPWAGamesState() {
      this.isPWABukaGames =
        typeof window !== 'undefined' && window.sessionStorage?.getItem('isPWABukaGames') === 'true';
    },
  },
};
</script>

<style lang="scss" scoped>
.bukagames-instant-buy {
  height: 88px;
  background: #3838a8;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 157px;
    height: 100%;
    background: #1c3391;
    border-radius: 4px;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  }

  &__ {
    &heading {
      margin-bottom: 2px;
    }

    &info-wrapper {
      color: white;
      z-index: 1;
    }

    &games-wrapper {
      margin-top: 6px;
    }

    &game-box {
      background: white;
      border-radius: 4px;
      height: 20px;
      width: 40px;
      padding: 2px 6px;
      box-sizing: border-box;
      margin-right: 6px;

      img {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        object-fit: contain;
      }
    }
  }
}
</style>
