const slugToTitleCase = str => {
  if (typeof str !== 'string') return '';

  return str
    .split('-')
    .join(' ')
    .replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

module.exports = { slugToTitleCase };
