<template>
  <div class="d-quick-filter">
    <div class="d-quick-filter-inner">
      <bl-text-av
        variation="caption-10"
        color="subdued"
        class="d-total-text"
      >
        <span>{{ totalProducts | formatTotal }} Produk</span>
      </bl-text-av>
      <bl-button-av
        v-for="(option, idx) in options"
        :key="`quick-filter-${idx}`"
        :class="{ active: option.active, hide: option.hide }"
        size="small"
        :variation="option.active ? 'outline-white' : 'outline'"
        @click="updated(option)"
      >
        {{ option.text }}
      </bl-button-av>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'external/Vuex';
import numberHelper from '@bukalapak/toolbox-helper/number-helper';

export default {
  name: 'ProductQuickFilter',
  filters: {
    formatTotal(total) {
      return numberHelper.delimiter(total);
    },
  },
  props: {
    totalProducts: {
      type: Number,
      required: true,
    },
    productSearch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      items: [
        {
          label: 'top_seller',
          text: 'Super Seller',
          trueValue: '1',
          falseValue: '0',
          active: false,
          hide: false,
        },
        {
          label: 'brand',
          text: 'BukaMall',
          trueValue: '1',
          falseValue: '0',
          active: false,
          hide: false,
        },
        {
          label: 'courier',
          text: 'Kurir Instan',
          trueValue: true,
          falseValue: [],
          active: false,
          hide: false,
        },
        {
          label: 'rating_gte',
          text: 'Rating 4 Ke Atas',
          trueValue: '4',
          falseValue: null,
          active: false,
          hide: false,
        },
        {
          label: 'used',
          text: 'Bekas',
          trueValue: '1',
          falseValue: '0',
          active: false,
          hide: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['bhlm', 'oneDayCouriers']),
    options() {
      return this.items
        .map(item => {
          if (item.label === 'used' && this.isBHLMKeyExist('b')) {
            item.hide = true;
          }

          if (item.label === 'courier') {
            item.active = this.isOneDayCourierSelected();
          } else {
            item.active = String(this.paramValue(item.label)) === item.trueValue;
          }

          return item;
        })
        .sort((a, b) => b.active - a.active);
    },
  },
  methods: {
    isBHLMKeyExist(key) {
      return this.bhlm && this.bhlm.params && this.bhlm.params[key];
    },
    paramValue(label) {
      return this.productSearch.params[`search[${label}]`];
    },
    isOneDayCourierSelected() {
      if (!this.oneDayCouriers || this.oneDayCouriers.length === 0) return false;

      return isEqual(
        this.paramValue('courier')
          .slice()
          .sort(),
        this.oneDayCouriers.slice().sort(),
      );
    },
    updated(item) {
      this.$emit('selected', [
        {
          label: `search[${item.label}]`,
          value: item.active ? item.falseValue : item.label === 'courier' ? this.oneDayCouriers : item.trueValue,
          type: 'params',
        },
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
.d-quick-filter {
  height: 36px;
  margin-bottom: 4px;
  overflow: hidden;

  &-inner {
    padding: 2px 0 21px 10px;
    overflow-x: scroll;
    white-space: nowrap;
  }

  button {
    display: inline-block;
    height: 32px;
    padding-right: 12px;
    padding-left: 12px;
    margin: 0 10px 0 0;
    font-size: 12px;
    background-color: #fff;

    &.active {
      color: #fff;
      background-color: #4589dc;
      border-color: #4589dc;
    }

    &.hide {
      display: none;
    }
  }
}

.d-total-text {
  display: table;
  float: left;
  width: 1%;
  min-width: 48px;
  height: 32px;
  margin-right: 10px;
  white-space: normal;

  span {
    display: table-cell;
    height: 32px;
    vertical-align: middle;
  }
}
</style>
