import { INITIAL_APP_DATA, INITIAL_SEO_PAGE_TITLE, MOUNT_POINT_ID, PRODUCT_EXPLORER_MODE } from './constants';
import { createApp } from './app';

let seoPageTitle = '';
const { Vue, Vuex } = window;

if (window[INITIAL_SEO_PAGE_TITLE]) {
  seoPageTitle = JSON.parse(unescape(window[INITIAL_SEO_PAGE_TITLE]));
}

const { app, store } = createApp({
  Vue,
  Vuex,
  props: {
    initialUrl: document.location.href,
    csrMode: window[PRODUCT_EXPLORER_MODE] === 'csr',
    seoPageTitle,
    isLoggedIn: !!(window.USER && window.USER.id),
  },
  configs: window.__NEO_CONFIGS__,
  toggles: window.__NEO_TOGGLES__,
});

if (window[INITIAL_APP_DATA]) {
  store.replaceState(window[INITIAL_APP_DATA]);
  window.ProductExplorerMobile = app;
}

app.$mount(`#${MOUNT_POINT_ID}`);
