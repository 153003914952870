<template>
  <div class="bl-table--price-table">
    <h2 data-testid="price-table-title">
      Daftar harga {{ currentKeyword }} terbaru {{ '%month% %yyyy%' | humanizeTimestamp }}
    </h2>
    <table>
      <tbody>
        <tr v-for="(product, idx) in products" :key="`product-${product.id}-price-table-${idx}`">
          <td>
            <BlTextAv variation="caption-10" color="subdued" :ellipsis="2" :data-testid="`price-table-product-${idx}`"
              >{{ product.name }}</BlTextAv
            >
          </td>
          <td>{{ product.price | money }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">Data diperbarui pada {{ '%dd%/%mm%/%yyyy%' | humanizeTimestamp }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import dateHelper from '@bukalapak/toolbox-helper/date-helper';
import numberHelper from '@bukalapak/toolbox-helper/number-helper';

const { money } = numberHelper;
const { humanizeTimestamp } = dateHelper;

export default {
  name: 'PriceTable',
  filters: {
    money(amount) {
      return money(amount);
    },
    humanizeTimestamp(format) {
      return humanizeTimestamp(new Date(), format);
    },
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    currentKeyword: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bl-table--price-table {
  border-collapse: collapse;
  border-spacing: 0;

  h2 {
    color: #3d3d3d;
    font-size: 12px;
  }
  th,
  td {
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    vertical-align: top;
    color: #8d8d8d;
  }
  thead {
    th {
      padding: 0 0 12px 0;
      color: #3d3d3d;
    }
  }
  tbody {
    td {
      padding: 6px 0;
      border-bottom: 1px solid #ebebeb;
    }
    td:first-child {
      padding-right: 6px;
    }
    td:last-child {
      width: 100px;
      text-align: right;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }
  tfoot {
    td {
      padding: 12px 0;
      font-weight: 600;
    }
  }
}
</style>
