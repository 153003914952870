<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv
        hasBorder="top"
        class="d-filter-item-active"
        style="padding-left: 0;"
        @click="isShow = true"
        :text="title"
      >
        <bl-badge-av :count="chosenList.length" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv
      v-else
      hasBorder="top"
      :text="title"
      style="padding-left: 0;"
      @click="isShow = true"
    >
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv
      :active.sync="isShow"
      :title="title"
      text-action="Reset"
      @clickAction="reset"
      @close="close"
    >
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding-left:16px;">
          <bl-cell-mv
            v-for="(option, idx) in options"
            :key="idx"
            :hasBorder="idx > 0 ? 'top' : undefined"
            style="padding-left: 0;"
            :text="option.name"
            @click="itemClicked($event, `variant-option-${idx}`)"
          >
            <div slot="iconRight">
              <bl-checkbox-av
                :ref="`variant-option-${idx}`"
                v-model="checkboxesArr"
                :native-value="option.value"
              />
            </div>
          </bl-cell-mv>
        </div>
      </div>
      <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
        <bl-button-av
          variation="primary"
          fullwidth
          style="border-radius: 4px;"
          @click="save"
        >
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import FilterChosen from './FilterChosen.vue';
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(m => m.default || m);

export default {
  name: 'FilterVariants',
  props: {
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    IcoBackAndroid,
    BlSheetMv,
    FilterChosen,
  },
  data() {
    return {
      isShow: false,
      checkboxesArr: [],
      chosenList: [],
      type: 'variants',
    }
  },
  computed: {
    options() {
      return this.variant.options.map(s => ({ name: s, value: s }));
    },
    colorOptions() {
      return this.variant.options.map(o => {
        const color = colorVariantMapping.find(c => c.label.toLowerCase() === o.toLowerCase());
        return {
          ...color,
          name: o,
          value: o,
        };
      });
    },
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.reset();
        this.updateChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.checkboxesArr = this.selected.filter(item => item);
      this.updateChosen();
    },
    updateChosen() {
      if (String(this.checkboxesArr)) {
        this.chosenList = this.checkboxesArr.filter(item => item);
      } else {
        this.chosenList = [];
      }
    },
    removeChosen(idx) {
      this.chosenList.splice(idx, 1);
      this.$emit('updated', { label: this.variant.identifier, value: this.chosenList, type: this.type });
    },
    close() {
      this.initializeValue();
    },
    reset() {
      this.checkboxesArr = [];
      this.$emit('updated', { label: this.variant.identifier, value: this.checkboxesArr, type: this.type });
    },
    save() {
      this.$emit('updated', { label: this.variant.identifier, value: this.checkboxesArr, type: this.type });
      this.updateChosen();
      this.isShow = false;
    },
    itemClicked(event, element) {
      if (event.target && event.target.tagName.toLowerCase() === 'div') {
        this.$refs[element][0].$el.click();
        return false;
      }
    },
  },
};
</script>
