const flattenCourierByCategories = couriersByCategories => {
  return couriersByCategories.reduce((acc, category) => {
    return [...acc, ...category.couriers];
  }, []);
};

const flattenDeepArray = arr => {
  return arr.reduce((r, { children, ...rest }) => {
    r.push(rest);
    if (children) r.push(...flattenDeepArray(children));
    return r;
  }, []);
};

export { flattenCourierByCategories, flattenDeepArray };
