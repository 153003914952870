class productSort {
  /**
   * Generate product sort options based on some product search params
   *
   * @param {string} [keyword=''] Product search keyword param
   * @param {string} [discount=0] Product search discount param
   * @param {Boolean} [isPromoPage=false] is promo page
   * @return {Object} Product sort options
   */
  static getSortOptions(keyword = '', discount = '0', isPromoPage = false, isBhlmkPage = false) {
    const options = [
      { value: 'last_relist_at:desc', apiValue: 'date', text: 'Terbaru' },
      { value: 'price:asc', apiValue: 'price', text: 'Termurah' },
      { value: 'price:desc', apiValue: '-price', text: 'Termahal' },
      { value: 'weekly_sales_ratio:desc', apiValue: 'bestselling', text: 'Terlaris' },
      { value: 'bestratingratio', apiValue: 'bestratingratio', text: 'Rating Tertinggi' },
    ];

    if (keyword || isBhlmkPage) {
      options.unshift({ value: '', apiValue: '', text: 'Relevansi' });
    }

    if (isPromoPage) {
      options.unshift({ value: 'relevance', apiValue: 'relevance', text: 'Pilihan' });
    }

    if (discount === '1') {
      options.push({ value: 'percentage:desc', apiValue: 'bestdiscount', text: 'Diskon Terbesar' });
    } else {
      options.push({ value: 'bestdiscount', apiValue: 'bestdiscount', text: 'Diskon Terbesar' });
    }

    return options;
  }
}

export default productSort;
