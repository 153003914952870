import isEqual from 'lodash/isEqual';
import { removeUnnecessaryParam, difference, convertCityParamToCities } from '@/shared/utilities/productSearchUtility';
import BaseUrlExtractor from './extractors/base';

import { NOT_FILTER_PARAMS } from '@/shared/types/productSearch';

const INITIAL_PAGINATION_LIMIT = 50;

class ProductSearch {
  constructor(urlExtractor = new BaseUrlExtractor(), paginationLimit = INITIAL_PAGINATION_LIMIT) {
    this.params = ProductSearch.initialParams();
    this.pagination = ProductSearch.initialPagination(paginationLimit);
    this.attributes = {};
    this.variants = {};
    this.urlObj = urlExtractor.urlObj;

    const newParams = urlExtractor.extractSearchParams();
    // convert city to cities https://bukalapak.atlassian.net/wiki/spaces/SRCH/pages/912066095/Migration+city+param+to+cities+param

    Object.assign(this.params, convertCityParamToCities(newParams));
    Object.assign(this.attributes, urlExtractor.extractSearchByParamType('attribute'));
    Object.assign(this.variants, urlExtractor.extractSearchByParamType('variant'));

    this.pagination.currentPage = parseInt(urlExtractor.extractPageNumber(), 10);

    if (this.pagination.currentPage > 1) {
      this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.limit;
    }
  }

  static initialParams() {
    return {
      'search[category_id]': '',
      'search[category_slug]': '',
      'search[category_name]': '',
      // 'search[city]': '', // to be deprecated, change to cities
      'search[cities]': [],
      'search[courier]': [],
      'search[installment]': '0',
      'search[keywords]': '',
      'search[new]': '0',
      'search[premium_seller]': '0',
      'search[price_max]': '',
      'search[price_min]': '',
      'search[province]': '',
      'search[rating_gte]': '0',
      'search[rating_lte]': '5',
      'search[sort_by]': '',
      'search[store_id]': '',
      'search[store_username]': '',
      'search[todays_deal]': '0',
      'search[top_seller]': '0',
      'search[brand]': '0',
      'search[used]': '0',
      'search[wholesale]': '0',
      'search[assurance]': '0',
      'search[super_seller_tier]': '',
    };
  }

  static initialPagination(limit = INITIAL_PAGINATION_LIMIT) {
    return {
      currentPage: 1,
      limit,
      offset: 0,
      total: 0,
    };
  }

  static addData(productSearchInstance, dataLabel = 'params', dataValue = {}) {
    const productSearchCopy = JSON.parse(JSON.stringify(productSearchInstance));

    Object.assign(productSearchCopy[dataLabel], dataValue);

    return productSearchCopy;
  }

  static isAnyFilterApplied(currentProductSearch = {}, initialParams = ProductSearch.initialParams()) {
    const cleanCurrentParams = removeUnnecessaryParam(currentProductSearch.params);
    const cleanInitialParams = removeUnnecessaryParam(initialParams);

    const isAnyAttributeFilterApplied =
      'attributes' in currentProductSearch ? Object.keys(currentProductSearch.attributes).length > 0 : false;
    const isAnyVariantFilterApplied =
      'variants' in currentProductSearch ? Object.keys(currentProductSearch.variants).length > 0 : false;

    return !isEqual(cleanCurrentParams, cleanInitialParams) || isAnyAttributeFilterApplied || isAnyVariantFilterApplied;
  }

  static selectedFilters(
    currentProductSearch = {},
    { initialParams = ProductSearch.initialParams(), unnecessaryParams = NOT_FILTER_PARAMS } = {},
  ) {
    const cleanCurrentParams = removeUnnecessaryParam(currentProductSearch.params, unnecessaryParams);
    const cleanInitialParams = removeUnnecessaryParam(initialParams, unnecessaryParams);

    const differences = difference(cleanCurrentParams, cleanInitialParams);
    if (Object.keys(differences).length === 0) return {};

    return differences;
  }
}

export default ProductSearch;
