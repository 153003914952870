function upstreamErrorLogger({ logger, err, tags = '', loggerStatusRule = { '404': 'warn' }, ...data }) {
  if (!logger) return;
  const statusCode = err.response && err.response.status;
  const status = loggerStatusRule[statusCode] || 'error';
  const customTags = typeof tags === 'string' ? [tags] : tags;

  logger[status](err.message, {
    stack: err.stack,
    tags: ['dora_upstream_error', ...customTags],
    ...data,
  });
}

module.exports = { upstreamErrorLogger };
