import IcoBayarditempatMinor from '@bukalapak/bazaar-visual-next/src/components/IcoBayarditempatMinor';
import IcoFeaturedproduct from '@bukalapak/bazaar-visual-next/src/components/IcoFeaturedproduct';
import IcoFreeshipping from '@bukalapak/bazaar-visual-next/src/components/IcoFreeshipping';
import IcoCart from '@bukalapak/bazaar-visual-next/src/components/IcoCart';
import IcoGuaranteeMinor from '@bukalapak/bazaar-visual-next/src/components/IcoGuaranteeMinor';
import IcoPhoneIphone from '@bukalapak/bazaar-visual-next/src/components/IcoPhoneIphone';
import IcoVerified from '@bukalapak/bazaar-visual-next/src/components/IcoVerified';

export const PRODUCT_TAG_ICON = {
  cod: IcoBayarditempatMinor,
  bazar: IcoFeaturedproduct,
  gratis_ongkir: IcoFreeshipping,
  grosir: IcoCart,
  garansi_aman: IcoGuaranteeMinor,
  produk_digital: IcoPhoneIphone,
  tanpa_ongkir: IcoFreeshipping,
  official_merchandise: IcoVerified,
};
