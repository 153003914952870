import ProductSort from '@/shared/models/sort';

const initialState = {
  sortOptions: ProductSort.getSortOptions(),
  currentActiveSort: null,
  showSort: false,
};

const getters = {
  showSort({ showSort }) {
    return showSort;
  },
  isAnySortFilterSelected(localState, localGetters, rootState, rootGetters) {
    const { currentSelectedSort } = localGetters;
    const currentKeyword = rootGetters.getSearchParamValue('search[keywords]');
    // const isDiscount = rootGetters.getSearchParamValue('search[todays_deal]');

    if (currentKeyword) {
      return currentSelectedSort.text !== 'Relevansi';
    }

    if (Object.keys(currentSelectedSort).length === 0) {
      return false;
    }
    // Terbaru is default sort option
    return currentSelectedSort.text !== 'Terbaru';
  },
  currentSelectedSort(localState, localGetters, rootState, rootGetters) {
    const currentKeyword = rootGetters.getSearchParamValue('search[keywords]');
    const isDiscount = rootGetters.getSearchParamValue('search[todays_deal]');
    const options = ProductSort.getSortOptions(
      currentKeyword,
      isDiscount,
      rootGetters.isPromoPage,
      rootGetters.bhlm.page && rootGetters.bhlm.params?.k,
    );
    const currentSort =
      options.find(option => option.value === rootGetters.getSearchParamValue('search[sort_by]')) ||
      options.find(option =>
        rootGetters.isPromoPage || rootGetters.isSortedByBestSelling
          ? option.apiValue === 'bestselling'
          : option.value === 'last_relist_at:desc',
      );
    return currentSort;
  },
};

const mutations = {
  CHANGE_CURRENT_ACTIVE_SORT(state, value) {
    state.currentActiveSort = value;
  },
  TOGGLE_SHOW_SORT(state, reversedValue) {
    state.showSort = reversedValue;
  },
};

const actions = {
  showSortToggle({ commit, state }) {
    commit('TOGGLE_SHOW_SORT', !state.showSort);
  },
  updateCurrentActiveSort({ commit }, payload) {
    commit('CHANGE_CURRENT_ACTIVE_SORT', payload);
  },
  resetSelectedSort({ commit, dispatch, rootGetters }) {
    const initialSortOptions = ProductSort.getSortOptions('', '0', rootGetters.isPromoPage);
    commit('CHANGE_CURRENT_ACTIVE_SORT', initialSortOptions[0]);
    dispatch('resetValueByLabel', 'sort_by', { root: true });
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters,
  mutations,
  actions,
};
