<template>
  <div class="related-keywords m-16">
    <bl-text-av tag="h2" weight="bold" variation="caption-12" class="mb-16">
      {{ label }}
    </bl-text-av>
    <div>
      <bl-link-av
        v-for="keyword in keywords"
        :key="keyword.url"
        :href="keywordUrl(keyword)"
        class="mr-12 mb-8"
        style="display: inline-block"
      >
        <bl-text-av class="bl-text--subdued" tag="span" variation="caption-12">
          {{ keyword.title | slugToTitleCase }}
        </bl-text-av>
      </bl-link-av>
    </div>
  </div>
</template>

<script>
import { slugToTitleCase } from '@/shared/utilities/stringUtility';

export default {
  name: 'RelatedKeywords',

  filters: {
    slugToTitleCase,
  },

  props: {
    keywords: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLogin: global.USER && !!global.USER.id,
    };
  },

  methods: {
    keywordUrl(keyword) {
      if (this.isLogin) {
        return `${keyword.url}?search_source=related_keywords&search[keywords]=${encodeURIComponent(keyword.keyword)}`;
      }
      return keyword.url;
    },
  },
};
</script>
