<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv text="Dikirim Dari" has-border="top" class="d-filter-item-active" style="padding-left: 0;" @click="isShow = true">
        <bl-badge-av :count="chosenList.length" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv v-else has-border="top" text="Dikirim Dari" style="padding-left: 0;" @click="isShow = true">
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv :active.sync="isShow" title="Dikirim Dari" text-action="Reset" @clickAction="reset" @close="close">
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding:16px;">
          <div style="margin-bottom:6px;">
            <bl-text-av variation="body-14" style="margin-bottom:6px;">
              Wilayah / Provinsi
            </bl-text-av>
            <bl-dropdown-av
              :value="value.province"
              placeholder="Pilih Provinsi"
              style="min-width:216px"
              @input="provinceSelected"
            >
              <option v-for="(option, idx) in options" :key="idx" :value="option.value">
                {{ option.label }}
              </option>
            </bl-dropdown-av>
          </div>
          <div v-if="citiesArr.length" style="margin-bottom:6px;">
            <bl-text-av variation="body-14" style="margin:16px 0 6px;">
              Kota
            </bl-text-av>
            <bl-dropdown-av
              :value="String(value.city) || null"
              placeholder="Pilih Kota"
              style="min-width:216px"
              @input="citySelected"
            >
              <option v-for="(city, idx) in citiesArr" :key="idx" :value="city.value">
                {{ city.label }}
              </option>
            </bl-dropdown-av>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding: 16px 24px; border-top: 1px solid #eee;">
        <bl-button-av variation="primary" fullwidth style="border-radius: 4px;" @click="save">
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import { mapGetters } from 'external/Vuex';
import FilterChosen from './FilterChosen.vue';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(
    m => m.default || m,
  );

export default {
  name: 'FilterSellerLocation',
  components: {
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  props: {
    paramName: {
      type: [String, Array, Object],
      required: true,
    },
    currentParamProvinceValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    currentParamCityValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      value: {
        province: null,
        city: [],
      },
      savedVal: {
        province: null,
        city: [],
      },
      isChecked: false,
      citiesArr: [],
      chosenList: [],
    };
  },
  computed: {
    ...mapGetters(['addresses']),
    options() {
      let arr = [];
      if (Object.keys(this.addresses).length > 0) {
        arr.push({
          label: 'Semua Wilayah/Provinsi',
          value: '',
          cities: [],
        });
        arr.push({
          label: 'Pulau Jawa',
          value: 'Pulau Jawa',
          cities: [],
        });
        arr.push({
          label: 'Jabodetabekkar',
          value: 'Jabodetabekkar',
          cities: [],
        });
        for (let [key, value] of Object.entries(this.addresses)) {
          let childArr = [];
          Object.keys(value).forEach(child => {
            childArr.push({
              label: child,
              value: child,
            });
          });
          arr.push({
            label: key,
            value: key,
            cities: childArr,
          });
        }
      }
      return arr;
    },
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.value = {
          province: null,
          city: [],
        };
        // this.updateConditionFilter();
        this.updateChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.getValue();
      this.updateChosen();
    },
    getValue() {
      this.value.province = this.currentParamProvinceValue || null;
      if (this.value.province) {
        this.provinceSelected(this.value.province);
      }
      this.value.city = String(this.currentParamCityValue) ? this.currentParamCityValue : [];
      this.savedVal = {
        province: this.value.province,
        city: this.value.city,
      };
    },
    updateChosen() {
      this.chosenList = [];
      if (this.value.province) this.chosenList.push(this.value.province);
      if (this.value.city !== 'null' && String(this.value.city)) {
        this.chosenList.push(String(this.value.city));
      }
    },
    reset() {
      this.value = {
        province: null,
        city: [],
      };
      this.citiesArr = [];
    },
    save() {
      this.$emit('updated', [
        { label: this.paramName[0], value: this.value.province || '' },
        { label: this.paramName[1], value: this.value.city },
      ]);
      this.savedVal = {
        province: this.value.province,
        city: this.value.city,
      };
      this.updateChosen();
      this.isShow = false;
    },
    close() {
      // this.initializeValue();
      this.value.province = this.savedVal.province;
      if (this.value.province) {
        this.provinceSelected(this.value.province);
      }
      this.value.city = this.savedVal.city;
    },
    provinceSelected(val) {
      this.value.province = val;
      this.value.city = [];

      const obj = this.options.find(c => c.value === val);
      this.citiesArr = obj.cities;
    },
    citySelected(val) {
      this.value.city = [val];
    },
    removeChosen(idx) {
      // this.chosenList.splice(idx, 1);
      if (idx === 1) {
        this.chosenList.splice(idx, 1);
        this.value.city = [];
      } else {
        this.chosenList = [];
        this.value = {
          province: null,
          city: [],
        };
      }
      this.save();
    },
  },
};
</script>
