<template>
  <div class="d-filter-card">
    <bl-flex-container-mv padding="16px 6px 16px 16px" align-items="center">
      <slot />
      <bl-flex-item-av grow="1" style="text-align:right;">
        <bl-switch-av :value="isCheckedVal" @input="toggle" />
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
export default {
  name: 'FilterSwitch',
  props: {
    paramName: {
      type: String,
      required: true,
    },
    currentParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    initialParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
    trueValue: {
      type: [String, Boolean],
      default() {
        return this.$PARAM_TRUE_VALUE;
      },
    },
    falseValue: {
      type: [String, Boolean],
      default() {
        return this.$PARAM_FALSE_VALUE;
      },
    },
  },
  data() {
    return {
      isChecked: '',
    }
  },
  watch: {
    isReset() {
      if (this.isReset) {
        this.isChecked = this.initialParamValue === this.trueValue;
      }
    },
  },
  computed: {
    isCheckedVal() {
      return this.isChecked;
    },
  },
  mounted() {
    this.isChecked = this.currentParamValue === this.trueValue
  },
  methods: {
    toggle(val) {
      if (typeof val === 'boolean') {
        this.isChecked = val;
        this.$emit('updated', this.paramName, this.isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>
