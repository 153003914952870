<template>
  <div>
    <div v-if="hasChosen">
      <bl-cell-mv
        hasBorder="top"
        class="d-filter-item-active"
        style="padding-left: 0;"
        @click="isShow = true"
        text="Pelapak"
      >
        <bl-badge-av count="1" size="medium" slot="badge" />
        <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
      </bl-cell-mv>
      <filter-chosen :items="chosenList" @remove="removeChosen" />
    </div>
    <bl-cell-mv
      v-else
      hasBorder="top"
      text="Pelapak"
      style="padding-left: 0;"
      @click="isShow = true"
    >
      <ico-chevron-right-minor slot="iconRight" :color="$UI_DARKER_COLOR" />
    </bl-cell-mv>
    <bl-sheet-mv
      :active.sync="isShow"
      title="Pelapak"
      text-action="Reset"
      @clickAction="reset"
      @close="close"
    >
      <ico-back-android slot="iconLeftNavBar" color="rgb(66, 69, 77)" style="background:#fff;" />
      <div slot="content" style="padding-top: 10px; padding-bottom: 16px;">
        <div class="d-filter-card" style="padding-left:16px;">
          <bl-cell-mv
            style="padding-left: 0;"
            @click="itemClicked"
          >
            <div slot="text">
              <bl-flex-container-mv align-items="center">
                <bl-flex-item-av style="margin-right:6px;">
                  Super Seller
                </bl-flex-item-av>
                <bl-flex-item-av style="padding-top:2px;">
                  <bl-tooltip-mv
                    placement="bottom-left"
                    text="Cari barang dari pelapak Super Seller yang pelayanan / performa lapaknya sangat baik."
                  >
                    <ico-help-minor color="#ced2d9" />
                  </bl-tooltip-mv>
                </bl-flex-item-av>
              </bl-flex-container-mv>
            </div>
            <div slot="iconRight">
              <bl-checkbox-av
                id="top-seller-option"
                v-model="checkboxesArr"
                native-value="1"
              />
            </div>
          </bl-cell-mv>
        </div>
      </div>
      <div style="padding: 16px 24px; border-top: 1px solid #eee;" slot="footer">
        <bl-button-av
          variation="primary"
          fullwidth
          style="border-radius: 4px;"
          @click="save"
        >
          Simpan
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import FilterChosen from './FilterChosen.vue';
import IcoChevronRightMinor from "@bukalapak/bazaar-visual-next/src/components/IcoChevronRightMinor";
import IcoBackAndroid from "@bukalapak/bazaar-visual-next/src/components/IcoBackAndroid";
import IcoHelpMinor from "@bukalapak/bazaar-visual-next/src/components/IcoHelpMinor";

const BlSheetMv = () =>
  import(/* webpackChunkName: "bl-sheet-mv" */ '@bukalapak/bazaar-mweb-v2/dist/components/BlSheetMv').then(m => m.default || m);

export default {
  name: 'FilterTopSeller',
  components: {
    IcoHelpMinor,
    IcoBackAndroid,
    IcoChevronRightMinor,
    BlSheetMv,
    FilterChosen,
  },
  props: {
    paramName: {
      type: String,
      required: true,
    },
    currentParamValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    isReset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      checkboxesArr: [],
      savedVal: '0',
      value: '0',
      chosenList: [],
    }
  },
  computed: {
    hasChosen() {
      return this.chosenList.length;
    },
  },
  watch: {
    checkboxesArr() {
      this.value = this.checkboxesArr[this.checkboxesArr.length - 1] || '0';
      this.updateChosen();
    },
    isReset() {
      if (this.isReset) {
        this.removeChosen();
      }
    },
    isShow() {
      this.$emit('close');
    },
  },
  mounted() {
    this.initializeValue();
  },
  methods: {
    initializeValue() {
      this.checkboxesArr = [this.currentParamValue];
      this.savedVal = this.currentParamValue;
      this.updateChosen();
    },
    updateChosen() {
      if (this.value === '1') {
        this.chosenList = ['Super Seller'];
      } else {
        this.chosenList = [];
      }
    },
    removeChosen() {
      this.checkboxesArr = [];
      this.value = '0';
      this.updateConditionFilter();
    },
    updateConditionFilter() {
      this.$emit('updated', this.paramName, this.value);
    },
    reset() {
      this.checkboxesArr = [];
    },
    save() {
      this.updateConditionFilter();
      this.updateChosen();
      this.savedVal = this.value;
      this.isShow = false;
    },
    close() {
      this.checkboxesArr = [this.savedVal];
    },
    itemClicked(event) {
      if (event.target && event.target.tagName.toLowerCase() === 'div') {
        document.getElementById('top-seller-option').click();
      }
    },
  },
};
</script>
