<template>
  <div class="ph-28" style="text-align: center;" data-testid="product-unavailable">
    <bl-illustration-av
      :image-url="assetUrl('illustrations/img_small_emptystate_general@2x.png')"
      image-size="small"
      style="margin: 16px 0;"
    />
    <template v-if="isFilter">
      <bl-text-av variation="body-14" weight="bold" style="margin-bottom: 8px;">
        Barang tidak ditemukan
      </bl-text-av>
      <bl-text-av variation="caption-12">
        Sistem kami kebingungan karena kamu pakai terlalu banyak filter.
        <br />
        Coba kurangi filter yang kamu pakai ya.
      </bl-text-av>
      <slot name="selected-filters" />
    </template>
    <template v-else>
      <bl-text-av
        variation="body-14"
        weight="bold"
        style="margin-bottom: 8px;"
      >
        Maaf, barangnya tidak ketemu
      </bl-text-av>
      <bl-text-av variation="caption-12">
        Coba cek lagi kata pencarianmu.
      </bl-text-av>
    </template>
  </div>
</template>

<script>
import BlIllustrationAv from '@bukalapak/bazaar-visual/src/components/BlIllustrationAv/BlIllustrationAv'

export default {
  name: 'ProductUnavailable',
  components: {
    BlIllustrationAv,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    assetUrl: global.assetUrl,
  },
};
</script>
