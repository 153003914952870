import qs from 'query-string';

import ProductSort from '@/shared/models/sort';
import ProductSearch from '@/shared/models/search';
import generatorUtility from '@/shared/utilities/generatorUtility';

const mapParamNames = {
  sort_by: 'sort',
  // city: 'cities', // replaced with real cities param
  todays_deal: 'deal',
  province: 'provinces',
  courier: 'couriers',
};

const booleanParams = [
  'assurance',
  'installment',
  'new',
  'premium_seller',
  'deal',
  'top_seller',
  'brand',
  'used',
  'wholesale',
];

const unnecessaryParams = [
  'category_slug',
  'new',
  'used',
  'price_min',
  'price_max',
  'rating_gte',
  'rating_lte',
  'city',
  'free_shipping_provinces',
];

class BaseProductSearchGenerator {
  static generateSearchParamsStr(productSearch) {
    const self = productSearch;

    const params = Object.assign({}, ProductSearch.selectedFilters(self, { unnecessaryParams: [] }));
    let result = '';

    // remove unnecessary params
    delete params['search[category_slug]'];
    delete params['search[category_name]'];

    // todo: cari tahu ini ngapain
    if (!params['search[store_id]']) {
      delete params['search[category_id]'];
    }

    delete params['search[store_id]'];
    delete params['search[store_username]'];

    result = qs.stringify(params, {
      arrayFormat: 'bracket',
    });

    // add pagination when page is more than 1
    const { currentPage } = self.pagination;

    if (currentPage > 1) {
      result = result ? `page=${currentPage}&${result}` : `page=${currentPage}`;
    }

    return result;
  }

  static generateApiSearchParamsObject(
    productSearchInstance,
    isRetrieveFacets = false,
    isPromoPage = false,
    isSortedByBestSelling = false,
  ) {
    const productSearchInstanceCopy = Object.assign({}, productSearchInstance);
    const searchAttributes =
      generatorUtility.getMiscellaneousFilterByType('attribute', productSearchInstance.attributes) || {};
    const searchVariants =
      generatorUtility.getMiscellaneousFilterByType('variant', productSearchInstance.variants) || {};
    productSearchInstanceCopy.params = ProductSearch.selectedFilters(productSearchInstanceCopy, {
      unnecessaryParams: [],
    });
    let apiParams = this.convertSearchParamToApiParam(productSearchInstanceCopy, isPromoPage);

    if (!apiParams.sort && (isSortedByBestSelling || isPromoPage)) {
      apiParams.sort = 'bestselling';
    }
    if (apiParams.used || apiParams.new) {
      apiParams.condition = generatorUtility.decideConditionParamValue(apiParams);
    }
    if (apiParams.price_min || apiParams.price_max) {
      apiParams.price_range = generatorUtility.generatePriceRange(apiParams);
    }
    if (Number(apiParams.rating_gte) > 0 || Number(apiParams.rating_lte) > 0) {
      apiParams.rating = generatorUtility.generateRating(apiParams);
    }
    if (apiParams.sort === '') {
      delete apiParams.sort;
    }
    apiParams = generatorUtility.removeUnnecessaryParam(apiParams, unnecessaryParams);
    apiParams.limit = productSearchInstance.pagination.limit;
    apiParams.offset = productSearchInstance.pagination.offset;
    apiParams.facet = isRetrieveFacets;

    const { currentPage } = productSearchInstance.pagination;
    apiParams.page = parseInt(currentPage, 10) > 0 ? currentPage : 1;

    return Object.assign({}, apiParams, searchAttributes, searchVariants);
  }

  static generateApiSearchParamsStr(productSearchInstance, isRetrieveFacets = false, isPromoPage = false) {
    return qs.stringify(this.generateApiSearchParamsObject(productSearchInstance, isRetrieveFacets, isPromoPage), {
      arrayFormat: 'bracket',
    });
  }

  static generateAdditionalSearchParamsByType(productSearchInstance, type = '', paramFormat) {
    const self = productSearchInstance;
    const additionalParams = {};

    Object.keys(self[type]).forEach(param => {
      additionalParams[`search[${paramFormat}][${param}]`] = self[type][param];
    });

    return qs.stringify(additionalParams, {
      arrayFormat: 'bracket',
    });
  }

  static generateSearchParamsUrl(productSearch, useCurrentPath = false) {
    const searchParams = [];
    const urlOrigin = productSearch.urlObj.origin || document.location.origin;
    const paramStr = this.generateSearchParamsStr(productSearch);
    const attributeStr = this.generateAdditionalSearchParamsByType(productSearch, 'attributes', 'filter_attr');
    const variantStr = this.generateAdditionalSearchParamsByType(productSearch, 'variants', 'filter_var');

    if (paramStr) searchParams.push(paramStr);
    if (attributeStr) searchParams.push(attributeStr);
    if (variantStr) searchParams.push(variantStr);

    return `${urlOrigin}${this.generatePathname(productSearch, useCurrentPath)}?${searchParams.join('&')}`;
  }

  static getSelectedSortOption(sortValue, keyword, discount, isPromoPage = false) {
    const sortOptions = ProductSort.getSortOptions(keyword, discount, isPromoPage);
    const selectedSortOption = sortOptions.find(sort => sort.value === sortValue);

    return (selectedSortOption && selectedSortOption.apiValue) || '';
  }

  static generatePathname() {
    return '/products';
  }

  static convertSearchParamToApiParam(productSearchInstance, isPromoPage = false) {
    const apiParams = {};
    const searchParams = Object.assign({}, productSearchInstance.params);
    const extractSearchRgx = /\[(.*)\]/; // regex to extract search[province] into province
    Object.keys(searchParams).forEach(paramKey => {
      let extractedKey = extractSearchRgx.exec(paramKey)[1];
      extractedKey = generatorUtility.convertApiKeyName(extractedKey, mapParamNames);
      apiParams[extractedKey] = generatorUtility.convertBooleanParam(
        extractedKey,
        searchParams[paramKey],
        booleanParams,
      );
      if (extractedKey === 'sort') {
        apiParams[extractedKey] = this.getSelectedSortOption(
          searchParams[paramKey],
          searchParams['search[keywords]'],
          searchParams['search[todays_deal]'],
          isPromoPage,
        );
      }
    });
    return apiParams;
  }
}

export default BaseProductSearchGenerator;
