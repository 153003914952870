const NOT_FILTER_PARAMS = ['category_id', 'category_slug', 'category_name', 'sort_by', 'keywords', 'super_seller_tier'];

const AGE_RESTRICTED_KEYWORD_CACHE_KEY = 'age-restricted-keyword';
const PRODUCT_TYPES = {
  PROMOTED: 'promoted',
  ORGANIC: 'organic',
  BUSINESS: 'business',
};
const RESTRICTED_KEYWORD = {
  BLOCKED: 'blocked',
  AGE_RESTRICTION: 'age_restriction',
};
const PAGE_TYPE = {
  BHLM: 'bhlm',
  CATEGORY: 'cat2/3',
  TREND_BRAND: 'trendBrand',
  TAG: 'tag',
  PRODUCT_SEARCH: 'product-search',
  PROMO: 'promo',
};

module.exports = {
  NOT_FILTER_PARAMS,
  AGE_RESTRICTED_KEYWORD_CACHE_KEY,
  PRODUCT_TYPES,
  RESTRICTED_KEYWORD,
  PAGE_TYPE,
  PRELOAD_PRODUCT_COUNT: 10,
};
