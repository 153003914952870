const qs = require('query-string');
const url = require('url');
const urlJoin = require('url-join');
const { humanizeTimestamp } = require('@bukalapak/toolbox-helper/date-helper').default;

const urlUtility = {
  createUrlObj(href) {
    const urlObj = url.parse(href);
    const origin = url.format({ protocol: urlObj.protocol, host: urlObj.host });
    const params = qs.parse(urlObj.search, { arrayFormat: 'bracket' });

    delete params[''];

    return { ...urlObj, params, origin };
  },
  encodeUrlParams(params) {
    return Object.entries(params)
      .map(param => param.map(encodeURIComponent).join('='))
      .join('&');
  },
  updateDocumentTitle(keyword) {
    if (!keyword) return;

    if (typeof window !== 'undefined') {
      const keywordCapitalized = keyword
        .split(' ')
        .map(k => `${k.charAt(0).toUpperCase()}${k.slice(1)}`)
        .join(' ');

      const currentPeriod = humanizeTimestamp(new Date(), '%month% %yyyy%');

      window.document.title = `Jual Produk ${keywordCapitalized} Termurah dan Terlengkap ${currentPeriod} | Bukalapak`;
    }
  },
  getPathname() {
    return typeof window === 'undefined' ? global.initialPathname : window.location.pathname;
  },

  transformToMobileUrl(items = [], key = '') {
    if (!Array.isArray(items) || items.length < 1) {
      return null;
    }

    return items.map(item => {
      const newItem = { ...item };

      if (newItem[key]) {
        if (newItem[key].startsWith('/')) {
          newItem[key] = urlJoin(process.env.M_HOST || 'https://m.bukalapak.com', newItem[key]);
        } else {
          newItem[key] = newItem[key].replace('www.', 'm.');
        }
      }

      return newItem;
    });
  },
};

module.exports = urlUtility;
