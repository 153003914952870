<template lang="pug">
  .c-progress-circular(
    role= 'progressbar',
    :class="{'c-progress-circular-indeterminate': indeterminate}",
    aria-valuemin="0",
    aria-valuemax="100"
    :aria-valuenow="indeterminate ? undefined : normalizedValue"
    :style="styles"
  )
    svg(
      xmlns="http://www.w3.org/2000/svg",
      :viewBox="`${viewBoxSize} ${viewBoxSize} ${2 * viewBoxSize} ${2 * viewBoxSize}`",
      :style="svgStyles"
    )
      circle(
        v-if="!indeterminate"
        class="c-progress-circular__underlay"
        fill="transparent",
        :cx="2 * viewBoxSize",
        :cy="2 * viewBoxSize",
        :r="radius",
        :stroke-width="strokeWidth",
        :stroke-dasharray="strokeDashArray",
        :stroke-dashoffset="0",
      )
      circle(
        class="c-progress-circular__overlay"
        fill="transparent",
        :cx="2 * viewBoxSize",
        :cy="2 * viewBoxSize",
        :r="radius",
        :stroke-width="strokeWidth",
        :stroke-dasharray="strokeDashArray",
        :stroke-dashoffset="strokeDashOffset",
        :style="circleStyle"
      )
    .c-progress-circular__info
      slot

</template>

<script>
export default {
  name: 'BlProgressCircularAv',
  props: {
    indeterminate: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 32,
    },
    width: {
      type: [Number, String],
      default: 4,
    },
    strokeColor: {
      type: String,
      default: '#aaff00',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    rotate: {
      type: [Number, String],
      default: -90,
    },
  },
  data: () => ({
    radius: 20,
  }),

  computed: {
    calculatedSize() {
      return Number(this.size) + (this.button ? 8 : 0);
    },

    circumference() {
      return 2 * Math.PI * this.radius;
    },
    normalizedValue() {
      if (this.value < 0) {
        return 0;
      }

      if (this.value > 100) {
        return 100;
      }

      return parseFloat(this.value);
    },
    strokeDashArray() {
      return Math.round(this.circumference * 1000) / 1000;
    },

    strokeDashOffset() {
      return ((100 - this.normalizedValue) / 100) * this.circumference + 'px';
    },

    strokeWidth() {
      return (Number(this.width) / +this.size) * this.viewBoxSize * 2;
    },
    styles() {
      return {
        height: `${this.calculatedSize}px`,
        width: `${this.calculatedSize}px`,
      };
    },
    circleStyle() {
      return {
        '--initialStroke': this.circumference,
        stroke: this.strokeColor,
      };
    },
    viewBoxSize() {
      return this.radius / (1 - Number(this.width) / +this.size);
    },
    svgStyles() {
      return {
        transform: `rotate(${Number(this.rotate)}deg)`,
      };
    },
  },
};
</script>

<style lang="scss" src="./BlProgressCircularAv.style.scss"></style>
