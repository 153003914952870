<template>
  <bl-flex-container-mv class="dp-grid">
    <bl-flex-container-mv v-for="o in 2" direction="column" :key="o">
      <bl-card-mv v-for="n in 2" :key="n" style="margin: 4px;">
        <bl-shimmer-av
          height="330px"
          style="width: 100%;"
        />
      </bl-card-mv>
    </bl-flex-container-mv>
  </bl-flex-container-mv>
</template>

<script>
export default {
  name: 'ProductGridShimmer',
};
</script>
